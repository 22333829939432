import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, makeStyles } from "@material-ui/core";
import styles from "./FilterComponents.module.scss";

const useStyles = makeStyles((themes) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
  },
  multipleSelectMui: {
    "& .MuiMenuItem-root": {
      textWrap: "wrap",
    },
  },
}));

export default function MultipleSelectFilter(props) {
  const classes = useStyles();

  const { options, filters, setFilters, id, sourceId, setSourceId } = props;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let a = value === "string" ? value.split(",") : value;
    setFilters({ ...filters, [id]: a.toString() });
    setSourceId(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl
      variant="outlined"
      style={{ width: "250px" }}
      className={classes.root}
    >
      <Select
        multiple={true}
        id={id}
        options={options}
        value={sourceId}
        onChange={handleChange}
        style={{ height: "40px" }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes: {
            paper: `${styles.multipleSelect} ${classes.multipleSelectMui}`,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.name}
            style={{ width: "250px", display: "block", padding: "6px 16px" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
