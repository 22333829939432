import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function AutoSelectFilter(props) {
  const {
    options,
    filters,
    setFilters,
    id,
    multipleFlag,
    placeholder,
    uniqueKey,
    disabled,
  } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      multiple={multipleFlag === false ? false : true}
      id={id}
      value={filters[id]}
      onChange={(event, value) => {
        setFilters({ ...filters, [id]: value || "" });
      }}
      style={{
        width:
          uniqueKey === "addMember" || uniqueKey === "viewMember" ? "95%" : 250,
      }}
      disabled={
        (uniqueKey === "addMember" || uniqueKey === "viewMember") && disabled
      }
      options={options}
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
