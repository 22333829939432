import { createContext, useEffect, useState } from "react";

export const AppData = createContext(null);

function Context({ children }) {
  const [formMode, setFormMode] = useState("create");
  const [mpaFormMode, setMpaFormMode] = useState("create");
  const [jobConfigMode, setJobConfigMode] = useState("create");
  const [eligibilityMode, setEligibilityMode] = useState("create");
  const [eligibilityJobId, setEligibilityJobId] = useState(null);
  const [userAction, setUserAction] = useState(null);

  useEffect(() => {
    const storedUserActions = JSON.parse(
      localStorage.getItem("userActions") || "{}"
    );

    setUserAction({
      view:
        localStorage.getItem("roleType") === "31" ||
        localStorage.getItem("roleType") === "6"
          ? 1
          : +storedUserActions?.view,
      edit:
        localStorage.getItem("roleType") === "31" ||
        localStorage.getItem("roleType") === "6"
          ? 1
          : +storedUserActions?.edit,
      approveReject:
        localStorage.getItem("roleType") === "31" ||
        localStorage.getItem("roleType") === "6"
          ? 1
          : +storedUserActions?.addOrDelete,
      export_access:
        localStorage.getItem("roleType") === "31" ||
        localStorage.getItem("roleType") === "6"
          ? 1
          : +storedUserActions?.export_access,
    });
  }, []);

  return (
    <AppData.Provider
      value={{
        formMode,
        setFormMode,
        mpaFormMode,
        setMpaFormMode,
        jobConfigMode,
        setJobConfigMode,
        eligibilityMode,
        setEligibilityMode,
        eligibilityJobId,
        setEligibilityJobId,
        userAction,
        setUserAction,
      }}
    >
      {children}
    </AppData.Provider>
  );
}

export default Context;
