import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const usestyles = makeStyles({
  formControlStyle: {
    "& .MuiInputBase-root.Mui-disabled": {
      background: "#F6F6F6",
    },
  },
});

export default function MultiSelectCheckFilter(props) {
  const {
    filters,
    setFilters,
    multipleFlag,
    id,
    options,
    placeholder,
    uniqueKey,
    disabled,
  } = props;

  const classes = usestyles();
  const [selectedNames, setSelectedNames] = useState(
    filters[id].length === 0 ? [placeholder] : filters[id]
  );

  const renderValue = (selected) => {
    if (
      selected.length === 0 ||
      (selected.length === 1 && selected[0] === placeholder)
    ) {
      return (
        <div
          style={{
            opacity:
              uniqueKey === "action_addMember" ||
              uniqueKey === "action_viewMember" ||
              uniqueKey === "dashboardPages_addMember" ||
              uniqueKey === "dashboardPages_viewMember"
                ? "0.5"
                : "1",
          }}
        >
          {placeholder}
        </div>
      );
    }

    let selectedValues = options
      .filter((option) => selected.includes(option.name))
      .map((option) => option.name)
      .join(", ");

    return selectedValues;
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let mainValue =
      value === "string"
        ? value.split(",").filter((e) => e !== placeholder)
        : value.filter((e) => e !== placeholder);

    const disFilter = mainValue.map(
      (val) => options.find((op) => op.name === val)?.id
    );

    setFilters({
      ...filters,
      [id]: disFilter,
    });

    setSelectedNames(mainValue.length ? mainValue : [placeholder]);
  };

  return (
    <FormControl
      variant="outlined"
      sx={{ width: "95%" }}
      disabled={disabled}
      className={`${
        uniqueKey === "action_addMember" ||
        uniqueKey === "action_viewMember" ||
        uniqueKey === "dashboardPages_addMember" ||
        uniqueKey === "dashboardPages_viewMember"
          ? classes.formControlStyle
          : ""
      }`}
    >
      <Select
        multiple={multipleFlag}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        options={options}
        value={selectedNames.length ? selectedNames : []}
        onChange={handleChange}
        style={{
          height: "40px",
        }}
        renderValue={renderValue}
        MenuProps={
          uniqueKey === "action_addMember"
            ? MenuProps
            : {
                ...MenuProps,
                anchorOrigin: {
                  vertical: "top", // Dropdown will anchor from the top
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "bottom", // Dropdown will open upwards
                  horizontal: "left",
                },
              }
        }
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.name}
            style={{ padding: "0" }}
            disabled={
              (uniqueKey === "action_addMember" ||
                uniqueKey === "action_viewMember") &&
              option.label === 1
            }
          >
            <Checkbox checked={selectedNames.indexOf(option.name) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
