import React, { Fragment, useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
// import Header from '../containers/Header';
import Header from "../containers/HeaderV2";

/**  Define a mapping of paths to page ids corresponding to their page names (permissions) */
const pagePermissionsMap = {
  "/changepassword": [1.2],
  "/accessPanel": [1.1],
  "/workerview/v2/:id": [6, 2] /* Multiple pages */,
  "/employer-data": [11],
  "/employer-data/company": [11],
  "/mpaConfig": [7],
  "/mpaConfig/mpa/:mpaId": [7],
  "/projectConfig": [1],
  "/projectConfig/project/:projectId": [1],
  "/tatConfig/project/:projectId": [1],
  "/attendance": [16],
  "/driveDatabase": [17],
  "/hub-listing": [12],
  "/jobsdb": [4],
  "/jobsdb/jobConfig/:jobId": [4],
  "/jobsdb/screening/:jobId": [4],
  "/jobsdb/eligibilityCriteria/:jobId": [4],
  "/campaignTracker": [4],
  "/applicationDb": [3],
  "/workerdata": [2],
  "/leadWorkerview/v2/:id": [2],
  "/paymentslistingV2": [5],
  "/document-verification": [6],
  "/verificationdetails/:rowData/:fn/:bucket": [6],
  "/bankverificationlisting": [6],
  "/pennyTestingverification/:userId/:pennyStatus": [6],
  "/shiftdata": [8],
  "/recruiter-data": [9],
  "/referral": [13],
};

const AuthenticatedRoute = ({ component: Component, path, ...rest }) => {
  const { session } = useContext(AuthContext);

  const userPages = JSON.parse(localStorage.getItem("pages")) || [];

  // Extract allowed page names from localStorage
  const allowedPageNames = userPages.map((page) => page.Id);

  // Get the required page ids for the given path
  const requiredPages = pagePermissionsMap[path] || [];

  // Check if the user has access to the requested page
  const hasAccess = requiredPages.some(
    (page) => allowedPageNames.includes(page.toString()) || page === 1.2
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        session ? (
          hasAccess ||
          localStorage.getItem("roleType") == "6" ||
          localStorage.getItem("roleType") == "31" ||
          path === "/" ? (
            <Fragment>
              <Header />
              <Component {...props} />
            </Fragment>
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
