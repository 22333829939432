import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import Upload from "./components/DelhiveryEarningAdmin";
import { Button, Grid, Checkbox } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AppData } from "../../context/context";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    width: max-content;
    position: absolute;
    left: 315px;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const EmployerData = (props) => {
  const classes = useStyles();
  const { userAction } = useContext(AppData);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disablePayments, setDisablePayments] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortUrl, setSortUrl] = useState("");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [null, null],
    zone: "",
    facility_state: "",
    facility_name: "",
    jobStatus: "",
    facilityCity: "",
    partnerId: "",
    area: "",
  });

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "swiggy_id":
        url = "&partnerId=101";
        break;
      case "flipkart_id":
        url = "&partnerId=100";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  //columns list
  //     partnerId
  // partnerName
  // logo
  // insertedOn
  // paymentConfiguration
  // isActive
  const columnsOkayGo = useMemo(
    () => [
      {
        Header: "Client id",
        accessor: "partnerId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Logo",
        accessor: "logo",
        defaultCanFilter: false,
        defaultCanSort: false,
        Cell: (row) => {
          return (
            <span>
              {row.row.original.logo && (
                <img
                  height="50"
                  width="50"
                  alt="not found"
                  src={row.row.original.logo}
                ></img>
              )}
            </span>
          );
        },
      },
      {
        Header: "Client Name",
        accessor: "partnerName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Payment Configuration",
        accessor: "paymentConfiguration",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added On",
        accessor: "insertedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        partnerId: el.partner_id || "",
        partnerName: el.company_name || "",
        logo: el.logo || "",
        insertedOn: el.inserted_on
          ? moment(el.inserted_on).format("DD/MM/YYYY")
          : "",
        paymentConfiguration:
          el.paymentConfiguration === 1
            ? "Shipment Basis"
            : el.paymentConfiguration === 2
            ? "Hourly Basis"
            : el.paymentConfiguration === 3
            ? "Daily Basis"
            : "",
        isActive: el.is_active === 1 ? "Yes" : "No",
      });
      return true;
    });
    return extractedData;
  };

  //Function to create All record export data
  const showToast = (type, message) => {
    if (type === "success") toast.success(message);
    else if (type === "error") toast.error(message);
  };

  const columns = columnsOkayGo;
  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    if (localStorage.getItem("roleType")) {
      Axios.get(`${urlLinks.baseUrl}${urlLinks.shifts.workerPartners}`)
        .then((response) => {
          if (response) {
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    ApplySort();
  }, [sortBy]);

  useEffect(() => {
    getPaymentListing(perPage, 1);
  }, [sortUrl, localStorage.getItem("roleType")]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [""],
      },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    rows.map((record, index) => {
      if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
        selectedRowIds[index] = false;
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameMatchNew zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameMatchNew zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <span
              style={{ marginLeft: "273px" }}
              className="fontSize24 fontWeightBold"
            >
              Employer Data
            </span>
          </Grid>
          {!!userAction.edit && (
            <Grid style={{ marginLeft: "54px" }} item xs={2}>
              <Link
                to="/employer-data/company"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Add New Company
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} direction="row" alignItems="flex-start"></Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
          </Grid>
        )}
        {openUploadModal && (
          <Upload
            openOnboardingModal={openUploadModal}
            setOpenOnboardingModal={setOpenUploadModal}
            showToast={showToast}
            updateMyData={updateMyData}
            data={data}
          ></Upload>
        )}
        <ToastContainer
          position="top-right"
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          autoClose={1000}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        ></ToastContainer>
      </Grid>
    </Styles>
  );
};
export default EmployerData;
