import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import crossSvg from "./cross.svg";
import dropDown from "./dropdown.svg";
import searchIcon from "./search.svg";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import { Link } from "react-router-dom";
import UrlLinks from "../../UrlLinks";
import moment from "moment";
import NoRecord from "./noRecord.svg";
import VerifiedSVG from "./verified15x15.svg";
import NotUploadSVG from "./Not uploaded.svg";
import RejectedSVG from "./Rejected.svg";
import FlagSVG from "./Flag.svg";
import "./indeterminateCheckbox.css";
import ProfileVerification from "../../Utils/dialogs/ProfileVerification";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import { makeDynamicStatus } from "../../Utils/functions/DynamicStatus";
import axios from "axios";
import { AppData } from "../../context/context";
import ExportButton from "../../Utils/functions/ExportButton";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
  select: {
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "8px 10px",
    },
  },
}));

const WorkerData = (props) => {
  /** accessing states from redux store **/
  const { users } = useSelector((state) => state);
  const { allRecord, modalLoader } = users;

  /** useDispatch is used to update states to redux store **/
  const dispatch = useDispatch();
  const { setAllRecord, setModalLoader } = bindActionCreators(
    actionCreators,
    dispatch
  );

  /** other states **/
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const { userAction } = useContext(AppData);
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [tab, setTab] = useState({
    workerDataTab: true,
    recruiterDataTab: false,
  });
  const [crossFilter, setCrossFilter] = useState({
    value: "&verificationStatus=2",
    index: 2,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [clientFilter, setClientFilter] = useState({});
  const [clientDropDown, setClientDropDown] = useState(false);
  const [clientListing, setClientListing] = useState([]);
  const [filterUrl, setFilterUrl] = useState("");
  const [isRecruiterData, setIsRecruiterData] = useState("");
  const [filters, setFilters] = useState({
    searchBy: "",
    source: "",
    all: true,
    onboardingInitiated: false,
    active: false,
    inactive: false,
    criminalRecord: false,
    temporarilyInactive: false,
    offboardingInitiated: false,
    zone: "",
    facilityState: "",
    facilityName: "",
    facilityCity: "",
    partnerId: "",
    workFor: "",
    workerStatus: "",
    psf: "",
    callStatus: "",
    interviewStatus: "",
    recruiterId: "",
    trainingUpdate: "",
    preferedHub: "",
    appLead: "",
    interviewDate: [null, null],
    firstEarningDate: [null, null],
    leadAddedDate: [null, null],
    bankDetails: "",
    leadCity: "",
    leadSource: "",
    lastEarning: "",
    clientEarningStatus: "",
    clientStatus: "",
    RecruiterAssignedBetween: [null, null],
    idGenerationDate: [null, null],
    IsLmaStatus: "",
    bgvStatus: "",
    lastAppliedBy: "",
  });
  const [openProfileVerificationModal, setOpenProfileVerificationModal] =
    useState(false);
  const [profileVerificationList, setProfileVerificationList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredClients = clientListing?.filter((client) =>
    client.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserModal = async (event, row, type) => {
    switch (type) {
      case "profileVerification": {
        setModalLoader(true);
        setOpenProfileVerificationModal(true);
        await Axios.get(
          `${UrlLinks.baseUrl}${UrlLinks.verificationAPIs.getPhotoVerificationListing}?userId=${row.userId}`
        )
          .then((res) => {
            setProfileVerificationList(res.data.response);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        setModalLoader(false);
        return;
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await Axios.get(
        `${UrlLinks.baseUrl}/${UrlLinks.shifts.workerPartners}`
      );
      console.log(response.data.response);
      if (response) {
        setClientListing(response.data.response);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getExternalWorkerList(perPage, 1, "client");
    setAllRecord([]);
  }, [clientFilter]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setsearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const updateFilterUrl = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["onboardingInitiated"]) {
      url = `${url}&workerStatus=2,5`;
    }
    if (filters["status"]) {
      url = `${url}&status=${filters["status"]}`;
    }
    if (filters["offboardingInitiated"]) {
      url = `${url}&workerStatus=4`;
    }
    if (filters["temporarilyInactive"]) {
      url = `${url}&workerStatus=6,8`;
    }
    if (filters["criminalRecord"]) {
      url = `${url}&criminal_record=1`;
    }
    if (filters["partnerId"]) {
      url = `${url}&partnerId=${filters["partnerId"].id}`;
    }
    if (filters["facilityName"]) {
      url = `${url}&facilityName=${filters["facilityName"].name}`;
    }
    if (filters["facilityCity"]) {
      url = `${url}&facilityCity=${filters["facilityCity"].name}`;
    }
    if (filters["facilityState"]) {
      url = `${url}&facilityState=${filters["facilityState"].name}`;
    }
    if (filters["source"]) {
      url = `${url}&source=${filters["source"].id}`;
    }
    if (filters["clientStatus"]) {
      url = `${url}&clientStatus=${filters["clientStatus"].id}`;
    }
    if (filters["clientEarningStatus"]) {
      url = `${url}&earningStatus=${filters["clientEarningStatus"].id}`;
    }
    if (filters["workerStatus"]) {
      url = `${url}&workerStatus=${filters["workerStatus"].id}`;
    }
    if (filters["workFor"]) {
      url = `${url}&workFor=${filters["workFor"]}`;
    }
    if (filters["psf"]) {
      url = `${url}&psf=${filters["psf"].id}`;
    }
    if (filters["leadSource"]) {
      url = `${url}&leadSource=${filters["leadSource"].id}`;
    }
    if (filters["callStatus"]) {
      url = `${url}&callStatus=${filters["callStatus"].id}`;
    }
    if (filters["lastAppliedBy"]) {
      url = `${url}&lastAppliedBy=${filters["lastAppliedBy"].id}`;
    }
    if (filters["appStatus"]) {
      url = `${url}&appStatus=${filters["appStatus"].id}`;
    }
    if (filters["interviewStatus"]) {
      url = `${url}&interviewStatus=${filters["interviewStatus"].id}`;
    }
    if (filters["recruiterId"]) {
      url = `${url}&recruiterId=${filters["recruiterId"].id}`;
    }
    if (filters["trainingUpdate"]) {
      url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`;
    }
    if (filters["preferedHub"]) {
      url = `${url}&preferedHub=${filters["preferedHub"].id}`;
    }
    if (filters["appLead"]) {
      url = `${url}&appLead=${filters["appLead"].id}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bankdetail=${filters["bankDetails"].id}`;
    }
    if (filters["leadCity"] || filters["leadCity"] === 0) {
      url = `${url}&leadCity=${filters["leadCity"].id}`;
    }
    if (filters["IsLmaStatus"]) {
      url = `${url}&is_lma_worker=${filters["IsLmaStatus"].id}`;
    }
    if (filters["bgvStatus"]) {
      url = `${url}&bgvStatus=${filters["bgvStatus"].id}`;
    }
    if (
      filters["RecruiterAssignedBetween"][0] &&
      !filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=2019-01-01&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (
      filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["idGenerationDate"][0] && !filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&idGeneratedFrom=2019-01-01&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["idGenerationDate"][0] && filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["interviewDate"][0] && !filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(
        filters["leadAddedDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["lastEarning"].id === "last3days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last5days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(4, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["lastEarning"].id === "last7days")
      url = `${url}&lastEarningFrom=${moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD")}&lastEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    return url;
  };

  const resetFiltersWithoutSearch = () => {
    const filterList = {
      source: "",
      searchBy: filters.searchBy,
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      facilityCity: "",
      workFor: "",
      workerStatus: "",
      psf: "",
      callStatus: "",
      interviewStatus: "",
      recruiterId: "",
      trainingUpdate: "",
      preferedHub: "",
      appLead: "",
      interviewDate: [null, null],
      firstEarningDate: [null, null],
      leadAddedDate: [null, null],
      bankDetails: "",
      leadCity: "",
      leadSource: "",
      lastEarning: "",
      appStatus: "",
      clientEarningStatus: "",
      clientStatus: "",
      RecruiterAssignedBetween: [null, null],
      idGenerationDate: [null, null],
      IsLmaStatus: "",
      bgvStatus: "",
      lastAppliedBy: "",
    };
    setFilters(filterList);
    setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
    setCurrentPage(1);
    return filterList;
  };

  const workerDataColumn = useMemo(
    () => [
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return (
            <Link
              to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
              target="_blank"
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.clients.map((el) => {
                return <div>{el[0]}</div>;
              })}
            </div>
          );
        },

        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Document Verification Status",
        accessor: "DVStatus",
        Cell: (row) => {
          return (
            <Grid container style={{ lineHeight: "30px", display: "flex" }}>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                {makeDocumentstatus(
                  "Aadhaar Card",
                  row.row.original.aadharStatus
                )}
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                {makeDocumentstatus("PAN Card", row.row.original.panStatus)}
              </Grid>
              {/* <Grid item xs={7} style={{ textAlign: "left" }}></Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Link
                  to={{
                    pathname: `/verificationdetails/${
                      row.row.original.userId
                    }/${crossFilter.index}/${makeStatus(crossFilter.index)}}}`,
                  }}
                  state={{ from: localStorage.setItem("location", "worker") }}
                  className="nameLink_arrow"
                  target="_blank"
                >
                  <span style={{ textDecoration: "underline" }}>
                    View details
                  </span>{" "}
                  {">"}
                </Link>
              </Grid> */}
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Profile verification",
        accessor: "profileVerification",
        Cell: (row) => {
          return (
            <Grid container style={{ display: "flex" }}>
              <Grid item xs={8} style={{ textAlign: "left" }}>
                {makeDynamicStatus(
                  "profileVerification",
                  row.row.original.profile_photo_verified
                )}
              </Grid>
              {row.row.original.profile_photo_verified !== null &&
                row.row.original.profile_photo_verified !== undefined &&
                row.row.original.profile_photo_verified !== 2 && (
                  <Grid
                    item
                    xs={4}
                    style={{ textAlign: "left", display: "flex" }}
                  >
                    <button
                      className={styles.viewButton}
                      type="button"
                      onClick={(e) =>
                        handleUserModal(
                          e,
                          row.row.original,
                          "profileVerification"
                        )
                      }
                    >
                      <span style={{ textDecoration: "underline" }}>View</span>{" "}
                      <span>{">"}</span>
                    </button>
                  </Grid>
                )}
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [crossFilter]
  );

  const recruiterDataColumn = useMemo(
    () => [
      {
        Header: "Recruiter ID",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return <span className="nameLink">{row.row.original.name}</span>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "PAN Card Status",
        accessor: "panCardStatus",
        Cell: (row) => {
          return (
            <Grid container style={{ lineHeight: "30px", display: "flex" }}>
              <Grid item xs={6} style={{ textAlign: "left" }}>
                {makeDynamicStatus(
                  "panCardStatus",
                  row.row.original.pan_status
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: "left", marginTop: "4px" }}>
                <Link
                  to={{
                    pathname: `/verificationdetails/${
                      row.row.original.userId
                    }/${crossFilter.index}/${makeStatus(crossFilter.index)}}}`,
                  }}
                  state={{
                    from: localStorage.setItem("location", "recruiter"),
                  }}
                  className="nameLink"
                  target="_blank"
                >
                  View details {">"}
                </Link>
              </Grid>
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [crossFilter]
  );

  const makeDocumentstatus = (doc, status) => {
    switch (status) {
      case 0:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={FlagSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 1:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={VerifiedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={RejectedSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
      default:
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "8px" }}>
              <img src={NotUploadSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> {doc}</div>
          </div>
        );
    }
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let clients = [];

    response.content.map((el) => {
      clients = [];
      extractedData.push({
        userId: tab.workerDataTab ? el.user_id : el.userId || "",
        okaygoId: tab.workerDataTab
          ? el.clientDetails.length > 0
            ? el.clientDetails[0].okaygo_id
            : ""
          : el.okayGoId !== null
          ? el.okayGoId
          : "",
        name: tab.workerDataTab
          ? `${el.first_name ? el.first_name : ""} ${
              el.last_name ? el.last_name : ""
            }`
          : `${el.firstName ? el.firstName : ""} ${
              el.lastName ? el.lastName : ""
            }`,
        contactNo: tab.workerDataTab ? el.phone_number : el.phoneNumber || "",
        panStatus: tab.workerDataTab
          ? el.pan_verified
          : el.panStatus !== null
          ? el.panStatus
          : 0,
        aadharStatus: tab.workerDataTab
          ? el.aadhar_verified
          : el.aadharStatus !== null
          ? el.aadharStatus
          : 0,
        dlStatus: el.dlStatus !== null ? el.dlStatus : 0,
        addressStatus: el.addressStatus !== null ? el.addressStatus : 0,
        profile_pic_status:
          el.profile_pic_status !== null ? el.profile_pic_status : 0,
        profile_photo_verified: el.profile_photo_verified,
        pan_status: el.panStatus !== null ? el.panStatus : 0,
        clientWorkerData: tab.workerDataTab
          ? el.clientDetails?.map((cl) => {
              clients.push([cl.client_name]);
            }) || ""
          : el.clientList !== null
          ? el.clientList.map((cl) => {
              clients.push([
                cl.clientName,
                createWorkerStatus(cl.clientIdStatus),
              ]);
            })
          : "",
        clients: clients,
      });
      return true;
    });
    return extractedData;
  };

  const makeDataForExport = (response) => {
    console.log("Inside", response);
    let extractedData = [];
    let clients = [];

    response.map((el) => {
      clients = [];
      extractedData.push({
        okaygoId: tab.workerDataTab
          ? el.clientDetails.length > 0
            ? el.clientDetails[0].okaygo_id
            : ""
          : el.okayGoId !== null
          ? el.okayGoId
          : "",
        name: tab.workerDataTab
          ? `${el.first_name ? el.first_name : ""} ${
              el.last_name ? el.last_name : ""
            }`
          : `${el.firstName ? el.firstName : ""} ${
              el.lastName ? el.lastName : ""
            }`,
        contactNo: el.phone_number || "",
        panStatus:
          el.pan_verified === 1
            ? "Verified"
            : el.pan_verified === 2
            ? "Flagged"
            : el.pan_verified === 3
            ? "Rejected"
            : el.pan_verified === 4
            ? "Pending"
            : "Not Filled",
        aadharStatus:
          el.aadhar_verified === 1
            ? "Verified"
            : el.aadhar_verified === 2
            ? "Flagged"
            : el.aadhar_verified === 3
            ? "Rejected"
            : el.aadhar_verified === 4
            ? "Pending"
            : "Not Filled",
        profile_photo_verified:
          el.profile_photo_verified === 0
            ? "Flagged"
            : el.profile_photo_verified === 2
            ? "Flagged"
            : el.profile_photo_verified === 3
            ? "Rejected"
            : el.profile_photo_verified === 1
            ? "Verified"
            : "Not Filled",
        clients:
          el.clientDetails?.map((cl) => {
            clients.push([cl.client_name]);
          }) || "",
        clients: clients.toString(),
      });
      return true;
    });

    console.log(extractedData, "-------");
    return extractedData;
  };

  const createWorkerStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Offboarded";
        break;
      case 1:
        text = "Active";
        break;
      default:
        break;
    }
    return text;
  };

  const makeWorkerData = (response) => {
    let extractedData = [];
    let clients = [];

    response.map((el) => {
      let temp;
      if (tab.workerDataTab) {
        temp = {
          "LeadId/okaygoId":
            el.okayGoId !== null
              ? el.okayGoId
              : el.leadId !== null
              ? el.leadId
              : "",
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          contactNo: el.phoneNumber || "",
          PanCardStatus:
            el.panStatus !== null ? makeStatus(el.panStatus) : "Not Filled",
          AadharStatus:
            el.aadharStatus !== null
              ? makeStatus(el.aadharStatus)
              : "Not Filled",
          DLStatus:
            el.dlStatus !== null ? makeStatus(el.dlStatus) : "Not Filled",
          AddressStatus:
            el.addressStatus !== null
              ? makeStatus(el.addressStatus)
              : "Not Filled",
          Profile_pic_status:
            el.profile_pic_status !== null
              ? makeStatus(el.profile_pic_status)
              : "Not Filled",
          clients: clients.toString(),
        };
      } else {
        temp = {
          recruiterId: el.okayGoId,
          name: `${el.firstName ? el.firstName : ""} ${
            el.lastName ? el.lastName : ""
          }`,
          contactNo: el.phoneNumber || "",
          PanCardStatus:
            el.panStatus !== null ? makeStatus(el.panStatus) : "Not Filled",
        };
      }
      clients = [];
      extractedData.push(temp);
      return true;
    });
    return extractedData;
  };

  const makeStatus = (status) => {
    switch (status) {
      case 0:
        return "Not Filled";
      case 1:
        return "Verified";
      case 2:
        return "Flagged";
      case 3:
        return "Rejected";
      case 4:
        return "Pending";
      default:
        break;
    }
  };

  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const createAllRecordExcel = (obj) => {
    setAllRecordLoading(true);
    // let requestedBy = "";
    let filter = filterUrl;
    if (Object.keys(clientFilter).length) {
      filter = `&partnerId=${clientFilter.partner_id}`;
    }

    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listingV2
    }?page_no=${0}&rows=${totalRows}${filter}${
      crossFilter.value
    }&exportExcel=1`;

    let recruiter_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listing
    }?page_no=${0}&rows=${totalRows}${filter}${
      crossFilter.value
    }${isRecruiterData}`;

    Axios.get(tab.workerDataTab ? worker_url : recruiter_url)
      .then((response) => {
        console.log("CAllled", response);
        setAllRecord(makeDataForExport(response.data.response));
      })
      .catch((error) => {
        setLoading(false);
      });

    // let worker_url = `${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?${requestedBy}${filterUrl}${crossFilter.value}&exportExcel=1`;
    // let recruiter_url = `${urlLinks.baseUrl}${urlLinks.verificationAPIs.listing}?${requestedBy}${filterUrl}${crossFilter.value}${isRecruiterData}&exportExcel=1`;
    // Axios.get(tab.workerDataTab ? worker_url : recruiter_url).then(
    //   (response) => {
    //     if (response) {
    //       setAllRecord(makeWorkerData(response.data.response));
    //     }
    //   }
    // );
  };

  const handleOpenExportModal = () => {
    createAllRecordExcel();
  };

  useEffect(() => {
    setAllRecordLoading(false);
    console.log(allRecord, "-----");
  }, [allRecord]);

  const getExternalWorkerList = (rows, pageNo, type = "") => {
    setLoading(true);
    let filter = filterUrl;
    if (clientFilter.partner_id && clientFilter.partner_id != undefined) {
      filter = `&partnerId=${clientFilter.partner_id}`;
    }

    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listingV2
    }?page_no=${pageNo - 1}&rows=${rows}${filter}${crossFilter.value}`;

    let recruiter_url = `${urlLinks.baseUrl}${
      urlLinks.verificationAPIs.listing
    }?page_no=${pageNo - 1}&rows=${rows}${filter}${
      crossFilter.value
    }${isRecruiterData}`;

    Axios.get(tab.workerDataTab ? worker_url : recruiter_url)
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setAllRecord([]);
  }, [filterUrl, localStorage.getItem("roleType"), crossFilter, tab]);

  const columns = tab.workerDataTab ? workerDataColumn : recruiterDataColumn;
  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table" style={{ width: "100%" }}>
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.render("Header") ===
                        "Document Verification Status"
                      ? "width300 header"
                      : "header"
                  }
                >
                  <span
                    style={
                      column.render("Header") === "addressGoogle" ||
                      column.render("Header") === "documentsPending"
                        ? { width: "250px" }
                        : {}
                    }
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  const handleTab = (event, name) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch();
    //////////////////////////////////

    if (name === "1") {
      setTab({ ...tab, workerDataTab: true, recruiterDataTab: false });
      setIsRecruiterData("");
      setCurrentPage(1);
    } else if (name === "2") {
      setTab({ ...tab, workerDataTab: false, recruiterDataTab: true });
      setIsRecruiterData("&isRecruiter=1");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    setClientDropDown(false);
    setCurrentPage(1);
  }, [tab, crossFilter]);

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay justifyFlexStart">
          <span className="fontSize24 fontWeightBold marginRight20">
            Document Details Verification
          </span>
          <Button
            onClick={(event) => {
              if (tab.recruiterDataTab === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker
          </Button>
          <Button
            onClick={(event) => {
              if (tab.workerDataTab === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusButtons ${
              tab.recruiterDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Recruiter
          </Button>
        </Grid>
        <Grid item xs={12} className="flexDisplay">
          <Grid
            item
            xs={7}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder={
                tab.recruiterDataTab
                  ? "Contact number / Recruiter ID "
                  : "Enter Aadhar No./ Contact number / OkayGo ID"
              }
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
        </Grid>
        <div className={styles.btnTray}>
          <Grid container className="flexDisplay paddingTop16 justifyFlexStart">
            {
              <Grid item xs={10}>
                <Button
                  onClick={(event) => {
                    {
                      setCrossFilter({
                        value: "&verificationStatus=2",
                        index: 2,
                      });
                    }
                  }}
                  className={`statusButtons marginRight20 ${
                    crossFilter.value === "&verificationStatus=2"
                      ? "blueColorBorder"
                      : "greyColorBorder"
                  }`}
                >
                  Flagged
                </Button>
                <Button
                  onClick={(event) => {
                    {
                      setCrossFilter({
                        value: "&verificationStatus=0",
                        index: 0,
                      });
                    }
                  }}
                  className={`statusButtons marginRight20 ${
                    crossFilter.value === "&verificationStatus=0"
                      ? "blueColorBorder"
                      : "greyColorBorder"
                  }`}
                >
                  Not filled
                </Button>
                <Button
                  onClick={(event) => {
                    {
                      setCrossFilter({
                        value: "&verificationStatus=1",
                        index: 1,
                      });
                    }
                  }}
                  className={`statusButtons marginRight20 ${
                    crossFilter.value === "&verificationStatus=1"
                      ? "blueColorBorder"
                      : "greyColorBorder"
                  }`}
                >
                  Verified
                </Button>
                <Button
                  onClick={(event) => {
                    setCrossFilter({
                      value: "&verificationStatus=3",
                      index: 3,
                    });
                  }}
                  className={`statusButtons marginRight20 ${
                    crossFilter.value === "&verificationStatus=3"
                      ? "blueColorBorder"
                      : "greyColorBorder"
                  }`}
                >
                  Rejected
                </Button>
                <Button
                  onClick={(event) => {
                    setCrossFilter({ value: "", index: 0 });
                  }}
                  className={`statusButtons marginRight20 ${
                    crossFilter.value === ""
                      ? "blueColorBorder"
                      : "greyColorBorder"
                  }`}
                >
                  All
                </Button>
              </Grid>
            }
          </Grid>
          {!tab.recruiterDataTab && (
            <Grid
              container
              style={{
                paddingTop: "8px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              
              {!!userAction.export_access && (
                <ExportButton
                  uniqueKey="docVerify"
                  createAllRecordExcel={createAllRecordExcel}
                  allRecordLoading={allRecordLoading}
                  filename="Worker Data.csv"
                  buttonName="Export to Excel"
                  loadingMessage="Please wait ... it might take few minutes."
                  downloadButton="Download now"
                  handleOpenExportModal={handleOpenExportModal}
                />
              )}
              <div className={styles.filterDropdownBox}>
                <div
                  onClick={() => setClientDropDown((prev) => !prev)}
                  className={styles.inputDiv}
                >
                  <input
                    value={clientFilter.company_name || ""}
                    className={styles.filterDropdownBoxInput}
                    placeholder="Filter by Client"
                    readOnly
                  />
                  {Object.keys(clientFilter).length > 0 && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setClientFilter({});
                        setSearchTerm(""); // Reset search
                        setClientDropDown(false);
                      }}
                      className={styles.closeBtn}
                    >
                      <img src={crossSvg} />
                    </div>
                  )}
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setClientDropDown(true);
                    }}
                    className={styles.closeBtn2}
                  >
                    <img src={dropDown} />
                  </div>
                </div>

                {clientDropDown && (
                  <div className={styles.dropDown}>
                    <div className={styles.searchContainer}>
                      <input
                        type="text"
                        className={styles.filterDropdownBoxInput}
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <img src={searchIcon} className={styles.searchIcon} />
                    </div>
                    {filteredClients.length > 0 ? (
                      filteredClients.map((i, index) => (
                        <div
                          key={`${i.company_name}-${index}`}
                          onClick={() => {
                            setClientFilter(i);
                            setSearchTerm(""); // Reset search
                            setClientDropDown(false);
                          }}
                          className={styles.dropDownItem}
                        >
                          {i.company_name}
                        </div>
                      ))
                    ) : (
                      <div className={styles.dropDownItem}>
                        No results found
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Grid>
          )}
        </div>

        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        <ProfileVerification
          uniqueKey="profileVerification"
          open={openProfileVerificationModal}
          setOpen={setOpenProfileVerificationModal}
          title={`Profile Verification`}
          dataList={profileVerificationList}
          leftPicName="Aadhaar photo"
          rightPicName="Profile photo"
          leftPicLink={profileVerificationList.aadharPhoto}
          rightPicLink={profileVerificationList.profilePhoto}
          showToast={showToast}
          updateMyData={updateMyData}
        />
      </Grid>
    </Styles>
  );
};

export default WorkerData;
