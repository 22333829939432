import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import { Button, Grid, Checkbox, Drawer } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import Axios from "axios";
import styles from "./index.module.scss";
import ExportModal from "./components/ExportModal";
import swal from "sweetalert";
import DocumentDownload from "./components/downloadDocuments";
import OnboardingModalV2 from "./components/onboardingModalV2";
import OnBoardingModalV3 from "./components/onBoardingModalV3";
import UploadAJWorkerModal from "./components/UploadAJWorkers";
import CasperOnboardingModal from "./components/CasperOnboardingModal";
import AssignToRecruiterModal from "../WorkerData/components/AssignToRecruiter";
import Pagination from "../../Utils/Pagination";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FilterDrawer from "./filters";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import LastAppliedJob from "./components/LastJobApplied";
import BGVStatus from "../BGVListing/components/BGVDocumentStatus";
import EditContactModal from "./components/EditPhoneNumber";
import ReactivationModal from "./components/ReactivtionDialog";
import CriminalRecord from "./components/CriminalRecord";
import SendMsg from "./components/SendMsg";
import ScaleLoader from "react-spinners/ScaleLoader";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { AuthContext } from "../../services/AuthProvider";
import InfoIcon from "@material-ui/icons/Info";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import UrlLinks from "../../UrlLinks";
import Tooltip from "@material-ui/core/Tooltip";
import LastSentMsgs from "./components/LastSentMsgs";
import "./indeterminateCheckbox.css";
import OnboardingModal from "../../Utils/dialogs/OnboardingModal";
import SearchBar from "../../Utils/functions/SearchBar";
import LeadSearchBar from "../../Utils/functions/LeadSearchBar";
import ActionsDropdown from "../../Utils/functions/ActionsDropdown";
import ExportButton from "../../Utils/functions/ExportButton";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import { FirstUpperCase, urlToPromise } from "../../Utils/CommonFunctions";
import HistoryModal from "../../Utils/dialogs/HistoryModal";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import { AppData } from "../../context/context";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const WorkerData = (props) => {
  /** accessing states from redux store **/
  const { users, workerData } = useSelector((state) => state);
  const { allRecord } = users;
  const { rowData } = workerData;

  /** useDispatch is used to update states to redux store **/
  const dispatch = useDispatch();
  const { setRowData, setAllRecord } = bindActionCreators(
    actionCreators,
    dispatch
  );

  /** other states **/
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const { userAction } = useContext(AppData);
  const [data, setData] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSendMsg, setOpenSendMsg] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
  const [openMettlAssessorModal, setOpenMettlAssessorModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [openOnboardingModalV2, setOpenOnboardingModalV2] = useState(false);
  const [openAJworkerUploadModal, setOpenAJworkerUploadModal] = useState(false);
  const [openOnboardingApnaModalV3, setOpenOnboardingApnaModalV3] =
    useState(false);
  const [openCasperOnboardingModal, setOpenCasperOnboardingModal] =
    useState(false);
  const [openInsuranceTagModal, setOpenInsuranceTagModal] = useState(false);
  const [disable, setDisable] = useState(true);
  const [searchByAuto, setSearchByAuto] = useState("");
  const [searchKeyByAuto, setSearchKeyByAuto] = useState("");
  const [editContactData, setEditContactData] = useState({
    userId: null,
    contact: null,
    open: false,
    error: "",
  });
  const [reactivationData, setReactivationData] = useState({
    open: false,
    userId: null,
    date: null,
  });
  const [crossFilter, setCrossFilter] = useState("");
  const [assignFilterString, setAssignFilterString] = useState("");
  const [actions, setActions] = useState(-1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchBy, setSearchBy] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [docListLoader, setDocListLoader] = useState(null);
  const [downloadDocError, setDownloadDocError] = useState(null);
  const [leadCityId, setLeadCityId] = useState([]);
  const [leadSourceId, setLeadSourceId] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searchActions, setSearchActions] = useState("Search by");
  const [curLeadId, setCurLeadId] = useState({
    leadCity: [],
    leadSource: [],
  });
  const [searchError, setSearchError] = useState(false);
  const [criminalRecordData, setCriminalRecordData] = useState({
    open: false,
    userId: null,
  });
  const [tab, setTab] = useState({
    workerDataTab: true,
    leadDataTab: false,
  });
  const [isLeadData, setIsLeadData] = useState("");
  const [assignFilter, setAssignFilter] = useState({
    workerTabFilter: {
      all: true,
      crossutilized: false,
      singleOrganisation: false,
      inactive: false,
    },
    leadTabFilter: { all: true, notAssigned: false, assigned: false },
  });
  const [partners, setPartners] = useState([{}]);
  const [openServiceAgreementModal, setOpenServiceAgreementModal] =
    useState(false);
  const [serviceAgreementColumns, setServiceAgreementColumns] = useState([]);
  const [serviceAgreementList, setServiceAgreementList] = useState([]);
  const [openBriberyAgreementModal, setOpenBriberyAgreementModal] =
    useState(false);
  const [briberyAgreementColumns, setBriberyAgreementColumns] = useState([]);
  const [briberyAgreementList, setBriberyAgreementList] = useState([]);
  const [filters, setFilters] = useState({
    active: false,
    all: true,
    appLead: "",
    bankDetails: "",
    bgvStatus: "",
    callStatus: "",
    clientEarningStatus: "",
    clientStatus: "",
    criminalRecord: false,
    docAvail: "",
    facilityCity: "",
    facilityName: "",
    facilityState: "",
    firstEarningDate: [null, null],
    functionalArea: "",
    idGenerationDate: [null, null],
    inactive: false,
    interviewDate: [null, null],
    interviewStatus: "",
    IsLmaStatus: "",
    lastAppliedBy: "",
    lastAppliedOn: [null, null],
    lastEarningDate: [null, null],
    leadAddedDate: [null, null],
    leadCity: "",
    leadSource: "",
    leadState: "",
    notEarning: "",
    offboardingInitiated: false,
    onboardingInitiated: false,
    partnerId: "",
    preferedHub: "",
    projectName: "",
    psf: "",
    RecruiterAssignedBetween: [null, null],
    recruiterId: "",
    roleType: "",
    searchBy: "",
    searchKey: "",
    source: "",
    temporarilyInactive: false,
    trainingUpdate: "",
    workerStatus: "",
    workFor: "",
    zone: "",
  });

  const searchBarList = ["Lead ID", "Aadhar No", "Pincode", "Phone No"];

  const actionList = [
    tab.workerDataTab
      ? {
          value: "uploadWorkerData",
          label: "Upload Worker data",
        }
      : {},
    tab.leadDataTab
      ? {
          value: "uploadApnaWorkerData",
          label: "Upload leads data",
        }
      : {},
    tab.workerDataTab
      ? {
          value: "UploadCasperID",
          label: "Upload Client IDs",
        }
      : {},
    tab.workerDataTab
      ? {
          value: "UploadInsuranceTags",
          label: "Upload Insurance Tags",
        }
      : {},
    tab.workerDataTab
      ? {
          value: "UploadMettlAssessorWorker",
          label: "Upload Mettl Assessor Worker",
        }
      : {},
    tab.workerDataTab
      ? {
          value: "uploadOffboardingData",
          label: "Upload Offboarding data",
        }
      : {},
    tab.workerDataTab
      ? {
          value: "agreement",
          label: "Download Agreements",
        }
      : {},
  ];

  const tabsData = [
    { label: "All", value: "all" },
    tab.workerDataTab ? { label: "Crossutilized", value: "crossutilized" } : {},
    tab.workerDataTab
      ? {
          label: "Single organisation",
          value: "singleOrganisation",
        }
      : {},
    tab.workerDataTab ? { label: "Inactive", value: "inactive" } : {},
    tab.leadDataTab ? { label: "Not Assigned", value: "notAssigned" } : {},
    tab.leadDataTab ? { label: "Assigned", value: "assigned" } : {},
  ];

  const workerDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
              user={row}
            />
          </div>
        ),
      },
      {
        Header: "OkayGo id",
        accessor: "okaygoId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return row.row.original.criminalRecord === "Yes" ? (
            <button
              className={styles.workerName}
              type="button"
              id={row.row.original.userId}
              onClick={handleCriminalRecord}
            >
              {row.row.original.name}
            </button>
          ) : (
            <Link
              to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
              target="_blank"
              className="nameLink"
            >
              {row.row.original.name}
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        // accessor: "contactNo",
        Cell: (row) => {
          return (
            <div>
              {(row.row.original.facilityData.some(el => el.clientName === "TCPL") && row.row.original.mpaName) ? "XXXXXXXXXX" : row.row.original.contactNo}  
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Father's Name",
        accessor: "fathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "DOB",
        accessor: "dob",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Source",
        accessor: "source",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Lead Source",
        accessor: "sourceLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Lead Added On",
        accessor: "leadAddedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Status",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                if (el.clientName)
                  return (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-status`}>
                      <b>{el.clientName}: </b>
                      {createClientStatus(el.clientIdStatus)}
                    </div>
                  );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client ID(s)",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.client_id && (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-id`}>
                      <b>{el.clientName}: </b>
                      {el.client_id}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "BGV Status",
        accessor: "BGVstatus",
        Cell: ({ row }) => {
          return <BGVStatus status={row.original.BGVstatus} />;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Earning Status",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  (el.earning_status === 1 || el.earning_status === 0) && (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-earning`}>
                      <b>{el.clientName}: </b>
                      {el.earning_status === 1 ? "Active" : "Inactive"}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: (row) => {
          return (
            <div>
              App Status
              <HtmlTooltip
                title={
                  <div>
                    <ul>
                      <li>
                        <b>Registered:</b> verified OTP
                      </li>
                      <li>
                        <b>Basic details filled:</b> filled name ,father
                        name,dob
                      </li>
                      <li>
                        <b>Documents-Partially pending:</b> Some document
                        uploaded
                      </li>
                      <li>
                        <b>Onboarding Done:</b>All docs uploaded(Aadhar
                        front/back ,DL,Current address ,PAN)
                      </li>
                      <li>
                        <b>Not registered:</b> app not downloaded
                      </li>
                    </ul>
                  </div>
                }
              >
                <InfoIcon
                  style={{ verticalAlign: "bottom" }}
                  fontSize="small"
                />
              </HtmlTooltip>
            </div>
          );
        },
        accessor: "appStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents Pending",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.documentsPending}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "documents",
        Cell: DocumentDownload,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Bank Details",
        accessor: "bankDetails",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhar Number",
        accessor: "aadharNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "City",
        accessor: "city",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address (Google address)",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>
              {row.row.original.addressGoogle}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role Type",
        accessor: "roleType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility name",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                if (el.clientName === "Flipkart")
                  return (
                    el.facilityName && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fname`}>
                        <b>{el.clientName}: </b>
                        {el.facilityName}
                      </div>
                    )
                  );
                else
                  return (
                    el.clusterName && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fname`}>
                        <b>{el.clientName}: </b>
                        {el.clusterName}
                      </div>
                    )
                  );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility city",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                if (el.clientName === "Flipkart")
                  return (
                    el.facilityCity && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fcity`}>
                        <b>{el.clientName}: </b>
                        {el.facilityCity}
                      </div>
                    )
                  );
                else
                  return (
                    el.clusterCity && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fcity`}>
                        <b>{el.clientName}: </b>
                        {el.clusterCity}
                      </div>
                    )
                  );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Facility state",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                if (el.clientName === "Flipkart")
                  return (
                    el.facilityState && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fstate`}>
                        <b>{el.clientName}: </b>
                        {el.facilityState}
                      </div>
                    )
                  );
                else
                  return (
                    el.clusterState && (
                      <div key={`${id}-${el.partnerId}-${el.workerId}-Fstate`}>
                        <b>{el.clientName}: </b>
                        {el.clusterState}
                      </div>
                    )
                  );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Zone",
        defaultCanFilter: false,
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.zone && (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-zone`}>
                      <b>{el.clientName}: </b>
                      {el.zone}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanSort: false,
      },
      {
        Header: "Modal Flag",
        accessor: "lmaGroupFlexEflex",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Rate Card",
        defaultCanFilter: false,
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                if (el.clientName === "Flipkart") {
                  return (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-rateCard`}>
                      <b>{el.clientName}: </b>
                      {row.row.original.is_lma_worker
                        ? el.lmaRateCard
                        : el.rateCard}
                    </div>
                  );
                }
                return (
                  el.rateCardType && (
                    <div key={`${el.partnerId}-${el.workerId}-rateCard`}>
                      <b>{el.clientName}: </b>
                      {el.rateCardType}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanSort: false,
      },
      {
        Header: "First Earning Date",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.first_earning_date && (
                    <div
                      key={`${id}-${el.partnerId}-${el.workerId}-firstEarning`}
                    >
                      <b>{el.clientName}: </b>
                      {moment(el.first_earning_date).format("DD/MM/YYYY")}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Earning Date",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.lastEarningOn && (
                    <div
                      key={`${id}-${el.partnerId}-${el.workerId}-notEarning`}
                    >
                      <b>{el.clientName}: </b>
                      {moment(el.lastEarningOn).format("DD/MM/YYYY")}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Days Worked",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.workingDays >= 0 && (
                    <div
                      key={`${id}-${el.partnerId}-${el.workerId}-workingDays`}
                    >
                      <b>{el.clientName}: </b>
                      {el.workingDays}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Cross Utilization Status",
        accessor: "crossUtilizationStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Job applied on",
        accessor: "lastJobAppliedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Job applied for",
        Cell: LastAppliedJob,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Service Agreement",
        Cell: (row) => {
          const agreementAvailable = row.row.original.facilityData.filter(
            (e) => e.userAgreementAcceptance
          );
          return agreementAvailable.length > 0 ? (
            <button
              className={styles.workerName}
              style={{ fontSize: "16px" }}
              type="button"
              onClick={(e) =>
                handleUserModal(e, row.row.original, "serviceAgreement")
              }
            >
              View
            </button>
          ) : (
            "-"
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Anti-Bribery Agreement",
        Cell: (row) => {
          const briberyAvailable = row.row.original.facilityData.filter(
            (e) => e.antiBriberyAcceptance
          );
          return briberyAvailable.length > 0 ? (
            <button
              className={styles.workerName}
              style={{ fontSize: "16px" }}
              type="button"
              onClick={(e) =>
                handleUserModal(e, row.row.original, "briberyAgreement")
              }
            >
              View
            </button>
          ) : (
            "-"
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Recruiter Assigned",
        accessor: "recruiterAssignedName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned On",
        accessor: "assignedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview For",
        accessor: "client",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Call Status",
        accessor: "callStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Application Status",
        accessor: "applicationStatus",
      },
      {
        Header: "Interview Date",
        accessor: "interviewDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview Status",
        accessor: "interviewStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Training Start Date",
        accessor: "trainingStartDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Update",
        accessor: "trainingUpdate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Updated On",
        accessor: "lastUpdate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "ID Generated On",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.client_id_inserted_on && (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-idGenOn`}>
                      <b>{el.clientName}: </b>
                      {moment(el.client_id_inserted_on).format("DD/MM/YYYY")}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "FOD Status",
        Cell: (row) => {
          return (
            <div>
              {row.row.original.facilityData.map((el, id) => {
                return (
                  el.fodStatus && (
                    <div key={`${id}-${el.partnerId}-${el.workerId}-fod`}>
                      <b>{el.clientName}: </b>
                      {el.fodStatus === 1
                        ? "Done"
                        : el.fodStatus === 2
                        ? "Pending"
                        : ""}
                    </div>
                  )
                );
              })}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "FOD Follow up status",
        Cell: (row) => {
          return (
            <div>
              <div>{row.row.original.followupstatus}</div>
              {row.row.original.concern && (
                <div>
                  <b>Remark : </b>
                  {row.row.original.concern}
                </div>
              )}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added on",
        accessor: "addedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added by",
        accessor: "addedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const leadsColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <>
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                style={{ display: "flex", justifyItems: "center" }}
              />
            </div>{" "}
          </>
        ),
      },
      {
        Header: "Lead id",
        accessor: "leadId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          // till the worker id not assigned to lead -> showing lead view
          if (
            row.row.original.workerId === null ||
            row.row.original.workerId === undefined
          ) {
            return row.row.original.criminalRecord === "Yes" ? (
              <button
                className={styles.workerName}
                type="button"
                id={row.row.original.userId}
                onClick={handleCriminalRecord}
              >
                {row.row.original.name}
              </button>
            ) : (
              <Link
                to={{
                  pathname: `/leadWorkerview/v2/${row.row.original.userId}`,
                }}
                className="nameLink"
              >
                {row.row.original.name}
              </Link>
            );
          } else {
            return row.row.original.criminalRecord === "Yes" ? (
              <button
                className={styles.workerName}
                type="button"
                id={row.row.original.userId}
                onClick={handleCriminalRecord}
              >
                {row.row.original.name}
              </button>
            ) : (
              <Link
                to={{ pathname: `/workerview/v2/${row.row.original.userId}` }}
                className="nameLink"
              >
                {row.row.original.name}
              </Link>
            );
          }
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },

      {
        Header: "Father's Name",
        accessor: "fathersName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "DOB",
        accessor: "dob",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Source",
        accessor: "sourceLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Functional Area",
        accessor: "functionalArea",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: (row) => {
          return (
            <div>
              App Status
              <HtmlTooltip
                title={
                  <div>
                    <ul>
                      <li>
                        <b>Registered:</b> verified OTP
                      </li>
                      <li>
                        <b>Basic details filled:</b> filled name ,father
                        name,dob
                      </li>
                      <li>
                        <b>Documents-Partially pending:</b> Some document
                        uploaded
                      </li>
                      <li>
                        <b>Onboarding Done:</b>All docs uploaded(Aadhar
                        front/back ,DL,Current address ,PAN)
                      </li>
                      <li>
                        <b>Not registered:</b> app not downloaded
                      </li>
                    </ul>
                  </div>
                }
              >
                <InfoIcon
                  style={{ verticalAlign: "bottom" }}
                  fontSize="small"
                />
              </HtmlTooltip>
            </div>
          );
        },
        accessor: "appStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last SMS sent",
        Cell: LastSentMsgs,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Aadhar Number",
        accessor: "aadhaarNumberLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Documents",
        accessor: "documents",
        Cell: DocumentDownload,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Lead City",
        accessor: "leadCity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Lead State",
        accessor: "leadState",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Address",
        accessor: "addressLead",
        Cell: (row) => {
          return (
            <div style={{ width: "250px" }}>{row.row.original.addressLead}</div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role Type",
        accessor: "roleType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Job City",
        accessor: "jobCity",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Job State",
        accessor: "jobState",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Job applied on",
        accessor: "lastJobAppliedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Job applied for",
        Cell: LastAppliedJob,
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Recruiter Assigned",
        accessor: "recruiterAssignedLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Assigned On",
        accessor: "assignedOnLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Call Status",
        accessor: "callStatusLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Application Status",
        accessor: "applicationStatus",
      },
      {
        Header: "First called on",
        accessor: "firstCalledOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last called on",
        accessor: "lastCalledOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview Date",
        accessor: "interviewDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client Name",
        accessor: "clientNameLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Preferred Hub",
        accessor: "preferredHubLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Interview Status",
        accessor: "interviewStatus",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Start Date",
        accessor: "trainingStartDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Training Update",
        accessor: "trainingUpdate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Last Updated On",
        accessor: "lastUpdateLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Follow Up Date & Time",
        accessor: "followUpLead",
        Cell: (row) => {
          return (
            <div style={{ width: "0px" }}>{row.row.original.followUpLead}</div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added on",
        accessor: "addedOnLead",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  const columns = tab.workerDataTab ? workerDataColumn : leadsColumn;

  // Function to update the Table Data
  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  const resetFiltersWithoutSearch = () => {
    const filterList = {
      source: "",
      searchBy: filters.searchBy,
      searchKey: filters.searchKey,
      zone: "",
      facilityState: "",
      facilityName: "",
      partnerId: "",
      facilityCity: "",
      workFor: "",
      workerStatus: "",
      psf: "",
      callStatus: "",
      interviewStatus: "",
      applicationStatus: "",
      recruiterId: "",
      trainingUpdate: "",
      preferedHub: "",
      appLead: "",
      interviewDate: [null, null],
      firstEarningDate: [null, null],
      lastEarningDate: [null, null],
      leadAddedDate: [null, null],
      bankDetails: "",
      leadCity: "",
      leadSource: "",
      notEarning: "",
      appStatus: "",
      clientEarningStatus: "",
      clientStatus: "",
      RecruiterAssignedBetween: [null, null],
      idGenerationDate: [null, null],
      IsLmaStatus: "",
      bgvStatus: "",
      lastAppliedBy: "",
      lastAppliedOn: [null, null],
      leadState: "",
      roleType: "",
      functionalArea: "",
      docAvail: "",
      projectName: "",
    };

    if (openFilterDrawer) {
      if (tab.workerDataTab) {
        setFilterUrl(filters.searchBy ? "&searchBy=" + filters.searchBy : "");
      } else if (tab.leadDataTab) {
        setFilterUrl(
          filters.searchKey !== "" && filters.searchBy
            ? "&searchField=" +
                filters.searchKey +
                "&searchValue=" +
                filters.searchBy
            : ""
        );
      }
    } else {
      if (!tab.workerDataTab) {
        setFilterUrl(
          filters.searchKey && filters.searchBy
            ? "&searchBy=" + filters.searchBy
            : ""
        );
      } else if (!tab.leadDataTab) {
        setFilterUrl(
          filters.searchKey !== "" && filters.searchBy
            ? "&searchField=" +
                filters.searchKey +
                "&searchValue=" +
                filters.searchBy
            : ""
        );
      }
    }

    setFilters(filterList);
    setLeadCityId([]);
    setLeadSourceId([]);
    setCurLeadId({
      leadCity: [],
      leadSource: [],
    });
    setCurrentPage(1);
    return filterList;
  };

  const createDocumentZip = (documents, profilePhoto, okaygoId, name) => {
    var zip = new JSZip();
    var fileUrl = "";
    var profilePhotoURL = "";
    var photoName = "";
    documents.forEach(function (doc, index) {
      var filename = doc.file_name;
      filename = filename.substring(0, filename.indexOf("?"));
      if (doc.file_path.substring(0, 5) === "http:") {
        // in case if it come https, link wont become httpss
        fileUrl = "https" + doc.file_path.substring(4, doc.file_path.length);
      } else {
        fileUrl = doc.file_path;
      }
      zip.file(filename, urlToPromise(fileUrl), { binary: true });
      if (
        doc.profilePhoto !== null &&
        doc.profilePhoto !== "" &&
        !profilePhotoURL
      ) {
        if (doc.profilePhoto.substring(0, 5) === "http:") {
          profilePhotoURL =
            "https" + doc.profilePhoto.substring(4, doc.profilePhoto.length);
        } else {
          profilePhotoURL = doc.profilePhoto;
        }
        if (profilePhotoURL.includes(".jpg?")) {
          photoName = `${name.replaceAll(" ", "")}_Profile_Photo.jpg`;
        } else if (profilePhotoURL.includes(".png?")) {
          photoName = `${name.replaceAll(" ", "")}_Profile_Photo.png`;
        }
        zip.file(photoName, urlToPromise(profilePhotoURL), { binary: true });
      }
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${okaygoId}_${name}_Documents`);
      setDocListLoader(null);
    });
  };

  const getDocumentList = (userId, profilePhoto, okaygoId, name) => {
    setDocListLoader(userId);
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.workerUrls.docList}${parseInt(userId)}`
    )
      .then((response) => {
        if (response.data.response.content.length > 0 && profilePhoto) {
          createDocumentZip(
            response.data.response.content,
            profilePhoto,
            okaygoId,
            name
          );
        } else {
          setDocListLoader(null);
          setDownloadDocError({
            err: <span>No Document Available</span>,
            userId: userId,
          });
        }
      })
      .catch((error) => {
        setDocListLoader(null);
        setDownloadDocError({
          err: <span style={{ color: "red" }}>Error while downloading</span>,
          userId: userId,
        });
      });
  };

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy, searchKey: searchKey });
    setSearchByAuto(searchBy);
    setSearchKeyByAuto(searchKey);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
    setSearchByAuto("");
  };

  const handleCriminalRecord = (event) => {
    setCriminalRecordData({ userId: event.target.id, open: true });
  };

  const handleEditContactClose = () => {
    setEditContactData({
      userId: null,
      contact: null,
      open: false,
      error: "",
    });
  };

  const handleUserModal = async (event, row, type) => {
    switch (type) {
      case "serviceAgreement": {
        setRowData(row);
        setOpenServiceAgreementModal(true);
        setServiceAgreementColumns([
          {
            key: "projectName",
            label: "Project Name",
          },
          {
            key: "agreement",
            label: "Agreement",
          },
        ]);
        await Axios.get(
          `${UrlLinks.baseUrl}${UrlLinks.workerUrls.getAllAgreedProjectByUserId}?userId=${row.userId}`
        )
          .then((res) => {
            setServiceAgreementList(res.data.response);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }

      case "briberyAgreement": {
        setRowData(row);
        setOpenBriberyAgreementModal(true);
        setBriberyAgreementColumns([
          {
            key: "projectName",
            label: "Project Name",
          },
          {
            key: "agreement",
            label: "Agreement",
          },
        ]);
        await Axios.get(
          `${UrlLinks.baseUrl}/task-model/getAllAntiBriberyByUserId?userId=${row.userId}` // API to be modified
        )
          .then((res) => {
            setBriberyAgreementList(res.data.response);
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        return;
      }
    }
  };

  const handleReactivationDateClose = () => {
    setReactivationData({
      open: false,
      userId: null,
      date: null,
    });
  };

  const handleTab = (event, name) => {
    // RESET filters except search bar
    resetFiltersWithoutSearch();

    if (name === "1") {
      setTab({ ...tab, leadDataTab: false, workerDataTab: true });
      setIsLeadData("");
      setCurrentPage(1);
    } else if (name === "2") {
      setTab({ ...tab, leadDataTab: true, workerDataTab: false });
      setIsLeadData("&leadRecords=1");
      setCurrentPage(1);
    }
  };

  const updateFilterUrl = () => {
    let url = "";

    // Search By
    if (tab.workerDataTab && filters["searchBy"]) {
      url = `${url}&searchBy=${filters["searchBy"]}`;
    }
    if (
      tab.leadDataTab &&
      filters["searchBy"] !== "" &&
      filters["searchKey"] !== ""
    ) {
      url = `${url}&searchField=${filters["searchKey"]}&searchValue=${filters["searchBy"]}`;
    }
    if (filters["onboardingInitiated"]) {
      url = `${url}&workerStatus=2,5`;
    }
    if (filters["status"]) {
      url = `${url}&status=${filters["status"]}`;
    }
    if (filters["offboardingInitiated"]) {
      url = `${url}&workerStatus=4`;
    }
    if (filters["temporarilyInactive"]) {
      url = `${url}&workerStatus=6,8`;
    }
    if (filters["criminalRecord"]) {
      url = `${url}&criminal_record=1`;
    }
    if (filters["partnerId"]) {
      url = `${url}&partnerId=${filters["partnerId"].id}`;
    }
    if (filters["facilityName"]) {
      url = `${url}&facilityName=${filters["facilityName"].name}`;
    }
    if (filters["facilityCity"]) {
      url = `${url}&facilityCity=${filters["facilityCity"].name}`;
    }
    if (filters["facilityState"]) {
      url = `${url}&facilityState=${filters["facilityState"].name}`;
    }
    if (filters["source"]) {
      url = `${url}&source=${filters["source"].id}`;
    }
    if (filters["clientStatus"]) {
      url = `${url}&clientStatus=${filters["clientStatus"].id}`;
    }
    if (filters["clientEarningStatus"]) {
      url = `${url}&earningStatus=${filters["clientEarningStatus"].id}`;
    }
    if (filters["workerStatus"]) {
      url = `${url}&workerStatus=${filters["workerStatus"].id}`;
    }
    if (filters["workFor"]) {
      url = `${url}&workFor=${filters["workFor"]}`;
    }
    if (filters["psf"]) {
      url = `${url}&psf=${filters["psf"].id}`;
    }
    if (filters["leadSource"]) {
      url = `${url}&leadSource=${filters["leadSource"]}`;
    }
    if (filters["callStatus"]) {
      url = `${url}&callStatus=${filters["callStatus"].id}`;
    }
    if (filters["lastAppliedBy"]) {
      url = `${url}&lastAppliedBy=${filters["lastAppliedBy"].id}`;
    }
    if (filters["lastAppliedOn"][0] && !filters["lastAppliedOn"][1])
      url = `${url}&appliedFrom=${moment(filters["lastAppliedOn"][0]).format(
        "YYYY-MM-DD"
      )}&appliedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["lastAppliedOn"][0] && filters["lastAppliedOn"][1])
      url = `${url}&appliedFrom=2019-01-01&appliedTo=${moment(
        filters["lastAppliedOn"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["lastAppliedOn"][0] && filters["lastAppliedOn"][1])
      url = `${url}&appliedFrom=${moment(filters["lastAppliedOn"][0]).format(
        "YYYY-MM-DD"
      )}&appliedTo=${moment(filters["lastAppliedOn"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["appStatus"]) {
      url = `${url}&appStatus=${filters["appStatus"].id}`;
    }
    if (filters["interviewStatus"]) {
      url = `${url}&interviewStatus=${filters["interviewStatus"].id}`;
    }
    if (filters["applicationStatus"]) {
      url = `${url}&applicationStatus=${filters["applicationStatus"].id}`;
    }
    if (filters["recruiterId"]) {
      url = `${url}&recruiterId=${filters["recruiterId"].id}`;
    }
    if (filters["trainingUpdate"]) {
      url = `${url}&trainingUpdate=${filters["trainingUpdate"].id}`;
    }
    if (filters["preferedHub"]) {
      url = `${url}&preferedHub=${filters["preferedHub"].id}`;
    }
    if (filters["appLead"]) {
      url = `${url}&appLead=${filters["appLead"].id}`;
    }
    if (filters["bankDetails"] || filters["bankDetails"] === 0) {
      url = `${url}&bankdetail=${filters["bankDetails"].id}`;
    }
    if (filters["leadCity"]) {
      url = `${url}&leadCity=${filters["leadCity"]}`;
    }
    if (filters["IsLmaStatus"]) {
      url = `${url}&is_lma_worker=${filters["IsLmaStatus"].id}`;
    }
    if (filters["bgvStatus"]) {
      url = `${url}&bgvStatus=${filters["bgvStatus"].id}`;
    }
    if (
      filters["RecruiterAssignedBetween"][0] &&
      !filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=2019-01-01&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (
      filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&recruiterAssingedFrom=${moment(
        filters["RecruiterAssignedBetween"][0]
      ).format("YYYY-MM-DD")}&recruiterAssingedTO=${moment(
        filters["RecruiterAssignedBetween"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["idGenerationDate"][0] && !filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (
      !filters["RecruiterAssignedBetween"][0] &&
      filters["RecruiterAssignedBetween"][1]
    )
      url = `${url}&idGeneratedFrom=2019-01-01&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["idGenerationDate"][0] && filters["idGenerationDate"][1])
      url = `${url}&idGeneratedFrom=${moment(
        filters["idGenerationDate"][0]
      ).format("YYYY-MM-DD")}&idGeneratedTO=${moment(
        filters["idGenerationDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["interviewDate"][0] && !filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=2019-01-01&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["interviewDate"][0] && filters["interviewDate"][1])
      url = `${url}&interviewDateFrom=${moment(
        filters["interviewDate"][0]
      ).format("YYYY-MM-DD")}&interviewDateTO=${moment(
        filters["interviewDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["leadAddedDate"][0] && !filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=2019-01-01&leadAddedTo=${moment(
        filters["leadAddedDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["leadAddedDate"][0] && filters["leadAddedDate"][1])
      url = `${url}&leadAddedFrom=${moment(filters["leadAddedDate"][0]).format(
        "YYYY-MM-DD"
      )}&leadAddedTo=${moment(filters["leadAddedDate"][1]).format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["firstEarningDate"][0] && !filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=2019-01-01&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["firstEarningDate"][0] && filters["firstEarningDate"][1])
      url = `${url}&firstEarningFrom=${moment(
        filters["firstEarningDate"][0]
      ).format("YYYY-MM-DD")}&firstEarningTo=${moment(
        filters["firstEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["lastEarningDate"][0] && !filters["lastEarningDate"][1])
      url = `${url}&lastEarningFrom=${moment(
        filters["lastEarningDate"][0]
      ).format("YYYY-MM-DD")}&lastEarningTo=${moment(new Date()).format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (!filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
      url = `${url}&lastEarningFrom=2019-01-01&lastEarningTo=${moment(
        filters["lastEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["lastEarningDate"][0] && filters["lastEarningDate"][1])
      url = `${url}&lastEarningFrom=${moment(
        filters["lastEarningDate"][0]
      ).format("YYYY-MM-DD")}&lastEarningTo=${moment(
        filters["lastEarningDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;

    if (filters["notEarning"].id === "last3days")
      url = `${url}&notEarningFrom=${moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD")}&notEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["notEarning"].id === "last5days")
      url = `${url}&notEarningFrom=${moment()
        .subtract(4, "days")
        .format("YYYY-MM-DD")}&notEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;
    if (filters["notEarning"].id === "last7days")
      url = `${url}&notEarningFrom=${moment()
        .subtract(6, "days")
        .format("YYYY-MM-DD")}&notEarningTo=${moment().format(
        "YYYY-MM-DD"
      )} 23:59:59`;

    if (filters["leadState"]) {
      url = `${url}&state=${filters["leadState"].name}`;
    }
    if (filters["roleType"]) {
      url = `${url}&category=${filters["roleType"].id}`;
    }
    if (filters["functionalArea"]) {
      url = `${url}&functionalArea=${filters["functionalArea"].name}`;
    }
    if (filters["docAvail"] || filters["docAvail"] === 0) {
      url = `${url}&docAvailable=${filters["docAvail"].id}`;
    }
    if (filters["projectName"]) {
      url = `${url}&projectId=${filters["projectName"].id}`;
    }

    return url;
  };

  const ApplyFilters = () => {
    // debugger
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setLeadCityId(leadCityId);
    setCurrentPage(1);
  };

  const handleSendMsg = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = "";
    indexes.map((id, index) => {
      if (id[1]) {
        idList += data[parseFloat(id[0])].userId + ",";
      }

      return true;
    });
    idList = idList.substr(0, idList.length - 1);
    setSelectedUserIds(idList);
    setOpenSendMsg(!openSendMsg);
  };

  const handleAssignFilter = (e, type) => {
    if (tab.workerDataTab) {
      if (!assignFilter.workerTabFilter[type]) {
        // Update filters.tabs based on the type
        const updatedTabs = Object.fromEntries(
          Object.keys(assignFilter.workerTabFilter).map((key) => [
            key,
            key === type,
          ])
        );
        setAssignFilter({
          ...assignFilter,
          workerTabFilter: updatedTabs,
        });
      }
    } else {
      if (!assignFilter.leadTabFilter[type]) {
        // Update filters.tabs based on the type
        const updatedTabs = Object.fromEntries(
          Object.keys(assignFilter.leadTabFilter).map((key) => [
            key,
            key === type,
          ])
        );
        setAssignFilter({
          ...assignFilter,
          leadTabFilter: updatedTabs,
        });
      }
    }
    if (type === "all") {
      setCrossFilter("");
      setAssignFilterString("");
    } else if (type === "crossutilized") {
      setCrossFilter("&crossUtilStatus=2");
    } else if (type === "singleOrganisation") {
      setCrossFilter("&crossUtilStatus=1");
    } else if (type === "inactive") {
      setCrossFilter("&crossUtilStatus=0");
    } else if (type === "assigned") {
      setAssignFilterString("&notAssigned=1");
    } else if (type === "notAssigned") {
      setAssignFilterString("&notAssigned=0");
    }
    setCurrentPage(1);
  };

  const handleEditPhoneNumberService = (contact, userId) => {
    Axios.post(
      `${UrlLinks.baseUrl}${UrlLinks.workerUrls.editContact}?userId=${userId}&value=${contact}`
    )
      .then((response) => {
        if (response.data.code === 1000) {
          showToast("success", "Contact number updated successfully");
          handleEditContactClose();
          updateMyData();
        } else {
          setEditContactData({
            ...editContactData,
            error: response.data.response.error,
          });
        }
      })
      .catch((error) => {
        setEditContactData({ ...editContactData, error: error.message });
      });
  };

  const handleReactivationDate = () => {
    Axios.post(
      `${UrlLinks.baseUrl}${
        UrlLinks.workerUrls.workerReactivation
      }?reActivationDate=${moment(reactivationData.date).format(
        "YYYY-MM-DD 00:00:00"
      )}&userId=${reactivationData.userId}&updatedBy=${parseInt(
        localStorage.getItem("userID")
      )}`
    )
      .then((response) => {
        showToast("success", "Activation date set successfully");
        getExternalWorkerList(perPage, currentPage);
        setReactivationData({
          open: false,
          userId: null,
          date: null,
        });
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  const createClientStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Offboarded";
        break;
      case 1:
        text = "Active";
        break;
      default:
        break;
    }
    return text;
  };

  const createWorkerStatus = (status) => {
    let text = "";
    switch (status) {
      case 0:
        text = "Not Onboarded";
        break;
      case 1:
        text = "Active";
        break;
      case 2:
        text = "Partially Onboarded";
        break;
      case 3:
        text = "Inactive";
        break;
      case 4:
        text = "Offboarding Initiated";
        break;
      case 5:
        text = "Onboarding Completed";
        break;
      case 6:
        text = "Temporarily Inactive";
        break;
      case 7:
        text = "Temporary Inactivation Initiated";
        break;
      case 8:
        text = "Reactivation Initiated";
        break;
      default:
        break;
    }
    return text;
  };

  const setLeadRecruiterDetail = (recruiterList, type) => {
    if (recruiterList.length === 0) {
      return "";
    }
    if (type === "client") {
      return recruiterList[recruiterList.length - 1]?.employerName
        ? recruiterList[recruiterList.length - 1]?.employerName
        : "";
    }
    if (type === "applicationStatus") {
      return recruiterList[recruiterList.length - 1]?.applicationStatus
        ? recruiterList[recruiterList.length - 1]?.applicationStatus
        : "";
    }
    if (type === "interviewDate") {
      return `${
        recruiterList[recruiterList.length - 1]?.interviewDate
          ? moment(
              recruiterList[recruiterList.length - 1]?.interviewDate
            ).format("DD/MM/YYYY")
          : ""
      }`;
    }
    if (type === "trainingStartDate") {
      return `${
        recruiterList[recruiterList.length - 1]?.trainingStartDate
          ? moment(
              recruiterList[recruiterList.length - 1]?.trainingStartDate
            ).format("DD/MM/YYYY")
          : ""
      }`;
    }
    if (type === "callStatus") {
      return `${
        recruiterList[recruiterList.length - 1]?.callStatus
          ? recruiterList[recruiterList.length - 1]?.callStatus
          : ""
      }`;
    }
    if (type === "followupstatus") {
      return `${
        recruiterList[recruiterList.length - 1]?.followupStatus
          ? recruiterList[recruiterList.length - 1]?.followupStatus
          : ""
      }`;
    }

    if (type === "interviewStatus") {
      return `${
        recruiterList[recruiterList.length - 1]?.interviewStatus
          ? recruiterList[recruiterList.length - 1]?.interviewStatus
          : ""
      }`;
    }
    if (type === "trainingUpdate") {
      return `${
        recruiterList[recruiterList.length - 1]?.trainingStatus
          ? recruiterList[recruiterList.length - 1]?.trainingStatus
          : ""
      }`;
    }
    if (type === "assignedOn") {
      return `${
        recruiterList[recruiterList.length - 1]?.insertedOn
          ? moment(recruiterList[recruiterList.length - 1]?.insertedOn).format(
              "DD/MM/YYYY"
            )
          : ""
      }`;
    }

    if (type === "lastUpdate") {
      return `${
        recruiterList[recruiterList.length - 1]?.updatedOn
          ? moment(recruiterList[recruiterList.length - 1]?.updatedOn).format(
              "DD/MM/YYYY hh:mm a"
            )
          : ""
      }`;
    }

    if (type === "firstCalledOn") {
      return `${
        recruiterList[recruiterList.length - 1]?.firstcalled_on
          ? moment(
              recruiterList[recruiterList.length - 1]?.firstcalled_on
            ).format("DD/MM/YYYY hh:mm a")
          : ""
      }`;
    }

    if (type === "lastCalledOn") {
      return `${
        recruiterList[recruiterList.length - 1]?.lastcalled_on
          ? moment(
              recruiterList[recruiterList.length - 1]?.lastcalled_on
            ).format("DD/MM/YYYY hh:mm a")
          : ""
      }`;
    }
    if (type === "callStatusLead") {
      return `${
        recruiterList[recruiterList.length - 1]?.callStatus
          ? recruiterList[recruiterList.length - 1]?.callStatus
          : ""
      }`;
    }
    return "";
  };

  //Function to create table data
  const makeData = (response) => {
    const Mapper = (value) => {
      let key = Number(value);
      switch (key) {
        case 1:
          return "Applied";
        case 2:
          return "Not Eligible";
        case 3:
          return "Backed Out";
        case 4:
          return "DNA";
        case 5:
          return "Interview Pending";
        case 6:
          return "Interview Done";
        case 7:
          return "Training Done";
        case 8:
          return "Training Pending";
        case 9:
          return "Ready to Work";
        case 10:
          return "Started Working";
        case 11:
          return "No Task Available";
        case -1:
          return "Unactioned";
        default:
          return "-";
      }
    };
    let extractedData = [];
    let lastupdatedon = [];
    console.log(filters.partnerId)
    response.content.map((el) => {
      const sourceByType =
        el.sourceBy === "VENDOR"
          ? "Vendor"
          : el.sourceBy === "TA"
          ? "HRBP/TA"
          : el.sourceBy === "EREF"
          ? "Employee reference"
          : "";
      const sourceByName = el.sourceByName ? ` - ${el.sourceByName}` : "";
      const addedEmail = el.workerInsertedByEmail
        ? `(${el.workerInsertedByEmail})`
        : "";
      const lastAppliedDetails = el.lastJobAppliedDetails
        ? el.lastJobAppliedDetails.split("^")
        : [];
      extractedData.push({
        okaygoId: el.okaygoId || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        contactNo: el.phoneNumber || "",
        mpaName: el.mpaName|| null,
        source: el.partnerName || "",
        facilityData: el.clientList || [],
        appStatus: el.appStatus || "",
        documentsPending: el.pendingDoucment || "",
        bankDetails: el.accountNumber ? "Yes" : "No",
        lastSmsTemplate: el.lastSmsTemplate || "",
        lastSmsTime: el.lastSmsTime
          ? moment(el.lastSmsTime).format("hh:mm a, DD/MM/YYYY")
          : "",
        lastJobAppliedOn: lastAppliedDetails[0]
          ? moment(lastAppliedDetails[0]).format("DD/MM/YYYY")
          : "",
        lastJobAppliedFor:
          lastAppliedDetails.length > 1 ? lastAppliedDetails.slice(1) : [],
        lastSmsMessage: el.lastSmsMessage || "",
        aadharNumber: el.aadhaarNumber || "",
        BGVstatus: el.bgvFinalStatus,
        city: el.userCity || "",
        addressGoogle: el.userGoogleLocation || "",
        is_lma_worker: el.is_lma_worker,
        lmaGroupFlexEflex: el.is_lma_worker
          ? "LMA"
          : el.groupflexId
          ? "GroupFlex"
          : "Eflex",
        crossUtilizationStatus: el.crossUtilStatus || "",
        recruiterAssignedName:
          el.recruiterAssignedName !== null
            ? el.recruiterAssignedName +
              (el.recruiterAssignedNumber !== null
                ? " - " + el.recruiterAssignedNumber
                : "")
            : "",
        assignedOn: setLeadRecruiterDetail(el.recruitertList, "assignedOn"),
        client: setLeadRecruiterDetail(el.recruitertList, "client"),
        callStatus: setLeadRecruiterDetail(el.recruitertList, "callStatus"),
        interviewDate: setLeadRecruiterDetail(
          el.recruitertList,
          "interviewDate"
        ),
        interviewStatus: setLeadRecruiterDetail(
          el.recruitertList,
          "interviewStatus"
        ),
        trainingStartDate: setLeadRecruiterDetail(
          el.recruitertList,
          "trainingStartDate"
        ),
        trainingUpdate: setLeadRecruiterDetail(
          el.recruitertList,
          "trainingUpdate"
        ),
        lastUpdate: setLeadRecruiterDetail(el.recruitertList, "lastUpdate"),
        followUpLead: `${
          el.lastUpdate
            ? el.lastUpdate.follow_up_date
              ? moment(el.lastUpdate.follow_up_date).format("DD/MM/YYYY")
              : "-"
            : "-"
        } ${
          el.lastUpdate
            ? el.lastUpdate.follow_up_time
              ? moment(el.lastUpdate.follow_up_time)._i
              : "-"
            : "-"
        }`,

        idGeneratedOn: el.client_id_inserted_on
          ? moment(el.client_id_inserted_on).format("DD/MM/YYYY")
          : "",
        addedOn: el.workerInsertedOn
          ? moment(el.workerInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        addedBy: `${
          el.workerInsertedByName ? el.workerInsertedByName : ""
        } ${addedEmail}`,
        leadId: el.leadId || "",
        clientNameLead: el.clientName || "",
        sourceLead: el.leadSource || "",
        aadhaarNumberLead: el.aadharNumberLead || "",
        addressLead: el.userGoogleLocation || "",
        recruiterAssignedLead: `${
          el.recruiterAssignedName ? el.recruiterAssignedName : ""
        } ${el.recruiterAssignedNumber ? el.recruiterAssignedNumber : ""}`,
        assignedOnLead: setLeadRecruiterDetail(el.recruitertList, "assignedOn"),
        callStatusLead: setLeadRecruiterDetail(
          el.recruitertList,
          "callStatusLead"
        ),
        interviewDateLead: el.interviewDate
          ? moment(el.interviewDate).format("DD/MM/YYYY")
          : "",
        preferredHubLead: el.preferredHub || "",
        interviewStatusLead: el.interviewStatus || "",
        trainingStartDateLead: el.trainingStartDate
          ? moment(el.trainingStartDate).format("DD/MM/YYYY")
          : "",
        trainingUpdateLead: el.trainingUpdate || "",
        firstCalledOn: setLeadRecruiterDetail(
          el.recruitertList,
          "firstCalledOn"
        ),
        lastCalledOn: setLeadRecruiterDetail(el.recruitertList, "lastCalledOn"),
        leadAddedOn: el.leadAddedOn
          ? moment(el.leadAddedOn).format("DD/MM/YYYY hh:mm a")
          : "",
        lastUpdateLead: setLeadRecruiterDetail(el.recruitertList, "lastUpdate"),
        followupstatus: `${el.follow_up_status ? el.follow_up_status : ""}`,
        concern: `${el.concern ? el.concern : ""}`,

        addedOnLead: el.leadInsertedOn
          ? moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")
          : "",

        registeredOnApp:
          el.appLead !== null && el.appLead !== 0
            ? moment(el.leadInsertedOn).format("DD/MM/YYYY hh:mm a")
            : "--",
        leadCity: el.leadCity || "",
        userGoogleLocation: el.userGoogleLocation || "",
        leadIsAdhaar: el.leadIsAdhaar
          ? el.leadIsAdhaar === 1
            ? "Yes"
            : "No"
          : "No",
        leadIsDrivingLicense: el.leadIsDrivingLicense
          ? el.leadIsDrivingLicense === 1
            ? "Yes"
            : "No"
          : "No",
        leadIsBike: el.leadIsBike ? (el.leadIsBike === 1 ? "Yes" : "No") : "No",
        leadPincode: el.leadPincode || "",
        profileStatus:
          el.profileStatus === "4" ? "Completed" : el.profileStatus + "/4",
        functionalArea: el.functionalArea || "",
        smsCount: el.smsSentCount || "",
        smsDeliveryStatus: el.smsDeliveryStatus || "",
        LastSmsDeliveredOn: el.smsDeliveredOn
          ? moment(el.smsDeliveredOn).format("DD/MM/YYYY hh:mm a")
          : "",
        status: createWorkerStatus(el.workerStatus),
        fathersName: el.fatherName || "",
        gender:
          el.gender === "26"
            ? "Male"
            : el.gender === "27"
            ? "Female"
            : el.gender === "30"
            ? "Other"
            : "",
        dob: el.dateOfBirth ? moment(el.dateOfBirth).format("DD/MM/YYYY") : "",
        maritalStatus:
          el.maritalStatus === 1871
            ? "Single"
            : el.maritalStatus === 1872
            ? "Married"
            : el.maritalStatus === 1873
            ? "Other"
            : "",
        edab:
          el.physicallyDisabled === 1
            ? "Yes"
            : el.physicallyDisabled === 0
            ? "No"
            : "",
        criminalRecord:
          el.criminalRecord === 0 ? "No" : el.criminalRecord === 1 ? "Yes" : "",
        present_address: el.curAddressLine1 || "",
        workerStatus: el.workerStatus,
        present_city: el.curCity || "",
        present_state: el.curStateName || "",
        permanent_pincode: el.perPincode || "",
        present_pincode: el.curPincode || "",
        leadSource: el.leadSource || "",
        permanent_address: el.perAddressLine1 || "",
        permanent_city: el.perCity || "",
        permanent_state: el.perStateName || "",
        emergencyContactNo: el.emergencyContact || "",
        qualification: el.qualification || "",
        workExperience:
          el.totalExpYear || el.totalExpMonth
            ? `${el.totalExpYear ? el.totalExpYear : 0} yrs ${
                el.totalExpMonth ? el.totalExpMonth : 0
              } months`
            : "",
        rolePreference: el.jobRolePreferenceName || "",
        sourcedBy: `${sourceByType}${sourceByName}`,
        zone: el.zone || "",
        facility_name: el.facilityName || "",
        facility_city: el.facilityCity || "",
        facility_state: el.facilityState || "",
        rateCard: el.rateCardType || "",
        facility_type: el.facilityType ? el.facilityType : "",
        designation: el.designation || "",
        date_of_joining: el.dateOfJoining
          ? moment(el.dateOfJoining).format("DD/MM/YYYY")
          : "",
        activationDate: el.activeDate
          ? moment(el.activeDate).format("DD/MM/YYYY")
          : "",
        reactivationDate: el.reactivationDate
          ? moment(el.reactivationDate).format("DD/MM/YYYY")
          : null,
        reactivationDateTimestamp: el.reactivationDate
          ? el.reactivationDate
          : null,
        exitType: el.exitType || "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        deactivationDate: el.inactiveDate
          ? moment(el.inactiveDate).format("DD/MM/YYYY")
          : "",
        reasonOfLeaving: el.reasonOfLeaving || "",
        offboardingInitiateDate: el.offboardingInitiationDate
          ? moment(el.offboardingInitiationDate).format("DD/MM/YYYY")
          : "",
        offboardingInitiateBy: el.offboardingInitiateBy || "",
        blacklisted:
          el.blacklisted === 1 ? "Yes" : el.blacklisted === 0 ? "No " : "",
        userId: el.userId,
        agreementPath: el.agreementFilePath,
        agreementName: el.agreementFileName,
        profilePhoto: el.profilePhoto,
        groupflex: el.groupflexId ? el.groupflexId : "Eflex",
        partnerName: el.partnerName ? el.partnerName : "",
        workerId: el.workerId ? el.workerId : null,
        clients: makeClients(
          el.facilityName,
          el.zomatoWorkerStatus,
          el.swiggyWorkerStatus,
          el.delhiveryWorkerStatus,
          el.jiomartWorkerStatus
        ),
        documentsVerified: el.documentsVerified,
        leadInsertedOn: el.leadInsertedOn
          ? moment(el.leadInsertedOn).format("DD/MM/YYYY")
          : "",
        profileCompleted:
          el.docsUploadedUser !== null
            ? el.docsUploadedUser === 1
              ? "YES"
              : "NO"
            : "-",
        leader: el.isLeader !== null ? (el.isLeader === 1 ? "YES" : "NO") : "-",
        leadState: el.leadState ? el.leadState : "-",
        roleType: el.category ? el.category : "-",
        jobCity: el.jobCity ? el.jobCity : "-",
        jobState: el.jobState ? el.jobState : "-",
        applicationStatus:
          Mapper(
            setLeadRecruiterDetail(el.recruitertList, "applicationStatus")
          ) || "-",
      });
      lastupdatedon.push(el.lastUpdatedOn);
      return true;
    });
    return extractedData;
  };

  const makeClients = (
    casperId,
    zomatoId,
    swiggyId,
    delhiveryId,
    jiomartId
  ) => {
    let str = " ";
    if (casperId !== null && casperId !== undefined) {
      str = str + "Flipkart,";
    }
    if (zomatoId !== null && zomatoId !== undefined) {
      str = str + "Zomato,";
    }
    if (swiggyId !== null && swiggyId !== undefined) {
      str = str + "Swiggy,";
    }
    if (delhiveryId !== null && delhiveryId !== undefined) {
      str = str + "Delhivery,";
    }
    if (jiomartId !== null && jiomartId !== undefined) {
      str = str + "Jiomart,";
    }
    return str.substring(0, str.length - 1);
  };

  //Function to call export data
  const createAllRecordExcel = (obj) => {
    var filterUrl = obj.url;
    let excelExportedBy = localStorage.getItem("userID");

    if (isLeadData === "") {
      Axios.get(
        `${UrlLinks.baseUrl}${
          UrlLinks.workerUrls.workerDataListingv3
        }?${filterUrl}&exportExcel=1&sentToAll=${obj.sentToAll ? 1 : 0}${
          tab.leadDataTab ? assignFilterString : crossFilter
        }&excelExportedBy=${excelExportedBy}`
      ).then((response) => {
        if (response) {
          swal(response.data.response, "", "success");
        }
      });
    } else {
      Axios.get(
        `${UrlLinks.baseUrl}${
          UrlLinks.workerUrls.leadDataListingv3
        }?${filterUrl}&exportExcel=1&sentToAll=${obj.sentToAll ? 1 : 0}${
          tab.leadDataTab ? assignFilterString : crossFilter
        }&excelExportedBy=${excelExportedBy}`
      ).then((response) => {
        if (response) {
          swal(response.data.response, "", "success");
        }
      });
    }
  };

  const handleOpenExportModal = () => {
    setExportModal(true);
  };

  //Function to call main listing.
  const getExternalWorkerList = (rows, pageNo) => {
    setLoading(true);

    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${UrlLinks.baseUrl}${
          tab.leadDataTab
            ? UrlLinks.workerUrls.leadDataListingv3
            : UrlLinks.workerUrls.workerDataListingv3
        }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${
          tab.leadDataTab ? assignFilterString : crossFilter
        }`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const createZipForDocuments = (urls) => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Agreements.zip";
    urls.forEach(async function (url, index) {
      const record = Object.entries(url);
      const filename = `${record[0][0]}`;
      const path = `${record[0][1]}`;
      try {
        await JSZipUtils.getBinaryContent(path, function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          if (count === urls.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, zipFilename);
            });
          }
        });
      } catch (err) {}
    });
  };

  const handleDownloadAgreements = () => {
    const rowIds = Object.keys(selectedRowIds);
    let agreementUrls = [];
    rowIds.map((row) => {
      if (data[row].agreementPath) {
        const fileName = `${data[row].contactNo}_${data[row].name}.pdf`;
        agreementUrls.push({
          [fileName]: data[row].agreementPath,
        });
      }
    });
    if (agreementUrls.length > 0) {
      createZipForDocuments(agreementUrls);
    } else {
      showToast("error", "It will take some time to generate agreement.");
    }
  };

  const handleActions = (event) => {
    if (event.target.value === "uploadWorkerData") {
      // new onboarding Model v2
      setOpenOnboardingModalV2(true);
    } else if (event.target.value === "uploadApnaWorkerData") {
      setOpenOnboardingApnaModalV3(true);
    } else if (event.target.value === "uploadOffboardingData") {
      setOpenOffboardingModal(true);
    } else if (event.target.value === "UploadMettlAssessorWorker") {
      setOpenMettlAssessorModal(true);
    } else if (event.target.value === "UploadCasperID") {
      setOpenCasperOnboardingModal(true);
    } else if (event.target.value === "UploadInsuranceTags") {
      setOpenInsuranceTagModal(true);
    } else if (event.target.value === "agreement") {
      handleDownloadAgreements();
    } else if (event.target.value === "Lead ID") {
      setSearchKey("leadId");
      setSearchActions("Lead ID");
    } else if (event.target.value === "Aadhar No") {
      setSearchKey("aadhar");
      setSearchActions("Aadhar No");
    } else if (event.target.value === "Pincode") {
      setSearchKey("user_google_location");
      setSearchActions("Pincode");
    } else if (event.target.value === "Phone No") {
      setSearchKey("phoneNumber");
      setSearchActions("Phone No");
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getDocumentList,
      docListLoader,
      downloadDocError,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, id) => (
                <th
                  key={id}
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.render("Header") === "Profile Completion"
                      ? "" // not visible to hrbp and super hrbp
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "Last SMS sent"
                      ? "width200 header"
                      : "header"
                  }
                >
                  <span
                    style={
                      column.render("Header") === "addressGoogle" ||
                      column.render("Header") === "documentsPending"
                        ? { width: "250px" }
                        : {}
                    }
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell, j) => {
                    return (
                      <td
                        key={j}
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  /* Useffects */

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto, searchKeyByAuto]);

  useEffect(() => {
    handleSearch();
  }, [searchKey]);

  useEffect(() => {
    let searchByVar = RemoveExtraSpaces(searchBy);
    let count = 0;
    count += (searchByVar.match(/ /g) || []).length;
    count += (searchByVar.match(/,/g) || []).length;
    count++;
    if (count > 100) {
      setSearchError(true);
    } else if (count <= 100) {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setCurrentPage(1);
  }, [
    filterUrl,
    localStorage.getItem("roleType"),
    tab,
    assignFilterString,
    crossFilter,
  ]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisable(disableButton);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.getPartner}`).then((response) => {
      setPartners(response.data.response);
    });
  }, []);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12}>
          <span className="fontSize24 fontWeightBold marginRight20">
            Worker Data{" "}
          </span>
          <Button
            onClick={(event) => {
              if (tab.leadDataTab === true) {
                handleTab(event, "1");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.workerDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Worker Data
          </Button>
          <Button
            onClick={(event) => {
              if (tab.workerDataTab === true) {
                handleTab(event, "2");
              }
            }}
            className={`statusButtons marginRight20 ${
              tab.leadDataTab ? "blueColorBorder" : "greyColorBorder"
            }`}
          >
            Leads data
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay justifyContentSpaceBetween"
        >
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            {tab.leadDataTab ? (
              <LeadSearchBar
                uniqueId="LeadData"
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                searchError={searchError}
                filters={filters}
                setFilters={setFilters}
                searchActions={searchActions}
                handleActions={handleActions}
                handleSearch={handleSearch}
                searchBarList={searchBarList}
                handleSearchClose={handleSearchClose}
                ApplyFilters={ApplyFilters}
                setFilterUrl={setFilterUrl}
                placeholder='Select "Search by" and search here'
                searchInstructions="Use comma or space as delimiter to search multiple pin., lead id. Max limit 100.
              "
                warningMessage=" Max limit reached. Search 100 pin max."
              />
            ) : (
              <SearchBar
                uniqueId="WorkerData"
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                searchError={searchError}
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
                handleSearchClose={handleSearchClose}
                ApplyFilters={ApplyFilters}
                placeholder="Search by PIN/Aadhar No./ Contact number/ OkayGo ID/ Client ID"
                buttonName="Search"
                searchInstructions="Use comma or space as delimiter to search multiple OkayGo id, Client id & pin. Max limit 100."
                warningMessage=" Max limit reached. Search 100 pin max."
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8"
            style={{
              justifyContent: "flex-end",
              maxWidth: "550px",
            }}
          >
            {!!userAction.edit && (
              <div style={{ marginRight: "20px" }}>
                <ActionsDropdown
                  uniqueKey="actionWorker"
                  actions={actions}
                  handleActions={handleActions}
                  actionList={actionList}
                  isRowSelected={false}
                  disableOption={Object.keys(selectedRowIds).length === 0}
                  dropdownName="Actions"
                />
              </div>
            )}
            {!!userAction.export_access && (
              <div style={{ marginRight: "20px" }}>
                <ExportButton
                  uniqueKey="workerData"
                  createAllRecordExcel={createAllRecordExcel}
                  allRecordLoading={allRecordLoading}
                  filename={
                    isLeadData === "" ? `Worker Data.csv` : `Lead Data.csv`
                  }
                  buttonName="Export to Excel"
                  loadingMessage="Please wait ... it might take few minutes."
                  downloadButton="Download now"
                  handleOpenExportModal={handleOpenExportModal}
                />
              </div>
            )}
            <Button
              variant="outlined"
              className="dataButtonOutline"
              style={{ marginRight: "20px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              filters
            </Button>
            <Button
              variant="outlined"
              className="dataButtonOutline"
              onClick={() => handleSendMsg()}
              disabled={disable}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay paddingTop16 justifyFlexStart"
        >
          {!!userAction.edit && (
            <Button
              variant="contained"
              className="dataButton"
              style={{ marginTop: "7px", marginRight: "20px" }}
              onClick={() => {
                const indexes = Object.entries(selectedRowIds);
                let idList = "";
                indexes.map((id, index) => {
                  if (id[1]) {
                    idList += data[parseFloat(id[0])].userId + ",";
                  }
                  return true;
                });
                idList = idList.substr(0, idList.length - 1);
                setSelectedUserIds(idList);
                setOpenAssignModal(true);
              }}
              disabled={disable}
            >
              Assign To Recruiter
            </Button>
          )}
          <div className={`${styles.filterTabs}`}>
            {tabsData.map((singleTab, index) => {
              return (
                singleTab.value && (
                  <Button
                    key={index}
                    onClick={(event) => {
                      handleAssignFilter(event, singleTab.value);
                    }}
                    className={`statusButtons marginRight20 ${
                      tab.workerDataTab
                        ? assignFilter.workerTabFilter[singleTab.value] &&
                          singleTab.value
                          ? "blueColorBorder"
                          : "greyColorBorder"
                        : assignFilter.leadTabFilter[singleTab.value] &&
                          singleTab.value
                        ? "blueColorBorder"
                        : "greyColorBorder"
                    }`}
                  >
                    {singleTab.label}
                  </Button>
                )
              );
            })}
          </div>
          <div className={styles.criminalCheckBox}>
            <div></div>
            <span>Candidate with Criminal record</span>
          </div>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => {
            setLeadCityId(curLeadId.leadCity);
            setLeadSourceId(curLeadId.leadSource);
            setOpenFilterDrawer(false);
          }}
        >
          <FilterDrawer
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            tab={tab}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFiltersWithoutSearch}
            leadCityId={leadCityId}
            setLeadCityId={setLeadCityId}
            leadSourceId={leadSourceId}
            setLeadSourceId={setLeadSourceId}
            curLeadId={curLeadId}
            setCurLeadId={setCurLeadId}
          ></FilterDrawer>
        </Drawer>
        {openOnboardingModalV2 && (
          <OnboardingModalV2
            openOnboardingModal={openOnboardingModalV2}
            setOpenOnboardingModal={setOpenOnboardingModalV2}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openAssignModal && (
          <AssignToRecruiterModal
            open={openAssignModal}
            setOpen={setOpenAssignModal}
            showToast={showToast}
            userIds={selectedUserIds}
            updateMyData={updateMyData}
            tabData={tab.workerDataTab}
          />
        )}
        {openOnboardingApnaModalV3 && (
          <OnBoardingModalV3
            openOnboardingModal={openOnboardingApnaModalV3}
            setOpenOnboardingModal={setOpenOnboardingApnaModalV3}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openAJworkerUploadModal && (
          <UploadAJWorkerModal
            openOnboardingModal={openAJworkerUploadModal}
            setOpenOnboardingModal={setOpenAJworkerUploadModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        {openOffboardingModal && (
          <OnboardingModal
            openOnboardingModal={openOffboardingModal}
            setOpenOnboardingModal={setOpenOffboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
            title="Upload Offboarding Data"
            uniqueKey="uploadOffboardingData"
            templateButton="Download File Template"
            url={`${UrlLinks.baseUrl}${
              UrlLinks.workerUrls.offBoardWorkers
            }${localStorage.getItem("userID")}`}
            fileName="Invalid offboarding data"
          />
        )}
        {openMettlAssessorModal && (
          <OnboardingModal
            openOnboardingModal={openMettlAssessorModal}
            setOpenOnboardingModal={setOpenMettlAssessorModal}
            showToast={showToast}
            updateMyData={updateMyData}
            title="Upload Mettl Assessor Worker"
            uniqueKey="uploadMettlAssessor"
            templateButton="Download File Template"
            url={`${UrlLinks.baseUrl}${
              UrlLinks.workerUrls.uploadMettlAssessor
            }insertedBy=${localStorage.getItem("userID")}`}
            fileName="Invalid worker data"
          />
        )}
        {openCasperOnboardingModal && (
          <CasperOnboardingModal
            openCasperOnboardingModal={openCasperOnboardingModal}
            setOpenCasperOnboardingModal={setOpenCasperOnboardingModal}
            showToast={showToast}
            partners={partners}
            updateMyData={updateMyData}
            uniqueKey="UploadCasperID"
            title="Upload Client IDs"
            option1="Choose Client"
            option2="Upload Client IDs"
            downloadTitle="Download worker list"
            fileName="Worker List without Id.xlsx"
          />
        )}
        {openInsuranceTagModal && (
          <CasperOnboardingModal
            openCasperOnboardingModal={openInsuranceTagModal}
            setOpenCasperOnboardingModal={setOpenInsuranceTagModal}
            showToast={showToast}
            partners={partners}
            updateMyData={updateMyData}
            uniqueKey="UploadInsuranceTags"
            title="Upload Insurance Tags"
            option1="Choose Client"
            option2="Upload Insurance Tags"
            downloadTitle="Download worker list"
            fileName="Insurance Tagging Template.xlsx"
          />
        )}
        {criminalRecordData.open && (
          <CriminalRecord
            criminalRecordData={criminalRecordData}
            setCriminalRecordData={setCriminalRecordData}
          />
        )}
        {editContactData.open && (
          <EditContactModal
            open={editContactData}
            handleClose={handleEditContactClose}
            handleEditPhoneNumberService={handleEditPhoneNumberService}
          />
        )}
        {reactivationData.open && (
          <ReactivationModal
            open={reactivationData}
            handleClose={handleReactivationDateClose}
            handleReactivationDate={handleReactivationDate}
            setReactivationData={setReactivationData}
          />
        )}
        {exportModal && (
          <ExportModal
            openOffboardingModal={exportModal}
            setOpenOffboardingModal={setExportModal}
            filters={filters}
            setFilterUrl={setFilterUrl}
            filterUrl={filterUrl}
            createAllRecordExcel={createAllRecordExcel}
            updateFilterUrl={updateFilterUrl}
            setFilters={setFilters}
            tab={tab}
          />
        )}
        {openSendMsg && (
          <SendMsg
            isLeadData={isLeadData}
            filterUrl={filterUrl}
            open={openSendMsg}
            setOpen={setOpenSendMsg}
            userIds={selectedUserIds}
          />
        )}
        <HistoryModal
          uniqueKey="serviceAgreement"
          open={openServiceAgreementModal}
          setOpen={setOpenServiceAgreementModal}
          title={`Service Agreement`}
          historyList={serviceAgreementList}
          historyColumns={serviceAgreementColumns}
          applicationStatusList={[]}
          showToast
        />
        <HistoryModal
          uniqueKey="briberyAgreement"
          open={openBriberyAgreementModal}
          setOpen={setOpenBriberyAgreementModal}
          title={`Anti-Bribery Agreement`}
          historyList={briberyAgreementList}
          historyColumns={briberyAgreementColumns}
          applicationStatusList={[]}
          showToast
        />
      </Grid>
    </Styles>
  );
};

export default WorkerData;
