import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import Styles from "./Dialogs.module.scss";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Nunito",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#000000",
    padding: "0",
    fontFamily: "Nunito",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={`${classes.closeButton} ${Styles.closeButton}`}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: "0 16px",
    borderBottom: "unset",
    textAlign: "center",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: "center",
  },
}))(MuiDialogActions);

export default function DeleteConfirmationModal(props) {
  const {
    // uniqueId,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    title,
    content,
    approveButtonText,
    declineButtonText,
    handleApprove,
  } = props;

  const handleYes = () => {
    handleApprove();
    setOpenConfirmationDialog(false);
  };
  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openConfirmationDialog}
      classes={{ paper: Styles.deleteModalPaper }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <div className={Styles.boxTitle}>
          <Close />
          {title}
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          className="blueButtonOutlined"
          style={{
            textTransform: "capitalize",
            padding: "6px 38px",
            margin: "0 8px",
          }}
        >
          {declineButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={handleYes}
          className="redButtonContained"
          style={{
            textTransform: "capitalize",
            padding: "6px 38px",
            margin: "0 8px",
          }}
        >
          {approveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
