import React, { useState, useEffect } from "react";
import Axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextareaAutosize,
  Tooltip,
} from "@material-ui/core";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import swal from "sweetalert";
import AutoSelect from "./InputComponents/AutoSelect";
import "./index.css";
import axios from "axios";
import UrlLinks from "../../../UrlLinks";
import { Link, useHistory, useParams } from "react-router-dom";
import styles from "./index.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { AppData } from "../../../context/context";
import { useContext } from "react";
import { AuthContext } from "../../../services/AuthProvider";
import { AccessTimeOutlined, InsertLink } from "@material-ui/icons";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import TimeFilter from "../../../Utils/FilterComponents/TimeFilter";
import { ContentCopy } from "@mui/icons-material";
import { copyToClipboard } from "../../../Utils/CommonFunctions";

const useStyles = makeStyles({
  clientName: {
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#1C75BC",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #1C75BC",
      },
    },
  },
  attendanceOption: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    "& .MuiFormControlLabel-root": {
      marginLeft: "0",
      "& .MuiTypography-body1": {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontSize: "20px",
        lineHeight: "27px",
      },
    },
    "& .MuiCheckbox-colorSecondary.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
    },
  },
  shiftStart: {
    verticalAlign: "initial",
  },
  shiftInput: {
    height: "50px",
  },
});

const AddProject = (data) => {
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const { formMode, setFormMode, userAction } = useContext(AppData);
  const history = useHistory();
  const [laoding, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    clientName: "",
    projectName: "",
    vertical: "",
    projectOwner: "",
    description: "",
    projectCode: "",
    projectKey: "",
    task_addition: "",
    task_clone: "",
    task_deletion: "",
    agreementRequired: "",
    briberyAgreementRequired: true,
    agreementUrl: "",
    briberyAgreementUrl: "",
    attendanceRequired: "",
    attendanceRequired_currentLocation: "",
    attendanceRequired_selfie: "",
    attendanceUrl: `${UrlLinks.webBaseUrl}mark-attendance`,
    geoFencingRequired: "",
    shiftStartTime: "",
    shiftEndTime: "",
    isCaptureMultipleAttendance: "",
    slots: "",
  });
  const [isUrl, setIsUrl] = useState(false);
  const [isServiceUrl, setIsServiceUrl] = useState(false);
  const [validationErr, setValidationErr] = useState({
    projectDescription: false,
    projectCode: false,
    projectKey: false,
    projectName: false,
    shiftTime: false,
  });
  const [initialProjectDetails, setInitialProjectDetails] = useState({
    projectKey: "",
    projectCode: "",
  });
  const [validationMessage, setValidationMessage] = useState({
    descriptionError: "",
    projectCodeError: "",
    projectKeyError: "",
  });
  const [fieldEmptyCheck, setFieldEmptyCheck] = useState(false);
  const [optionList, setOptionList] = useState({
    clientList: [],
    verticalList: [],
    projectNameList: [],
    projectOwnerList: [],
    slotList: [
      {
        id: 1,
        name: "01 Time",
      },
      {
        id: 2,
        name: "02 Times",
      },
      {
        id: 3,
        name: "03 Times",
      },
      {
        id: 4,
        name: "04 Times",
      },
      {
        id: 5,
        name: "05 Times",
      },
    ],
  });
  const [selectedTime, setSelectedTime] = useState({
    startTime: null,
    endTime: null,
  });
  const { projectId } = useParams();

  const makeProjectOwnerData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.userId,
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
      });
      return true;
    });
    return list;
  };

  const makeVerticalData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.typeKey,
        name: el.typeValue,
      });
      return true;
    });
    return list;
  };

  const makeClientData = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.partner_id,
        name: el.partner_name,
      });
      return true;
    });
    return list;
  };

  const makeProjectNameList = (data) => {
    let list = [];
    data.map((el) => {
      list.push({
        id: el.projects_key,
        code: el.project_code,
        name: el.project_name,
      });
      return true;
    });
    return list;
  };

  const getProjectsById = (id) => {
    setFormMode("view");
    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.projectById}?projectId=${id}`
    ).then((response) => {
      let data = response.data.response;
      let attendUrl = data.attendanceLink?.split("mark-attendance/");
      attendUrl =
        attendUrl &&
        attendUrl[attendUrl.length - 1].replace(/[+/]/g, (match) => {
          return match === "+" ? "%2B" : "%2F";
        });

      let newFormData = {
        clientName: data.client_name
          ? { id: data.partner_id, name: data.client_name }
          : "",
        projectName: data.project_name ? data.project_name : "",
        vertical: data.category
          ? { id: data.categoryTypeKey, name: data.category }
          : "",
        projectOwner: data.project_owner
          ? { id: data.projectOwnerUserId, name: data.project_owner }
          : "",
        description: data.description ? data.description : "",
        projectCode: data.project_code ? data.project_code : "",
        projectKey: data.projects_key ? data.projects_key : "",
        is_has_job: data.is_has_job,
        task_addition: data.task_addition ? data.task_addition : 0,
        task_clone: data.task_clone ? data.task_clone : 0,
        task_deletion: data.task_deletion ? data.task_deletion : 0,
        agreementRequired: data.agreement_required
          ? data.agreement_required
          : 0,
        briberyAgreementRequired: data.antiBriberyRequired
          ? data.antiBriberyRequired
          : 0,
        agreementUrl: data.agreement_required ? data.agreementUrl : "",
        briberyAgreementUrl: data.antiBriberyRequired
          ? data.antiBriberyLink
          : "",
        attendanceRequired: data.attendance ? data.attendance : 0,
        attendanceRequired_currentLocation: data.location ? data.location : 0,
        attendanceRequired_selfie: data.selfie ? data.selfie : 0,
        attendanceUrl: data.attendanceLink
          ? `${UrlLinks.webBaseUrl}mark-attendance/${attendUrl}`
          : "",
        geoFencingRequired: data.geoFencing ? data.geoFencing : 0,
        shiftStartTime:
          data.attendance && data.shiftStartTime ? data.shiftStartTime : "",
        shiftEndTime:
          data.attendance && data.shiftEndTime ? data.shiftEndTime : "",
        isCaptureMultipleAttendance: data.slots ? 1 : 0,
        slots: data.slots
          ? {
              id: data.slots,
              name: `0${data.slots} ${data.slots === 1 ? "time" : "times"}`,
            }
          : "",
      };

      setFormData(newFormData);

      const currentDate = moment().format("ddd MMM DD YYYY");
      const combinedStartDateTime = `${currentDate} ${data.shiftStartTime}`;
      const combinedEndDateTime = `${currentDate} ${data.shiftEndTime}`;
      const parsedStartDateTime = moment(
        combinedStartDateTime,
        "ddd MMM DD YYYY HH:mm:ss"
      );
      const parsedEndDateTime = moment(
        combinedEndDateTime,
        "ddd MMM DD YYYY HH:mm:ss"
      );
      const formattedStartDateTime = parsedStartDateTime.format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );
      const formattedEndDateTime = parsedEndDateTime.format(
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
      );

      setSelectedTime({
        startTime:
          data.attendance && data.shiftStartTime
            ? formattedStartDateTime
            : null,
        endTime:
          data.attendance && data.shiftEndTime ? formattedEndDateTime : null,
      });
    });
  };

  const handleChange = (event, type) => {
    const timeString1 = "00:00:00";
    const timeString2 = "23:59:59";
    const currentDate = moment().format("ddd MMM DD YYYY");
    const combinedStartDateTime = `${currentDate} ${timeString1}`;
    const combinedEndDateTime = `${currentDate} ${timeString2}`;
    const parsedStartDateTime = moment(
      combinedStartDateTime,
      "ddd MMM DD YYYY HH:mm:ss"
    );
    const parsedEndDateTime = moment(
      combinedEndDateTime,
      "ddd MMM DD YYYY HH:mm:ss"
    );
    const formattedStartDateTime = parsedStartDateTime.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );
    const formattedEndDateTime = parsedEndDateTime.format(
      "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
    );

    if (type === "task_addition") {
      setFormData((prev) => ({
        ...prev,
        task_addition: event.target.checked ? 1 : 0,
        task_clone: 0,
        task_deletion: 0,
      }));
    }
    if (type === "task_clone") {
      setFormData((prev) => ({
        ...prev,
        task_clone: event.target.checked ? 1 : 0,
        task_deletion: event.target.checked ? 1 : 0,
      }));
    }
    if (type === "agreementRequired") {
      if (
        !event.target.checked &&
        !isServiceUrl &&
        formData.agreementUrl.length > 0
      ) {
        console.log("false----------------");
        setFormData((prev) => ({
          ...prev,
          agreementUrl: "",
        }));
      }
      setFormData((prev) => ({
        ...prev,
        agreementRequired: event.target.checked ? 1 : 0,
      }));
    }
    if (type === "briberyAgreementRequired") {
      setFormData((prev) => ({
        ...prev,
        briberyAgreementRequired: event.target.checked ? 1 : 0,
      }));
    }
    if (type === "attendanceRequired") {
      setFormData((prev) => ({
        ...prev,
        attendanceRequired: event.target.checked ? 1 : 0,
        attendanceRequired_currentLocation: event.target.checked ? 1 : 0,
        attendanceRequired_selfie: event.target.checked ? 1 : 0,
        geoFencingRequired: event.target.checked
          ? formData.geoFencingRequired
          : 0,
        isCaptureMultipleAttendance: event.target.checked
          ? formData.isCaptureMultipleAttendance
          : 0,
        shiftStartTime: event.target.checked
          ? formData.shiftStartTime
            ? formData.shiftStartTime
            : "00:00:00"
          : "",
        shiftEndTime: event.target.checked
          ? formData.shiftEndTime
            ? formData.shiftEndTime
            : "23:59:59"
          : "",
      }));
      setSelectedTime({
        startTime: event.target.checked
          ? selectedTime.startTime
            ? selectedTime.startTime
            : formattedStartDateTime
          : null,
        endTime: event.target.checked
          ? selectedTime.endTime
            ? selectedTime.endTime
            : formattedEndDateTime
          : null,
      });
    }
    if (type === "geoFencingRequired") {
      setFormData((prev) => ({
        ...prev,
        attendanceRequired_currentLocation: event.target.checked
          ? 1
          : formData.attendanceRequired_currentLocation,
        geoFencingRequired: event.target.checked ? 1 : 0,
      }));
    }
    if (type === "isCaptureMultipleAttendance") {
      setFormData((prev) => ({
        ...prev,
        isCaptureMultipleAttendance: event.target.checked ? 1 : 0,
      }));
    }
    if (type === "attendanceRequired_currentLocation") {
      let attendanceFlag =
        event.target.checked || formData.attendanceRequired_selfie;

      setFormData((prev) => ({
        ...prev,
        attendanceRequired_currentLocation: event.target.checked ? 1 : 0,
        attendanceRequired: attendanceFlag ? 1 : 0,
        geoFencingRequired: event.target.checked
          ? formData.geoFencingRequired
          : 0,
        shiftStartTime: attendanceFlag
          ? formData.shiftStartTime
            ? formData.shiftStartTime
            : "00:00:00"
          : "",
        shiftEndTime: attendanceFlag
          ? formData.shiftEndTime
            ? formData.shiftEndTime
            : "23:59:59"
          : "",
      }));
      setSelectedTime({
        startTime: attendanceFlag
          ? selectedTime.startTime
            ? selectedTime.startTime
            : formattedStartDateTime
          : null,
        endTime: attendanceFlag
          ? selectedTime.endTime
            ? selectedTime.endTime
            : formattedEndDateTime
          : null,
      });
    }
    if (type === "attendanceRequired_selfie") {
      let attendanceFlag =
        event.target.checked || formData.attendanceRequired_currentLocation;

      setFormData((prev) => ({
        ...prev,
        attendanceRequired_selfie: event.target.checked ? 1 : 0,
        attendanceRequired: attendanceFlag ? 1 : 0,
        shiftStartTime: attendanceFlag
          ? formData.shiftStartTime
            ? formData.shiftStartTime
            : "00:00:00"
          : "",
        shiftEndTime: attendanceFlag
          ? formData.shiftEndTime
            ? formData.shiftEndTime
            : "23:59:59"
          : "",
      }));
      setSelectedTime({
        startTime: attendanceFlag
          ? selectedTime.startTime
            ? selectedTime.startTime
            : formattedStartDateTime
          : null,
        endTime: attendanceFlag
          ? selectedTime.endTime
            ? selectedTime.endTime
            : formattedEndDateTime
          : null,
      });
    }
    if (type === "shiftStartTime") {
      setSelectedTime((prev) => ({
        ...prev,
        startTime: event,
      }));
      setFormData((prev) => ({
        ...prev,
        shiftStartTime: formData.attendanceRequired
          ? moment(event, "ddd MMM DD YYYY HH:mm:ss ZZ").format("HH:mm:ss")
          : "",
      }));
    }
    if (type === "shiftEndTime") {
      setSelectedTime((prev) => ({
        ...prev,
        endTime: event,
      }));
      setFormData((prev) => ({
        ...prev,
        shiftEndTime: formData.attendanceRequired
          ? moment(event, "ddd MMM DD YYYY HH:mm:ss ZZ").format("HH:mm:ss")
          : "",
      }));
    }
  };

  const handleInputChange = (e, type) => {
    e.preventDefault();

    if (type === 1) {
      setFormData((prev) => ({
        ...prev,
        projectName: e.target.value.replace(/[^a-zA-Z ]/gi, ""),
      }));
    } else if (type === 2) {
      const pattern = /^[a-zA-Z0-9]{1,7}$/;
      const value = e.target.value;
      let list = [];

      optionList.projectNameList.map((item) => {
        list.push(item.id);
      });

      if (!pattern.test(e.target.value)) {
        setValidationErr({ projectKey: true });
        setValidationMessage((prev) => ({
          ...prev,
          projectKeyError: "Invalid project key. Please check the info",
        }));
      } else if (list.includes(value)) {
        if (
          formMode === "edit" &&
          initialProjectDetails.projectKey === e.target.value
        ) {
          setValidationErr({ projectKey: false });
        } else {
          setValidationErr({ projectKey: true });
        }
        setValidationMessage((prev) => ({
          ...prev,
          projectKeyError: "Key already exists",
        }));
      } else {
        setValidationErr({ projectKey: false });
        setValidationMessage((prev) => ({ ...prev, projectKeyError: "" }));
      }
      setFormData((prev) => ({ ...prev, projectKey: e.target.value }));
    } else if (type === 3) {
      if (e.target.value.length >= 300) {
        setValidationErr({ projectDescription: true });
        setValidationMessage((prev) => ({
          ...prev,
          descriptionError: "Word limit exhausted, please check the info",
        }));
      } else {
        setValidationErr({ projectDescription: false });
        setValidationMessage((prev) => ({ ...prev, descriptionError: "" }));
      }
      setFormData((prev) => ({ ...prev, description: e.target.value }));
    } else if (type === 4) {
      const urlRegex =
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;
      if (!urlRegex.test(e.target.value) && e.target.value.length > 0) {
        setIsServiceUrl(false);
      } else {
        setIsServiceUrl(true);
      }
      setFormData((prev) => ({ ...prev, agreementUrl: e.target.value }));
    } else if (type === 44) {
      const urlRegex =
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/;
      if (!urlRegex.test(e.target.value) && e.target.value.length > 0) {
        setIsUrl(false);
      } else {
        setIsUrl(true);
      }
      setFormData((prev) => ({ ...prev, briberyAgreementUrl: e.target.value }));
    } else if (type === 5) {
      setFormData((prev) => ({ ...prev, attendanceUrl: e.target.value }));
    } else if (type === 6) {
      const pattern = /^[a-zA-Z0-9]+$/;
      const value = e.target.value;
      let list = [];

      optionList.projectNameList.map((item) => {
        list.push(item.code);
      });

      if (!pattern.test(e.target.value)) {
        setValidationErr({ projectCode: true });
        setValidationMessage((prev) => ({
          ...prev,
          projectCodeError: "Invalid project code. Please check the info",
        }));
      } else if (list.includes(value)) {
        if (
          formMode === "edit" &&
          initialProjectDetails.projectCode === e.target.value
        ) {
          setValidationErr({ projectCode: false });
        } else {
          setValidationErr({ projectCode: true });
        }
        setValidationMessage((prev) => ({
          ...prev,
          projectCodeError: "Code already exists",
        }));
      } else {
        setValidationErr({ projectCode: false });
        setValidationMessage((prev) => ({ ...prev, projectCodeError: "" }));
      }
      setFormData((prev) => ({ ...prev, projectCode: e.target.value }));
    }
  };

  const validateFormData = (formData) => {
    // Loop through the keys in the formData object
    for (const key in formData) {
      if (key === "description") continue;
      if (key === "is_has_job") continue;
      if (key === "task_addition") continue;
      if (key === "task_clone") continue;
      if (key === "task_deletion") continue;
      if (key === "isCaptureMultipleAttendance") continue;
      if (key === "geoFencingRequired") continue;
      if (
        (key === "agreementRequired" && !formData[key]) ||
        (key === "attendanceRequired" && !formData[key]) ||
        (key === "attendanceRequired_currentLocation" && !formData[key]) ||
        (key === "attendanceRequired_selfie" && !formData[key]) ||
        (key === "attendanceUrl" && !formData[key]) ||
        (key === "shiftStartTime" && !formData[key]) ||
        (key === "shiftEndTime" && !formData[key])
      )
        continue;
      if (!formData[key]) {
        if (key === "agreementUrl" && !formData["agreementRequired"]) continue;
        if (key === "slots" && !formData["isCaptureMultipleAttendance"])
          continue;
        return false;
      }
    }
    return true;
  };

  const handleSaveBtn = () => {
    if (formMode === "create") {
      Axios.post(
        `${UrlLinks.baseUrl}${UrlLinks.projectListing.addNewProject}?category=${
          formData.vertical.id
        }&tds=0&description=${
          formData.description
        }&inserted_by=${localStorage.getItem("userID")}&partnerId=${
          formData.clientName.id
        }&payout_label=""&projectKey=${formData.projectKey}&projectCode=${
          formData.projectCode
        }&projectName=${formData.projectName}&projectOwner=${
          formData.projectOwner.id
        }&task_addition=${formData.task_addition ? 1 : 0}&taskClone=${
          formData.task_clone ? 1 : 0
        }&taskDeletion=${formData.task_deletion ? 1 : 0}&agreementRequired=${
          formData.agreementRequired ? 1 : 0
        }${
          formData.agreementRequired
            ? `&agreementUrl=${formData.agreementUrl}`
            : ""
        }&antiBriberyRequired=${formData.briberyAgreementRequired ? 1 : 0}${
          formData.briberyAgreementRequired
            ? `&antiBriberyLink=${formData.briberyAgreementUrl}`
            : ""
        }&attendance=${formData.attendanceRequired ? 1 : 0}${
          formData.attendanceRequired
            ? `&attendanceLink=${formData.attendanceUrl}`
            : ""
        }&date_time=1&location=${
          formData.attendanceRequired_currentLocation ? 1 : 0
        }&geo_fencing=${formData.geoFencingRequired ? 1 : 0}&selfie=${
          formData.attendanceRequired_selfie ? 1 : 0
        }${
          formData.attendanceRequired
            ? `&shift_start_time=${
                formData.shiftStartTime ? formData.shiftStartTime : "00:00:00"
              }&shift_end_time=${
                formData.shiftEndTime ? formData.shiftEndTime : "23:59:59"
              }`
            : ""
        }${
          formData.isCaptureMultipleAttendance &&
          formData.slots?.id > 0 &&
          `&slots=${formData.slots.id}`
        }`,
        {}
      ).then((response) => {
        showToast("success", "New project added successfully");
        history.push(
          `/projectConfig/project/${response.data.response.projects_id}`
        );
        getProjectsById(response.data.response.projects_id);
      });
    }
    if (formMode === "edit") {
      Axios.post(
        `${UrlLinks.baseUrl}${
          UrlLinks.projectListing.editProject
        }?tds=0&description=${
          formData.description
        }&updated_by=${localStorage.getItem("userID")}&projectOwner=${
          formData.projectOwner.id
        }&projectId=${projectId}${
          formData.is_has_job === 0
            ? `&category=${formData.vertical.id}&partnerId=${formData.clientName.id}&projectKey=${formData.projectKey}&projectName=${formData.projectName}`
            : ""
        }${
          !initialProjectDetails.projectCode
            ? `&projectCode=${formData.projectCode}`
            : ""
        }&task_addition=${formData.task_addition ? 1 : 0}&taskClone=${
          formData.task_clone ? 1 : 0
        }&taskDeletion=${formData.task_deletion ? 1 : 0}&agreementRequired=${
          formData.agreementRequired ? 1 : 0
        }${
          formData.agreementRequired
            ? `&agreementUrl=${formData.agreementUrl}`
            : ""
        }&antiBriberyRequired=${formData.briberyAgreementRequired ? 1 : 0}${
          formData.briberyAgreementRequired
            ? `&antiBriberyLink=${formData.briberyAgreementUrl}`
            : ""
        }&attendance=${formData.attendanceRequired ? 1 : 0}${
          formData.attendanceRequired
            ? `&attendanceLink=${UrlLinks.webBaseUrl}mark-attendance`
            : ""
        }&date_time=1&location=${
          formData.attendanceRequired_currentLocation ? 1 : 0
        }&geo_fencing=${formData.geoFencingRequired ? 1 : 0}&selfie=${
          formData.attendanceRequired_selfie ? 1 : 0
        }${
          formData.attendanceRequired
            ? `&shift_start_time=${
                formData.shiftStartTime ? formData.shiftStartTime : "00:00:00"
              }&shift_end_time=${
                formData.shiftEndTime ? formData.shiftEndTime : "23:59:59"
              }`
            : ""
        }&slots=${
          formData.isCaptureMultipleAttendance ? formData.slots.id : 0
        }`,
        {}
      ).then((response) => {
        showToast("success", "The project is successfully updated");
        history.push(
          `/projectConfig/project/${response.data.response.projects_id}`
        );
        getProjectsById(response.data.response.projects_id);
      });
    }
  };

  useEffect(() => {
    setFieldEmptyCheck(!validateFormData(formData));
  }, [formData]);

  useEffect(() => {
    if (
      formMode !== "view" &&
      moment(selectedTime.endTime).isBefore(moment(selectedTime.startTime))
    ) {
      setValidationErr({ shiftTime: true });
      showToast("error", "Shift start time should be less than shift end time");
    } else {
      setValidationErr({ shiftTime: false });
    }
  }, [selectedTime]);

  useEffect(() => {
    setInitialProjectDetails({
      projectKey: formData.projectKey,
      projectCode: formData.projectCode,
    });
  }, [formMode]);

  useEffect(() => {
    let currentFormMode = formMode;

    if (projectId !== "add") {
      currentFormMode = "view";
    }

    Axios.get(
      `${UrlLinks.baseUrl}${UrlLinks.projectListing.projectOwner}`
    ).then((response) => {
      setOptionList((prev) => ({
        ...prev,
        projectOwnerList: makeProjectOwnerData(response.data.response),
      }));
    });

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.projectListing.vertical}`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          verticalList: makeVerticalData(response.data.response),
        }));
      }
    );

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.projectListing.client}`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          clientList: makeClientData(response.data.response),
        }));
      }
    );

    Axios.get(`${UrlLinks.baseUrl}${UrlLinks.projectListing.mainListing}`).then(
      (response) => {
        setOptionList((prev) => ({
          ...prev,
          projectNameList: makeProjectNameList(response.data.response),
        }));
      }
    );

    if (currentFormMode === "view") {
      getProjectsById(projectId);
    }

    setFormMode(currentFormMode);
  }, []);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const BlueSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#1C75BC",
      "&:hover": {
        backgroundColor: alpha("#1C75BC", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1C75BC",
    },
    "& .MuiSwitch-switchBase": {
      cursor: formMode === "view" ? "auto" : "pointer",
    },
  }));

  return (
    <>
      <div>
        <div>
          <div className={styles.mainBox}>
            <div className={styles.InputContainer}>
              <div className={styles.BackNavigator}>
                <div>
                  <Link to="/projectConfig" style={{ textDecoration: "none" }}>
                    <ArrowBackIcon
                      sx={{
                        color: "black",
                        fontSize: "40",
                        marginBottom: "-5px",
                      }}
                      onClick={() => {
                        setFormMode("create");
                      }}
                    ></ArrowBackIcon>
                  </Link>
                  <span className={styles.formHeading}>
                    {" "}
                    {data.match.params.projectId === "add"
                      ? "Add New Project"
                      : formData.projectName}
                  </span>
                </div>
                {formMode === "view" ? (
                  <div
                    className={styles.rightOptions}
                    style={{
                      width: !userAction.edit ? "30%" : "40%",
                    }}
                  >
                    {!!userAction.edit && (
                      <button
                        className={styles.editBtn}
                        onClick={() => {
                          if (formData.agreementUrl) setIsServiceUrl(true);
                          if (formData.briberyAgreementUrl) setIsUrl(true);
                          setFormMode("edit");
                        }}
                      >
                        <EditIcon />
                        <span>Edit</span>
                      </button>
                    )}
                    <div>
                      <span>Allow clone/delete</span>
                      <BlueSwitch
                        {...label}
                        checked={formData.task_clone ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                    <div>
                      <span>
                        {formData.task_addition
                          ? "Task add by worker"
                          : "Enable task addition "}
                      </span>
                      <BlueSwitch
                        {...label}
                        checked={formData.task_addition ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.rightOptions} style={{ width: "30%" }}>
                    <div
                      style={{
                        color: formData.task_addition ? "#2E2E2E" : "#B8B8B8",
                      }}
                    >
                      <span>Allow clone/delete</span>
                      <Tooltip
                        title={
                          <div
                            style={{
                              maxWidth: "192px",
                              fontSize: "14px",
                              padding: "3px 0",
                            }}
                          >
                            Worker will be able to clone/delete task in this
                            project
                          </div>
                        }
                      >
                        <BlueSwitch
                          {...label}
                          checked={
                            formData.task_addition && formData.task_clone
                              ? true
                              : false
                          }
                          onChange={(e) => handleChange(e, "task_clone")}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={formData.task_addition ? false : true}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <span>
                        {formData.task_addition
                          ? "Task add by worker"
                          : "Enable task addition "}
                      </span>
                      <Tooltip
                        title={
                          <div
                            style={{
                              maxWidth: "192px",
                              fontSize: "14px",
                              padding: "3px 0",
                            }}
                          >
                            Worker will be able to add task in this project
                          </div>
                        }
                      >
                        <BlueSwitch
                          {...label}
                          checked={formData.task_addition ? true : false}
                          onChange={(e) => handleChange(e, "task_addition")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
              <br></br>
              <div className={styles.InputBox}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Project name
                </span>
                {formMode === "view" ? (
                  <p>{formData.projectName}</p>
                ) : (
                  <FormControl variant="outlined">
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      label="Type project name"
                      value={formData.projectName}
                      onChange={(e) => {
                        handleInputChange(e, 1);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                  </FormControl>
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Client name
                </span>
                {formMode === "view" ? (
                  <p>{formData.clientName.name}</p>
                ) : (
                  <AutoSelect
                    data={formData}
                    setData={setFormData}
                    id="clientName"
                    multipleFlag={false}
                    label="Select client name"
                    list={optionList.clientList}
                    disabled={
                      formData.is_has_job === 1 ? formMode === "edit" : false
                    }
                  />
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Vertical name
                </span>
                {formMode === "view" ? (
                  <p>{formData.vertical.name}</p>
                ) : (
                  <AutoSelect
                    data={formData}
                    setData={setFormData}
                    id="vertical"
                    multipleFlag={false}
                    label="Select vertical name"
                    list={optionList.verticalList}
                    disabled={
                      formData.is_has_job === 1 ? formMode === "edit" : false
                    }
                  />
                )}
              </div>
              <div className={`${styles.InputBox} ${classes.clientName}`}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view"
                      ? styles.lightText
                      : styles.requiredField
                  }`}
                >
                  Project owner
                </span>
                {formMode === "view" ? (
                  <p>{formData.projectOwner.name}</p>
                ) : (
                  <AutoSelect
                    data={formData}
                    setData={setFormData}
                    id="projectOwner"
                    multipleFlag={false}
                    label="Select project owner"
                    list={optionList.projectOwnerList}
                  />
                )}
              </div>
            </div>
            <div className={styles.InputContainer2}>
              <div className={`${styles.InputBox}`}>
                <span
                  className={`${styles.formFont} ${
                    formMode === "view" && styles.lightText
                  }`}
                >
                  Brief Description of the project{" "}
                  <span style={{ color: "#757575", fontSize: "16px" }}>
                    (optional)
                  </span>
                  {formMode !== "view" && (
                    <Tooltip
                      title={
                        <div
                          style={{
                            maxWidth: "192px",
                            fontSize: "14px",
                            padding: "3px 0",
                          }}
                        >
                          (upto 300 characters allowed)
                        </div>
                      }
                    >
                      <InfoIcon
                        style={{ height: "20px", marginBottom: "-3px" }}
                      />
                    </Tooltip>
                  )}
                </span>
                {formMode === "view" ? (
                  <p>{formData.description}</p>
                ) : (
                  <TextareaAutosize
                    value={formData.description}
                    onChange={(e) => {
                      handleInputChange(e, 3);
                    }}
                    minRows={2}
                    placeholder="Type here..."
                    maxLength={300}
                    className={styles.textAreaDesc}
                  />
                )}
                {validationErr.projectDescription && (
                  <FormHelperText
                    style={{ color: "red", fontSize: "12px" }}
                    id="component-error-text"
                  >
                    {validationMessage.descriptionError}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div
              style={{ marginTop: "54px" }}
              className={styles.InputContainer}
            >
              <div className={styles.InputBox}>
                <span>
                  {" "}
                  <span
                    className={`${styles.formFont} ${
                      formMode === "view"
                        ? styles.lightText
                        : styles.requiredField
                    }`}
                  >
                    Project key
                  </span>{" "}
                  {formMode !== "view" && (
                    <Tooltip
                      title={
                        <div
                          style={{
                            maxWidth: "192px",
                            fontSize: "14px",
                            padding: "3px 0",
                          }}
                        >
                          (upto 7 characters allowed with no special character)
                        </div>
                      }
                    >
                      <InfoIcon
                        style={{ height: "20px", marginBottom: "-3px" }}
                      />
                    </Tooltip>
                  )}
                </span>
                <FormControl variant="outlined">
                  {formMode === "view" ? (
                    <p>{formData.projectKey}</p>
                  ) : (
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      label="Type project key"
                      value={formData.projectKey}
                      onChange={(e) => {
                        handleInputChange(e, 2);
                      }}
                      disabled={
                        formData.is_has_job === 1 ? formMode === "edit" : false
                      }
                    />
                  )}
                </FormControl>
                {validationErr.projectKey && (
                  <FormHelperText
                    style={{ color: "red", fontSize: "12px" }}
                    id="component-error-text"
                  >
                    {validationMessage.projectKeyError}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div
              style={{ marginTop: "54px" }}
              className={styles.InputContainer}
            >
              <div className={styles.InputBox}>
                <span>
                  {" "}
                  <span
                    className={`${styles.formFont} ${
                      formMode === "view"
                        ? styles.lightText
                        : styles.requiredField
                    }`}
                  >
                    Project code
                  </span>{" "}
                  {formMode !== "view" && (
                    <Tooltip
                      title={
                        <div
                          style={{
                            maxWidth: "192px",
                            fontSize: "14px",
                            padding: "3px 0",
                          }}
                        >
                          (no special character is allowed)
                        </div>
                      }
                    >
                      <InfoIcon
                        style={{ height: "20px", marginBottom: "-3px" }}
                      />
                    </Tooltip>
                  )}
                </span>
                <FormControl variant="outlined">
                  {formMode === "view" ? (
                    <p>{formData.projectCode}</p>
                  ) : (
                    <TextField
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "weight",
                      }}
                      className={styles.textComponent}
                      label="Type project code"
                      value={formData.projectCode}
                      onChange={(e) => {
                        handleInputChange(e, 6);
                      }}
                      disabled={
                        formMode === "edit" && initialProjectDetails.projectCode
                          ? true
                          : false
                      }
                    />
                  )}
                </FormControl>
                {validationErr.projectCode && (
                  <FormHelperText
                    style={{ color: "red", fontSize: "12px" }}
                    id="component-error-text"
                  >
                    {validationMessage.projectCodeError}
                  </FormHelperText>
                )}
              </div>
            </div>

            {/* ----------------------------------------------------------------------------------------- */}

            <div style={{ margin: "4%" }}>
              <Divider />
            </div>

            {formMode === "view" ? (
              <></>
            ) : (
              <div
                style={{ marginTop: "54px" }}
                className={styles.InputContainer}
              >
                <div>
                  <span className={`${styles.formFont}`}>
                    Add Service Agreement
                  </span>
                  <BlueSwitch
                    {...label}
                    checked={formData.agreementRequired ? true : false}
                    onChange={(e) => handleChange(e, "agreementRequired")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>

                <div>
                  <span className={`${styles.formFont}`}>
                    Add Anti-Bribery Agreement
                  </span>
                  {/* <BlueSwitch
                    {...label}
                    checked={formData.briberyAgreementRequired ? true : false}
                    onChange={(e) =>
                      handleChange(e, "briberyAgreementRequired")
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  /> */}
                </div>
              </div>
            )}
            <div
              style={{ marginTop: "54px" }}
              className={styles.InputContainer3}
            >
              <div className={styles.InputBox3}>
                <div className={styles.InputSpan}>
                  <span>
                    {" "}
                    <span
                      className={`${styles.formFont} ${
                        formMode === "view" || !formData.agreementRequired
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Service agreement link
                    </span>{" "}
                  </span>
                  <span>
                    {" "}
                    <span
                      className={`${styles.formFont} ${
                        formMode === "view" ||
                        !formData.briberyAgreementRequired
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Anti-Bribery agreement link
                    </span>{" "}
                  </span>
                </div>
                <FormControl variant="outlined">
                  {formMode === "view" ? (
                    <div className={styles.GridFormation}>
                      <a
                        href={formData.agreementUrl}
                        target="_blank"
                        className={styles.downloadTemplate}
                      >
                        {formData.agreementUrl?.length > 35
                          ? formData.agreementUrl?.substr(0, 32) +
                            "..." +
                            formData.agreementUrl?.substr(
                              formData.agreementUrl.length - 3
                            )
                          : formData.agreementUrl}
                      </a>
                      <a
                        href={formData.briberyAgreementUrl}
                        target="_blank"
                        className={styles.downloadTemplate}
                      >
                        {formData.briberyAgreementUrl?.length > 35
                          ? formData.briberyAgreementUrl?.substr(0, 32) +
                            "..." +
                            formData.briberyAgreementUrl?.substr(
                              formData.briberyAgreementUrl?.length - 3
                            )
                          : formData.briberyAgreementUrl}
                      </a>
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "5rem" }}>
                      <TextField
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        InputProps={{
                          endAdornment: (
                            <>
                              <InsertLink
                                style={{
                                  rotate: "135deg",
                                  color: formData.agreementRequired
                                    ? !isServiceUrl &&
                                      formData.agreementUrl.length > 0
                                      ? "FF0000"
                                      : "#1C75BC"
                                    : "",
                                }}
                              />
                            </>
                          ),
                        }}
                        className={styles.textComponent}
                        // style={{backgroundColor : formData.agreementRequired ? "" : "#F5F5F5"}}
                        sx={{
                          backgroundColor: formData.agreementRequired
                            ? ""
                            : "#F5F5F5",
                          borderRadius: "4px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: formData.agreementRequired
                                ? !isServiceUrl &&
                                  formData.agreementUrl.length > 0
                                  ? "1px solid #FF0000"
                                  : "1px solid rgba(0, 0, 0, 0.23)"
                                : "none",
                            },
                            "&:hover fieldset": {
                              borderColor:
                                !isServiceUrl &&
                                formData.agreementUrl.length > 0
                                  ? "#FF0000"
                                  : "rgba(0, 0, 0, 0.23)",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor:
                                !isServiceUrl &&
                                formData.agreementUrl.length > 0
                                  ? "#FF0000"
                                  : "#1C75BC",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              !isServiceUrl && formData.agreementUrl.length > 0
                                ? "#FF0000"
                                : "rgba(0, 0, 0, 0.6)",
                            transition: "color 0.2s ease",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color:
                              !isServiceUrl && formData.agreementUrl.length > 0
                                ? "#FF0000"
                                : "#1C75BC",
                          },
                        }}
                        label={
                          !isServiceUrl && formData.agreementUrl.length > 0
                            ? "Paste your agreement link `eg: https://docs.google.com/...`"
                            : "Paste your agreement link"
                        }
                        value={formData.agreementUrl}
                        onChange={(e) => {
                          handleInputChange(e, 4);
                        }}
                        disabled={formData.agreementRequired ? false : true}
                        fullWidth
                      />
                      <TextField
                        id="outlined-adornment-weight"
                        aria-describedby="outlined-weight-helper-text"
                        InputProps={{
                          endAdornment: (
                            <>
                              <InsertLink
                                style={{
                                  rotate: "135deg",
                                  color:
                                    formData.briberyAgreementUrl?.length > 0
                                      ? !isUrl
                                        ? "#FF0000"
                                        : "#1C75BC"
                                      : "#1C75BC",
                                }}
                              />
                            </>
                          ),
                        }}
                        className={styles.textComponent}
                        sx={{
                          borderRadius: "4px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border:
                                !isUrl &&
                                formData.briberyAgreementUrl?.length > 0
                                  ? "1px solid #FF0000"
                                  : "1px solid rgba(0, 0, 0, 0.23)",
                            },
                            "&:hover fieldset": {
                              borderColor:
                                !isUrl &&
                                formData.briberyAgreementUrl?.length > 0
                                  ? "#FF0000"
                                  : "rgba(0, 0, 0, 0.23)",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor:
                                !isUrl &&
                                formData.briberyAgreementUrl?.length > 0
                                  ? "#FF0000"
                                  : "#1C75BC",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color:
                              !isUrl && formData.briberyAgreementUrl?.length > 0
                                ? "#FF0000"
                                : "rgba(0, 0, 0, 0.6)",
                            transition: "color 0.2s ease",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color:
                              !isUrl && formData.briberyAgreementUrl?.length > 0
                                ? "#FF0000"
                                : "#1C75BC",
                          },
                        }}
                        label={
                          !isUrl && formData.briberyAgreementUrl?.length > 0
                            ? "Paste your agreement link `eg: https://docs.google.com/...`"
                            : "Paste your agreement link"
                        }
                        value={formData.briberyAgreementUrl}
                        onChange={(e) => {
                          handleInputChange(e, 44);
                        }}
                        disabled={
                          formData.briberyAgreementRequired ? false : true
                        }
                        fullWidth
                      />
                    </div>
                  )}
                </FormControl>
              </div>
            </div>
            {/* </div> */}

            <div style={{ margin: "4%" }}>
              <Divider />
            </div>

            {/* --------------------------------------------------------------------------------------------------------------------------- */}

            {formMode === "view" ? (
              <div
                style={{ marginTop: "54px" }}
                className={styles.InputContainerAttendance}
              >
                <div style={{ width: "20%" }}>
                  <span className={`${styles.formFont}`}>Mark Attendance</span>
                  <BlueSwitch
                    {...label}
                    checked={formData.attendanceRequired ? true : false}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <span className={`${styles.formFont}`}>
                    Enable Geo - Fencing
                  </span>
                  <BlueSwitch
                    {...label}
                    checked={formData.geoFencingRequired ? true : false}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ marginTop: "54px" }}
                className={styles.InputContainerAttendance}
              >
                <div style={{ width: "20%" }}>
                  <span className={`${styles.formFont}`}>Mark Attendance</span>
                  <BlueSwitch
                    {...label}
                    checked={formData.attendanceRequired ? true : false}
                    onChange={(e) => handleChange(e, "attendanceRequired")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
                <div
                  style={{
                    width: "20%",
                    color: formData.attendanceRequired ? "#2E2E2E" : "#B8B8B8",
                  }}
                >
                  <span className={`${styles.formFont}`}>
                    Enable Geo - Fencing
                  </span>
                  <BlueSwitch
                    {...label}
                    checked={formData.geoFencingRequired ? true : false}
                    onChange={(e) => handleChange(e, "geoFencingRequired")}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={formData.attendanceRequired ? false : true}
                  />
                </div>
              </div>
            )}
            <FormGroup
              className={`${styles.InputContainer} ${classes.attendanceOption}`}
              style={{ gridColumnGap: "2rem" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      formMode === "view" || !formData.attendanceRequired
                    }
                    checked={
                      formData.attendanceRequired_currentLocation ? true : false
                    }
                    onChange={(e) =>
                      handleChange(e, "attendanceRequired_currentLocation")
                    }
                  />
                }
                label="Current Location"
                className={classes.FormControlLabel}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      formMode === "view" || !formData.attendanceRequired
                    }
                    checked={formData.attendanceRequired_selfie ? true : false}
                    onChange={(e) =>
                      handleChange(e, "attendanceRequired_selfie")
                    }
                  />
                }
                label="Selfie"
              />
            </FormGroup>
            <div
              style={{ marginTop: "54px" }}
              className={`${styles.InputContainerAttendance}`}
            >
              <div
                className={styles.InputBox}
                style={{
                  width: formMode === "view" ? "" : "32%",
                  display: formMode === "view" ? "flex" : "",
                  flexDirection: formMode === "view" ? "column" : "row",
                }}
              >
                <span
                  style={{
                    width: "max-content",
                    marginRight: formMode === "view" ? "55px" : "30px",
                    color:
                      formMode === "view" || !formData.attendanceRequired
                        ? "rgba(0, 0, 0, 0.38)"
                        : "",
                  }}
                >
                  Shift Start Time
                </span>
                {formMode === "view" ? (
                  <p>{moment(selectedTime.startTime).format("HH:mm")}</p>
                ) : (
                  <TimeFilter
                    uniqueKey="projectShiftTime"
                    timeRange={false}
                    disabled={formData}
                    formMode={formMode}
                    ampm={false}
                    val={selectedTime.startTime}
                    handleChange={handleChange}
                    type="shiftStartTime"
                  />
                )}
              </div>
              <div
                className={styles.InputBox}
                style={{
                  width: formMode === "view" ? "" : "32%",
                  display: formMode === "view" ? "flex" : "",
                  flexDirection: formMode === "view" ? "column" : "row",
                }}
              >
                <span
                  style={{
                    width: "max-content",
                    marginRight: formMode === "view" ? "55px" : "30px",
                    color:
                      formMode === "view" || !formData.attendanceRequired
                        ? "rgba(0, 0, 0, 0.38)"
                        : "",
                  }}
                >
                  Shift End Time
                </span>
                {formMode === "view" ? (
                  <p>{moment(selectedTime.endTime).format("HH:mm")}</p>
                ) : (
                  <TimeFilter
                    uniqueKey="projectShiftTime"
                    timeRange={false}
                    disabled={formData}
                    formMode={formMode}
                    ampm={false}
                    val={selectedTime.endTime}
                    handleChange={handleChange}
                    type="shiftEndTime"
                  />
                )}
              </div>
            </div>
            {formMode === "view" ? (
              <div
                style={{ marginTop: "54px", marginBottom: "6px" }}
                className={styles.InputContainer}
              >
                <div>
                  <span className={`${styles.formFont}`}>
                    Capture Multiple Attendance
                  </span>
                  <BlueSwitch
                    {...label}
                    checked={formData.slots ? true : false}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ marginTop: "54px", marginBottom: "6px" }}
                className={styles.InputContainer}
              >
                <div>
                  <span className={`${styles.formFont}`}>
                    Capture Multiple Attendance
                  </span>
                  <BlueSwitch
                    {...label}
                    checked={
                      formData.isCaptureMultipleAttendance ? true : false
                    }
                    onChange={(e) =>
                      handleChange(e, "isCaptureMultipleAttendance")
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={formData.attendanceRequired ? false : true}
                  />
                </div>
              </div>
            )}
            <div className={`${styles.InputSlots} ${classes.clientName}`}>
              {formMode === "view" ? (
                <p>
                  {formData.slots?.id
                    ? formData.slots.id === 1
                      ? "01 time"
                      : `0${formData.slots.id} times`
                    : ""}
                </p>
              ) : (
                <AutoSelect
                  data={formData}
                  setData={setFormData}
                  id="slots"
                  multipleFlag={false}
                  label="Type & Select Number of Instance"
                  list={optionList.slotList}
                  disabled={
                    formData.isCaptureMultipleAttendance === 1 ? false : true
                  }
                />
              )}
            </div>
            {!!formData.attendanceRequired && (
              <div
                style={{ marginTop: "54px" }}
                className={styles.InputContainer}
              >
                <div className={styles.InputBox}>
                  <span>
                    {" "}
                    <span
                      className={`${styles.formFont} ${
                        formMode === "create" && !formData.attendanceRequired
                          ? styles.lightText
                          : styles.requiredField
                      }`}
                    >
                      Attendance Web Link
                    </span>{" "}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ width: formMode !== "view" ? "93%" : "" }}
                    >
                      {formMode === "view" ? (
                        <a
                          href={formData.attendanceUrl}
                          target="_blank"
                          className={styles.downloadTemplate}
                          style={{ marginRight: "20px" }}
                        >
                          {formData.attendanceUrl?.length > 35
                            ? formData.attendanceUrl?.substr(0, 32) +
                              "..." +
                              formData.attendanceUrl?.substr(
                                formData.attendanceUrl.length - 3
                              )
                            : formData.attendanceUrl}
                        </a>
                      ) : (
                        <TextField
                          id="outlined-adornment-weight"
                          aria-describedby="outlined-weight-helper-text"
                          InputProps={{
                            endAdornment: (
                              <>
                                <InsertLink
                                  style={{
                                    rotate: "135deg",
                                    color:
                                      formData.attendanceRequired &&
                                      formMode !== "edit"
                                        ? "#1C75BC"
                                        : "",
                                    display:
                                      formMode === "create" ? "block" : "none",
                                  }}
                                />
                              </>
                            ),
                          }}
                          className={styles.textComponent}
                          value={formData.attendanceUrl}
                          onChange={(e) => {
                            handleInputChange(e, 5);
                          }}
                          disabled={true}
                          fullWidth
                        />
                      )}
                    </FormControl>
                    <div
                      onClick={() => copyToClipboard(formData.attendanceUrl)}
                    >
                      <ContentCopy
                        style={{
                          color: "#1C75BC",
                          display:
                            formMode === "view" && formData.attendanceRequired
                              ? "block"
                              : "none",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.saveCancel}>
              <div className={styles.BtnBox}>
                <Link to="/projectConfig" style={{ textDecoration: "none" }}>
                  {" "}
                  <Button
                    onClick={() => {
                      setFormMode("create");
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>{" "}
                </Link>
                {!!userAction.edit && (
                  <Button
                    disabled={
                      formMode === "view" ||
                      isUrl === false ||
                      (formData.agreementRequired && isServiceUrl === false) ||
                      validationErr.projectDescription ||
                      validationErr.projectCode ||
                      validationErr.projectKey ||
                      validationErr.shiftTime ||
                      fieldEmptyCheck
                    }
                    onClick={handleSaveBtn}
                    variant="contained"
                    style={{ marginLeft: "20px" }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={laoding}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};
export default AddProject;
