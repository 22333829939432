import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import styledComponent from "styled-components";
import {
  Button,
  Grid,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  Switch,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import Pagination from "../../Utils/Pagination";
import { alpha, makeStyles, styled } from "@material-ui/core/styles";
import * as moment from "moment";
import { AuthContext } from "../../services/AuthProvider";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import FilterDrawer from "./filters";
import OffboardingModal from "./components/offboardingModal";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import AdminNotesModel from "./AdminNotes";
import LocationModal from "./LocationModal";
import CloseJobModal from "./components/CloseJobModal";
import "./indeterminateCheckbox.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import filterIcon from "../ProjectConfig/filterIcon.svg";
import filterIconMarked from "../ProjectConfig/filterIconMarked.svg";
import { AppData } from "../../context/context";

const Styles = styledComponent.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf !important;
      border-right: 1px solid #cfcfcf !important;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  actionButton: {
    "& .MuiSelect-selectMenu": {
      border: "1px solid #1C75BC",
      color: "#1C75BC",
      fontSize: "15px",
      fontWeight: "500",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 9px)",
      fontSize: "18px !important",
    },
    "& .MuiSelect-iconOutlined": {
      color: "#1C75BC",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1C75BC !important",
    },
  },
  actionButtonDisabled: {
    "& .MuiSelect-selectMenu": {
      color: "#C1C1C1",
      fontSize: "15px",
      fontWeight: "500",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 9px)",
      fontSize: "18px !important",
    },
    "& .MuiSelect-iconOutlined": {
      color: "#C1C1C1",
    },
  },
  dropdownStyle: {
    position: "absolute",
    top: "163px !important",
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

function removeExtraSpaces(string) {
  return string.replace(/\s{2,}/g, " ");
}

const Jobsdb = (props) => {
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const { userAction } = useContext(AppData);
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [disablePayments, setDisablePayments] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [searchBy, setSearchBy] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openOffboardingModal, setOpenOffboardingModal] = useState(false);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [openAdminNotes, setOpenAdminNotes] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openDatePicker, setopenDatePicker] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [row, setRow] = useState(null);
  const [disable, setDisable] = useState(true);
  const [statusResult, setStatusResult] = useState({
    result: "",
    status: "",
  });
  const [openCloseJobModal, setOpenCloseJobModal] = useState(false);
  const [actions, setActions] = useState(0);
  const [leadCityId, setLeadCityId] = useState([]);
  const [curLeadId, setCurLeadId] = useState({
    leadCity: [],
  });
  const [isfilter, setIsfilter] = useState(false);
  const [jobsInDemand, setJobsInDemand] = useState([]);

  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [null, null],
    zone: "",
    facility_state: "",
    facility_name: "",
    jobStatus: "",
    facilityCity: "",
    partnerId: "",
    projectId: "",
    verticalId: "",
    area: "",
    shiftStartTime: { id: null, name: null },
    shiftEndTime: { id: null, name: null },
  });

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "swiggy_id":
        url = "&partnerId=101";
        break;
      case "flipkart_id":
        url = "&partnerId=100";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  const handleChange = async (event, type, val) => {
    event.preventDefault();
    if (type === "jobsInDemand") {
      let isChecked = event.target.checked;

      await Axios.post(
        `${urlLinks.baseUrl}${urlLinks.jobs.setDemandJob}?inDemand=${
          isChecked ? 1 : 0
        }&job_id=${val}`
      ).then((response) => {
        let resMsg = response.data.response;
        if (resMsg === "Maximum of 3 in-demand job requests exceeded.") {
          isChecked = false;
          showToast("error", resMsg);
          return;
        }
      });
      if (isChecked) {
        setJobsInDemand((prev) => [...prev, val]);
      } else {
        setJobsInDemand((prev) => prev.filter((id) => id !== val));
      }
    }
  };

  const uploadOffboardingData = () => {
    setOpenOffboardingModal(true);
  };

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["area"]) {
      url = `${url}&area=${filters["area"].name}`;
    }
    if (filters["facility_state"]) {
      url = `${url}&facility_state=${filters["facility_state"].name}`;
    }
    if (filters["facility_name"]) {
      url = `${url}&facility_name=${filters["facility_name"].name}`;
    }
    if (filters["facilityCity"]) {
      url = `${url}&city=${filters["facilityCity"]}`;
    }
    if (filters["jobStatus"]) {
      url = `${url}&status=${filters["jobStatus"].id}`;
    }
    if (filters["partnerId"]) {
      url = `${url}&partner_id=${filters["partnerId"].id}`;
    }
    if (filters["projectId"]) {
      url = `${url}&projectId=${filters["projectId"].id}`;
    }
    if (filters["verticalId"]) {
      url = `${url}&vertical=${filters["verticalId"].id}`;
    }
    if (filters["shiftStartTime"].id) {
      let time = moment(filters["shiftStartTime"].id).format("HH:mm");
      url = `${url}&shiftStartTime=${time}`;
    }
    if (filters["shiftEndTime"].id) {
      let time = moment(filters["shiftEndTime"].id).format("HH:mm");
      url = `${url}&shiftEndTime=${time}`;
    }
    setFilterUrl(url);
    setFilters(filters);
    setLeadCityId(leadCityId);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  const closeBulkJob = (val) => {
    const indexes = Object.entries(selectedRowIds);
    let idList = [];
    let field = {
      openJob: ["openJob", 1],
      closeJob: ["closeJob", 2],
    };

    indexes.map((id, index) => {
      idList.push(data[parseFloat(id[0])].jobId);
      return true;
    });

    let idStr = idList.join(",");

    if (idList.length !== 0 && val === "closeJob") {
      Axios.post(
        `${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave2}?field=${
          field.closeJob[0]
        }&job_id=${idStr}&updated_by=${localStorage.getItem("userID")}&value=${
          field.closeJob[1]
        }`
      ).then((response) => {
        if (response.data.code === 1000) {
          updateMyData();
          showToast("success", "Job closed successfully.");
        } else {
          showToast("error", "Closing job failed");
        }
        setOpenCloseJobModal(false);
      });
    } else if (idList.length !== 0 && val === "openJob") {
      Axios.post(
        `${urlLinks.baseUrl}${urlLinks.jobs.jobEditSave2}?field=${
          field.closeJob[0]
        }&job_id=${idStr}&updated_by=${localStorage.getItem("userID")}&value=${
          field.openJob[1]
        }`
      ).then((response) => {
        if (response.data.code === 1000) {
          updateMyData();
          showToast("success", "Job opened successfully.");
        } else {
          showToast("error", "Opening job failed");
        }
        setOpenCloseJobModal(false);
      });
    } else {
      setOpenCloseJobModal(false);
      showToast("error", "Selected jobs are already closed");
    }
  };

  const handleCloseJob = () => {
    setOpenCloseJobModal(true);
  };

  const handleLocation = (props) => {
    setRow(props);
    setOpenLocation(true);
  };

  const resetFilters = () => {
    const filterList = {
      searchBy: "",
      billDate: [null, null],
      zone: "",
      facility_state: "",
      facility_name: "",
      jobStatus: "",
      facilityCity: "",
      partnerId: "",
      projectId: "",
      verticalId: "",
      area: "",
      shiftStartTime: { id: null, name: null },
      shiftEndTime: { id: null, name: null },
    };
    setFilters(filterList);
    setFilterUrl("");
    setLeadCityId([]);
    setCurLeadId({
      leadCity: [],
    });
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const BlueSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#1C75BC",
      "&:hover": {
        backgroundColor: alpha("#1C75BC", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#1C75BC",
    },
    "& .MuiSwitch-switchBase": {
      cursor: "pointer",
    },
  }));

  //columns list
  const columnsOkayGo = useMemo(() => {
    const baseColumns = [
      {
        Header: "Job title",
        accessor: "jobTitle",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Client & project",
        accessor: "clientProject",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Status",
        accessor: "status",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Locations",
        Cell: (row) => {
          return (
            <Grid container className="padding0">
              {row.row.original.location === "Remote" ? (
                <span>{row.row.original.location}</span>
              ) : (
                <button
                  className="linkButton1"
                  onClick={() => handleLocation(row.row.original)}
                >
                  <u>{row.row.original.location}</u>
                </button>
              )}
            </Grid>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Applications",
        accessor: "applications",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Jobs in demand",
        accessor: "inDemand",
        Cell: (row) => {
          return (
            <span>
              <BlueSwitch
                {...label}
                disabled={!userAction.edit}
                checked={
                  row.row.original.jobId ===
                  jobsInDemand.filter((id) => {
                    return id === row.row.original.jobId;
                  })[0]
                }
                onChange={(e) =>
                  handleChange(e, "jobsInDemand", row.row.original.jobId)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            </span>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <Link
              to={{ pathname: `/jobsdb/jobConfig/${row.row.original.jobId}` }}
              style={{
                textDecoration: "none",
                color: "#1C75BC",
                border: "1px solid #1C75BC",
                borderRadius: "5px",
                padding: "5% 10%",
                fontSize: "16px",
              }}
            >
              View/Edit
            </Link>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ];

    if (!!userAction.edit) {
      baseColumns.unshift({
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
              user={row}
            />
          </div>
        ),
      });
    }

    return baseColumns;
  }, [
    filterUrl,
    filters,
    sortBy,
    sortUrl,
    perPage,
    currentPage,
    jobsInDemand,
    userAction.edit,
  ]);

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    let lastupdatedon = [];
    let jobsInDemandList = [];

    response.content.map((el) => {
      if (el.inDemand) {
        jobsInDemandList.push(el.jobId);
      }
      extractedData.push({
        jobId: el.jobId || "",
        jobTitle: el.jobTitle || "",
        clientProject: `${el.client} - ${el.project}` || "",
        status:
          el.jobStatus === 1 ? "Active" : el.jobStatus === 2 ? "Inactive" : "",
        location:
          el.locationType === "Remote"
            ? el.locationType
            : el.locationCount == 1 ||
              el.locationCount == 2 ||
              el.locationCount == 3 ||
              el.locationCount == 4 ||
              el.locationCount == 5 ||
              el.locationCount == 6 ||
              el.locationCount == 7 ||
              el.locationCount == 8 ||
              el.locationCount == 9
            ? `0${el.locationCount} locations`
            : `${el.locationCount} locations`,
        applications: el.applicationCount ? el.applicationCount : "-",
      });
      lastupdatedon.push(el.lastUpdatedOn);
      return true;
    });
    if (!isfilter) {
      setJobsInDemand(jobsInDemandList);
    }

    return extractedData;
  };

  //Function to create All record export data

  const columns = columnsOkayGo;
  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";
    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.jobs.jobsdbListingNew}?page_no=${
          pageNo - 1
        }${requestedBy}&rows=${rows}${filterUrl}${sortUrl}`
      )
        .then((response) => {
          if (response) {
            setTotalRows(response.data.response.totalElements);
            setTotalPages(response.data.response.totalPages);
            if (currentPage > response.data.response.totalPages)
              setCurrentPage(1);
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handlePageChange = (page) => {
    getPaymentListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [""] },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getPaymentListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  /* UseEffects */

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    getPaymentListing(perPage, 1);
  }, [filterUrl, sortUrl, localStorage.getItem("roleType")]);

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  useEffect(() => {
    let searchByVar = removeExtraSpaces(searchBy);
    let count = 0;
    count += (searchByVar.match(/ /g) || []).length;
    count += (searchByVar.match(/,/g) || []).length;
    count++;
    if (count > 100) {
      setSearchError(true);
    } else if (count <= 100) {
      setSearchError(false);
    }
  }, [searchBy]);

  useEffect(() => {
    if (
      (filters.projectId !== null && filters.projectId !== "") ||
      filters.facilityCity !== "" ||
      (filters.verticalId !== "" && filters.verticalId !== null) ||
      (filters.jobStatus !== "" && filters.jobStatus !== null)
    ) {
      setIsfilter(true);
    } else {
      setIsfilter(false);
    }
  }, [filters]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      const rowStatus = Object.keys(selectedRowIds);
      let disableButton = true;
      let jobStatusList = [];
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      rowStatus.map((id) => {
        jobStatusList.push(rows[id].allCells[3].value);
      });
      const res = jobStatusList.every((val, i, arr) => val === arr[0]);
      setStatusResult({
        result: res,
        status: jobStatusList[0],
      });
      setDisable(disableButton);
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (statusResult.result === false) {
      showToast(
        "error",
        "Select either 'Active' or 'Inactive' jobs only at a time"
      );
    }
  }, [statusResult]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? "stickWorkerNameMatchNew zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1060 header width30"
                      : column.render("Header") === "Action"
                      ? "header width80"
                      : "header"
                  }
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length === 0 ? (
          ""
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? "stickWorkerNameMatchNew zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {rows.length === 0 ? (
        <div className={styles.noRecord}>
          <img src={NoRecord} alt="noRecord" />
          <span>No records found</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  const handleActions = (event) => {
    if (event.target.value === "closeJob") {
      handleCloseJob();
    } else if (event.target.value === "openJob") {
      closeBulkJob("openJob");
    }
  };

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid style={{ justifyContent: "space-between" }} container spacing={2}>
          <Grid item xs={6}>
            <span className="fontSize24 fontWeightBold">Job listing page</span>
          </Grid>
          {!!userAction.edit && (
            <Grid
              style={{
                marginLeft: "54px",
                display: "flex",
                justifyContent: "end",
              }}
              item
              xs={4}
            >
              <Link
                to="/jobsdb/jobConfig/add"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    textTransform: "unset",
                    color: "#1C75BC",
                    border: "1px solid #1C75BC",
                    height: "fit-content",
                    padding: "6px 10px",
                  }}
                  variant="outlined"
                  className={styles.blueOutlinedButton}
                  onClick={uploadOffboardingData}
                >
                  Add Job
                </Button>
              </Link>
              <FormControl
                variant="outlined"
                className={
                  disable || statusResult.result == false
                    ? `${classes.actionButtonDisabled}`
                    : `${classes.actionButton}`
                }
                style={{ marginLeft: "16px" }}
                disabled={disable || statusResult.result == false}
              >
                <Select
                  id="actions"
                  value={actions}
                  classes={{ outlined: styles.actionSelect }}
                  onChange={(e) => handleActions(e)}
                  IconComponent={MoreVertIcon}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value={0} disabled={true}>
                    {" "}
                    Actions
                  </MenuItem>
                  <MenuItem
                    value="openJob"
                    disabled={
                      statusResult.result && statusResult.status === "Inactive"
                        ? false
                        : true
                    }
                  >
                    Open job
                  </MenuItem>
                  <MenuItem
                    value="closeJob"
                    disabled={
                      statusResult.result && statusResult.status === "Active"
                        ? false
                        : true
                    }
                  >
                    Close job
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className="flexDisplay"
          style={{ padding: "1.5rem 0 1rem 0" }}
        >
          <Grid item xs={8} className="flexDisplay padding0 paddingTop8"></Grid>
          <Grid
            item
            xs={4}
            className="flexDisplay paddingBottom8"
            style={{ justifyContent: "flex-end", maxWidth: "550px" }}
          >
            <button
              className={styles.filterButton}
              onClick={() => setOpenFilterDrawer(true)}
            >
              {!isfilter ? (
                <img src={filterIcon} alt="filterIcon" />
              ) : (
                <img
                  src={filterIconMarked}
                  alt="filterIcon"
                  style={{ height: "16px" }}
                />
              )}
              Show filters
            </button>
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            {data.length === 0 ? (
              ""
            ) : (
              <Pagination
                rowsperpage={perPage}
                pageNo={currentPage}
                totalElements={totalRows}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
              />
            )}
          </Grid>
        )}
        {openAdminNotes && (
          <AdminNotesModel
            openAdminNotes={openAdminNotes}
            setOpenAdminNotes={setOpenAdminNotes}
            noteData={row.noOfOpenings}
            recruiterMasterId={row.jobId}
            updateMyData={updateMyData}
            showToast={showToast}
          />
        )}
        {openDatePicker && (
          <AdminNotesModel
            openAdminNotes={openAdminNotes}
            setOpenAdminNotes={setOpenAdminNotes}
            noteData={row.noOfOpenings}
            recruiterMasterId={row.jobId}
            updateMyData={updateMyData}
            showToast={showToast}
          />
        )}
        {openLocation && (
          <LocationModal
            openAdminNotes={openLocation}
            setOpenAdminNotes={setOpenLocation}
            recruiterMasterId={row.jobId}
          />
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => {
            setLeadCityId(curLeadId.leadCity);
            setOpenFilterDrawer(false);
          }}
        >
          <FilterDrawer
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
            leadCityId={leadCityId}
            setLeadCityId={setLeadCityId}
            curLeadId={curLeadId}
            setCurLeadId={setCurLeadId}
          ></FilterDrawer>
        </Drawer>
        {openOffboardingModal && (
          <OffboardingModal
            openOffboardingModal={openOffboardingModal}
            setOpenOffboardingModal={setOpenOffboardingModal}
            showToast={showToast}
            updateMyData={updateMyData}
          />
        )}
        <CloseJobModal
          open={openCloseJobModal}
          setOpen={setOpenCloseJobModal}
          closeBulkJob={closeBulkJob}
        />
      </Grid>
    </Styles>
  );
};

export default Jobsdb;
