import React, { useEffect, useState } from "react";
import styles from "./Dialogs.module.scss";
import {
  Close,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@material-ui/icons";
import * as moment from "moment";
import { Box, IconButton, Modal } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import NoRecord from "../../assets/noRecord.svg";
import axios from "axios";
import UrlLinks from "../../UrlLinks";

export default function HistoryModal(props) {
  const {
    uniqueKey,
    open,
    setOpen,
    title,
    historyList,
    historyColumns,
    applicationStatusList,
    showToast,
  } = props;

  const { users, workerData } = useSelector((state) => state);
  const { modalLoader } = users;
  const { rowData } = workerData;

  const [handleView, setHandleView] = useState({});

  useEffect(() => {
    if (uniqueKey !== "driveHistory" || historyList.length === 0) return;
    let obj = {};
    historyList.map((item) => {
      obj = {
        ...obj,
        [item.logId]: {
          show: false,
          val: item.shift.split(",").splice(0, 2).join(","),
        },
      };
      return true;
    });
    setHandleView(obj);
  }, [uniqueKey, historyList]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: uniqueKey === "serviceAgreement" ? 522 : 731,
    height: 587,
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
  };

  const [downloadLoader, setDownloadLoader] = useState("");

  const handleClose = () => setOpen(false);

  const handleClick = async (event, id, data, type) => {
    switch (type) {
      case "serviceAgreement": {
        try {
          setDownloadLoader(id);
          const res = await axios.get(
            `${UrlLinks.baseUrl}${UrlLinks.workerUrls.getAgreementByUserId}?projectId=${data.projects_id}&userId=${rowData.userId}`
          );
          let fileURL = res.data.response;
          if (!fileURL.includes("https:")) {
            if (fileURL.startsWith("http:")) {
              fileURL = fileURL.replace("http:", "https:");
            }
          }
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(fileURL);
          document.body.removeChild(link);
          setDownloadLoader("");
        } catch (error) {
          console.log("Error:", error);
          showToast(
            "error",
            "Error",
            "Something went wrong, please try again later."
          );
        }
        return;
      }
      case "briberyAgreement": {
        try {
          setDownloadLoader(id);
          const res = await axios.get(
            `${UrlLinks.baseUrl}/task-model/getAntiBriberyByUserId?projectId=${data.projects_id}&userId=${rowData.userId}`
          );
          let fileURL = res.data.response;
          if (!fileURL.includes("https:")) {
            if (fileURL.startsWith("http:")) {
              fileURL = fileURL.replace("http:", "https:");
            }
          }
          const link = document.createElement("a");
          link.href = fileURL;
          link.setAttribute("target", "_blank");
          link.setAttribute("rel", "noopener noreferrer");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(fileURL);
          document.body.removeChild(link);
          setDownloadLoader("");
        } catch (error) {
          console.log("Error:", error);
          showToast(
            "error",
            "Error",
            "Something went wrong, please try again later."
          );
        }
        return;
      }
      default:
        break;
    }
  };

  const loaderBar = (
    <div
      style={{
        display: "flex",
        justifyContent: downloadLoader > -1 ? "left" : "center",
      }}
    >
      <ScaleLoader height={20} width={10} color={"#1c75bc"} />
    </div>
  );

  const applicationHistoryContent = (item, id) => (
    <div key={id} className={styles.userBox}>
      {historyColumns.map((column, index) => {
        return (
          <div
            key={index}
            style={{
              width: "16%",
            }}
          >
            <span className={styles.columnName}>{column.label}</span>
            {index === 0 ? (
              <span className={styles.columnValue}>{item.jobName || "-"}</span>
            ) : index === 1 ? (
              <span className={styles.columnValue}>
                {moment(item.appliedOn).format("DD/MM/YYYY")}
              </span>
            ) : index === 2 ? (
              <span className={styles.columnValue}>
                {applicationStatusList[
                  Object.keys(applicationStatusList).find(
                    (statusKey) => statusKey == item.status
                  )
                ] || "-"}
              </span>
            ) : index === 3 ? (
              <span className={styles.columnValue}>
                {item.insertedOn
                  ? moment(item.insertedOn).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ) : index === 4 ? (
              <span className={styles.columnValue}>
                {item.status_updated_by || "-"}
              </span>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  const workerHistoryContent = (item, id) => (
    <div key={id} className={styles.userBox}>
      {historyColumns.map((column, index) => {
        return (
          <div
            key={index}
            style={{
              width: "23%",
            }}
          >
            <span className={styles.columnName}>{column.label}</span>
            {index === 0 ? (
              <span className={styles.columnValue}>
                {item.clientName || "-"}
              </span>
            ) : index === 1 ? (
              <span className={styles.columnValue}>{item.vertical || "-"}</span>
            ) : index === 2 ? (
              <span className={styles.columnValue}>
                {item.firstEarningDate
                  ? moment(item.firstEarningDate).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ) : index === 3 ? (
              <span className={styles.columnValue}>
                {item.status ? "Active" : "Inactive"}
              </span>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  const driveHistoryContent = (item, id) => {
    const handleViewMore = (id) => {
      let obj = {
        ...handleView,
        [id]: {
          ...handleView[id],
          show: !handleView[id].show,
          val: handleView[id].show
            ? handleView[id].val.split(",").splice(0, 2).join(",")
            : item.shift,
        },
      };
      setHandleView(obj);
    };

    return (
      <div key={id} className={styles.userBox}>
        {handleView !== {} &&
          historyColumns.map((column, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "23%",
                }}
              >
                <span className={styles.columnName}>{column.label}</span>
                {column.key === "shift" ? (
                  <div>
                    <div className={styles.columnValue} id={id}>
                      {handleView[item.logId].val.replace(/,$/, "") || "-"}
                    </div>
                    {!handleView[item.logId].show &&
                    item.shift.replace(/,$/, "").split(",").length > 2 ? (
                      <button
                        className={styles.linkButton}
                        type="button"
                        onClick={(e) => handleViewMore(item.logId)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "7px",
                        }}
                      >
                        View more
                        <KeyboardArrowDownOutlined />
                      </button>
                    ) : handleView[item.logId].show &&
                      item.shift.replace(/,$/, "").split(",").length > 2 ? (
                      <button
                        className={styles.linkButton}
                        type="button"
                        onClick={(e) => handleViewMore(item.logId)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "7px",
                        }}
                      >
                        View less
                        <KeyboardArrowUpOutlined />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <span className={styles.columnValue}>
                    {item[column.key] || "-"}
                  </span>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  const serviceAgreementContent = (item, id) => {
    return (
      <div key={id} className={styles.userBox}>
        {historyColumns.map((column, index) => {
          return (
            <div
              key={index}
              style={{
                width: "48%",
              }}
            >
              <span className={styles.columnName}>{column.label}</span>
              {index === 0 ? (
                <span className={styles.columnValue}>
                  {item.project_name || "-"}
                </span>
              ) : index === 1 ? (
                downloadLoader === id ? (
                  loaderBar
                ) : (
                  <button
                    className={`${styles.columnValue} ${styles.linkButton}`}
                    onClick={(e) =>
                      handleClick(e, id, item, "serviceAgreement")
                    }
                  >
                    Download
                  </button>
                )
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const briberyAgreementContent = (item, id) => {
    return (
      <div key={id} className={styles.userBox}>
        {historyColumns.map((column, index) => {
          return (
            <div
              key={index}
              style={{
                width: "48%",
              }}
            >
              <span className={styles.columnName}>{column.label}</span>
              {index === 0 ? (
                <span className={styles.columnValue}>
                  {item.project_name || "-"}
                </span>
              ) : index === 1 ? (
                downloadLoader === id ? (
                  loaderBar
                ) : (
                  <button
                    className={`${styles.columnValue} ${styles.linkButton}`}
                    onClick={(e) =>
                      handleClick(e, id, item, "briberyAgreement")
                    }
                  >
                    Download
                  </button>
                )
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      className={styles.historyModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      {
        <Box sx={style} className={styles.historyBox}>
          <p className={styles.header}>
            {title}
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </p>
          {modalLoader ? (
            loaderBar
          ) : historyList.length > 0 ? (
            historyList.map((item, id) => {
              return uniqueKey === "applicationHistory"
                ? applicationHistoryContent(item, id)
                : uniqueKey === "workingHistory"
                ? workerHistoryContent(item, id)
                : uniqueKey === "driveHistory"
                ? driveHistoryContent(item, id)
                : uniqueKey === "serviceAgreement"
                ? serviceAgreementContent(item, id)
                : uniqueKey === "briberyAgreement"
                ? briberyAgreementContent(item, id)
                : null;
            })
          ) : (
            <div className={styles.noRecord}>
              <img src={NoRecord} alt="noRecord" style={{ height: "100px" }} />
              <span>No data</span>
            </div>
          )}
        </Box>
      }
    </Modal>
  );
}
