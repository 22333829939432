import React, { useState, useEffect, useMemo, useContext } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Checkbox,
  TextField,
  InputAdornment,
  Drawer,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { CSVLink } from "react-csv";
import SearchIcon from "@material-ui/icons/Search";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import Swal from "sweetalert2";
import HelpSection from "./components/HelpSection";
import { AuthContext } from "../../services/AuthProvider";
import "./index.css";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import DateFilter from "./components/DateFilter";
import "./indeterminateCheckbox.css";
import FilterDrawer from "./filters";
import { AppData } from "../../context/context";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    //   border-radius: 8px;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "0",
      "&.Mui-focused fieldset": {
        border: "1px solid grey",
      },
    },
  },
  input: {
    '& [class*="MuiOutlinedInput-input"]': {
      padding: "8px 135px 8px 8px",
      borderLeft: "1px solid grey",
      borderRadius: "0px",
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <React.Fragment>
        <Checkbox ref={resolvedRef} {...rest} />
      </React.Fragment>
    );
  }
);

const ExternalWorker = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const { userAction } = useContext(AppData);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [searchUrl, setSearchUrl] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [searchByAuto, setSearchByAuto] = useState("");
  const [tab, setTab] = useState({
    invoiceTab: false,
    transactionTab: true,
  });

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  const [filters, setFilters] = useState({
    searchBy: "",
    billDate: [
      moment().add(-1, "days").format("YYYY-MM-DD"),
      moment().add(-1, "days").format("YYYY-MM-DD"),
    ],
    zm_user_id: "",
    isAj: "",
    roleType: "",
  });

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "swiggy_id":
        url = "&partnerId=101";
        break;
      case "flipkart_id":
        url = "&partnerId=100";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
  }, [sortBy]);

  const handleSearch = () => {
    setSearchUrl(`&searchBy=${searchBy}`);
  };

  const handleSearchClose = () => {
    setSearchUrl(``);
    setSearchBy("");
  };

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=2019-01-01&date2=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(filters["billDate"][0]).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["zm_user_id"])
      url = `${url}&zm_user_id=${filters["zm_user_id"].id}`;
    if (filters["isAj"]) url = `${url}&isAj=${filters["isAj"].id}`;
    if (filters["roleType"]) url = `${url}&category=${filters["roleType"].id}`;
    setFilterUrl(url);
    setFilters(filters);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };
  const resetFilters = () => {
    const filterList = {
      searchBy: "",
      billDate: [
        moment().add(-1, "days").format("YYYY-MM-DD"),
        moment().add(-1, "days").format("YYYY-MM-DD"),
      ],
      zm_user_id: "",
      isAj: "",
      roleType: "",
    };
    setFilters(filterList);

    let url = "";
    if (filters["billDate"][0] && !filters["billDate"][1])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=2019-01-01&date2=${moment(
        filters["billDate"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["billDate"][1] && filters["billDate"][0])
      url = `${url}date1=${moment(filters["billDate"][1]).format(
        "YYYY-MM-DD"
      )}&date2=${moment(filters["billDate"][0]).format("YYYY-MM-DD")} 23:59:59`;

    setFilterUrl(url);
    setIsFilterApplied(true);
    setCurrentPage(1);

    return filterList;
  };

  //columns list
  const transactionCols = useMemo(() => {
    const baseColumns = [
      {
        Header: "Recruiter Id",
        accessor: "recruiterId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Name",
        accessor: "name",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Zonal Manager",
        accessor: "zoneManager",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "City",
        accessor: "recruiterCity",
        Cell: (row) => {
          const match = row.row.original.recruiterCity.match(
            /^(?:[^,]*,){2}([^,]*)/
          );
          if (match !== null) {
            let cutstr = row.row.original.recruiterCity;

            cutstr = match.slice(0, -1);

            return <> {cutstr} ...</>;
          } else {
            return row.row.original.recruiterCity;
          }
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role Type",
        accessor: "roleType",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Block - Get Leads",
        Cell: (row) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  checked={row.row.original.isExt === 1}
                  onClick={() =>
                    handleOnHold(
                      !row.row.original.isExt,
                      row.row.original.recruiterId
                    )
                  }
                  color="primary"
                  disabled={!userAction.edit}
                />
              }
              label={row.row.original.isExt === 1 ? "Blocked" : ""}
            />
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "AJ Recruiter",
        accessor: "isAj",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Leads Allocated",
        accessor: "leadsAllocated",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Leads added by recruiter",
        accessor: "leadsAddedByRecruiter",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Leads from OkayGo",
        accessor: "leadsAddedFromOkayGo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Interview",
        accessor: "totalInterview",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Under Training",
        accessor: "totalUnderTraining",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Joined",
        accessor: "totalJoined",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Total Client ID",
        accessor: "totalCasperGen",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ];

    if (!!userAction.edit) {
      baseColumns.unshift({
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      });
    }

    return baseColumns;
  }, [filterUrl, userAction.edit]);

  const handleFilterDates = (dateOne, dateTwo, indexOne, indexTwo, type) => {
    let dates = filters.billDate;
    dates[indexOne] = dateOne;
    dates[indexTwo] = dateTwo;
    setFilters({ ...filters, [type]: dates });
    ApplyFilters(filters);
  };

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      extractedData.push({
        isExt: el.isExt !== null ? el.isExt : 0,
        totalInterview: el.totalInterview ? el.totalInterview : "",
        totalUnderTraining: el.totalUnderTraining ? el.totalUnderTraining : "",
        totalJoined: el.totalJoined ? el.totalJoined : "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        recruiterId: el.recruiterId ? el.recruiterId : "-",
        leadsAllocated: el.leadsAllocated ? el.leadsAllocated : "-",
        totalCasperGen: el.total_client_id_gen ? el.total_client_id_gen : "-",
        userId: el.userId ? el.userId : "-",
        recruiterCity: el.recruiterCity ? el.recruiterCity : "-",
        gender: el.gender ? el.gender : "-",
        dateOfBirth: el.dateOfBirth ? el.dateOfBirth : "-",
        emailId: el.emailId ? el.emailId : "-",
        phoneNumber: el.phoneNumber ? el.phoneNumber : "-",
        zoneManager: `${el.zmFirstName ? el.zmFirstName : ""} ${
          el.zmLastName ? el.zmLastName : ""
        }`,
        isAj: el.isAj == 1 ? "Yes" : "No",
        city: el.city ? el.city : "-",
        state: el.state ? el.state : "-",
        leadsAddedByRecruiter: el.leadsAddedByRecruiter
          ? el.leadsAddedByRecruiter
          : "-",
        leadsAddedFromOkayGo: el.leadsAddedFromOkayGo
          ? el.leadsAddedFromOkayGo
          : "-",
        roleType: el.category ? el.category : "-",
      });
      return true;
    });

    return extractedData;
  };

  //Function to create All record export data
  const columns = transactionCols;
  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    if (filterUrl.length > 1) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.recruiter.dashboard}?page_no=${
          pageNo - 1
        }&rows=${rows}&${filterUrl}${searchUrl}`
      )
        .then((response) => {
          if (response) {
            setData(makeData(response.data.response.content));
            setTotalRows(response.data.response.content.totalElements);
            setTotalPages(response.data.response.content.totalPages);
            if (currentPage > response.data.response.content.totalPages)
              setCurrentPage(1);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getPaymentListing(perPage, 1);
    setCurrentPage(1);
  }, [filterUrl, sortUrl, localStorage.getItem("roleType"), tab, searchUrl]);

  const handlePageChange = (page) => {
    getPaymentListing(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [""] },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getPaymentListing(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const makeAllRecordData = (response) => {
    let extractedData = [];
    response.map((el) => {
      let temp = {
        recruiterId: el.recruiterId ? el.recruiterId : "-",
        name: el.name,
        phoneNumber: el.phoneNumber ? el.phoneNumber : "-",
        zoneManager: `${el.zmFirstName ? el.zmFirstName : ""} ${
          el.zmLastName ? el.zmLastName : ""
        }`,
        city: el.city ? el.city : "-",
        roleType: el.category ? el.category : "-",
        isAj: el.isAj == 1 ? "Yes" : "No",
        leadsAllocated: el.leadsAllocated ? el.leadsAllocated : "-",
        leadsAddedByRecruiter: el.leadsAddedByRecruiter
          ? el.leadsAddedByRecruiter
          : "-",
        leadsAddedFromOkayGo: el.leadsAddedFromOkayGo
          ? el.leadsAddedFromOkayGo
          : "-",
        totalInterview: el.totalInterview ? el.totalInterview : "-",
        totalUnderTraining: el.totalUnderTraining ? el.totalUnderTraining : "",
        totalJoined: el.totalJoined ? el.totalJoined : "-",
        totalClientIdGenerated: el.totalCasperGen ? el.totalCasperGen : "-",
      };
      extractedData.push(temp);
    });

    return extractedData;
  };

  const createAllRecordExcel = () => {
    setAllRecordLoading(true);

    Axios.get(
      `${urlLinks.baseUrl}${urlLinks.recruiter.dashboard}?${filterUrl}&isExport=1`
    )
      .then((response) => {
        console.log("response", response.data.response);
        setAllRecord(makeAllRecordData(response.data.response.content));
      })
      .catch((err) => console.log("error ->", err));
    setAllRecordLoading(false);
  };

  const handleInactive = () => {
    const indexes = Object.entries(selectedRowIds);
    let idList = [];

    indexes.map((id, index) => {
      idList.push(data[parseFloat(id[0])].recruiterId);
      return true;
    });
    Swal.fire({
      title: "Are you sure to inactive " + idList.length + " recruiter ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Inactive",
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.post(
          `${urlLinks.baseUrl}${
            urlLinks.recruiter.activeAndBlockAPI
          }?recruiter_id=${idList.toString()}&is_active=0`
        ).then((response) => {
          if (response) {
            updateMyData();
          }
        });
        Swal.fire("Inactivated", "", "success");
      }
    });
  };

  const handleOnHold = (blockValue, recId) => {
    blockValue = blockValue ? 1 : 0;
    Axios.post(
      `${urlLinks.baseUrl}${urlLinks.recruiter.activeAndBlockAPI}?recruiter_id=${recId}&is_ext=${blockValue}`
    )
      .then((response) => {
        if (response) {
          showToast(
            "success",
            `${blockValue ? "Blocked" : "Unblocked"} Successfully`
          );
          updateMyData();
        }
      })
      .catch((error) => {
        showToast("error", error.message);
      });
  };

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Worker Id"
                      ? "stickWorkerIdnewMatch zIndex1080 header"
                      : column.render("Header") === "Name"
                      ? `${
                          userAction.edit
                            ? "stickRecruiterName"
                            : "stickRecruiterNameV2"
                        } zIndex1080 header`
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header"
                      : column.render("Header") === "City"
                      ? "fix-width230 header"
                      : "header"
                  }
                >
                  <span>{column.render("Header")}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.Header === "Worker Id"
                            ? "stickWorkerIdnewMatch  zIndex540"
                            : cell.column.Header === "Name"
                            ? `${
                                userAction.edit
                                  ? "stickRecruiterName"
                                  : "stickRecruiterNameV2"
                              } zIndex540`
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12}>
          <Grid item xs={5}>
            <span className="fontSize24 fontWeightBold">Recruiter's Data</span>
          </Grid>
        </Grid>
        <Grid
          container
          className="flexDisplay"
          alignItems="flex-start"
          style={{ justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={5}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <TextField
              className={`${classes.root} marginLeft0`}
              placeholder="Search by Contact no."
              fullWidth
              value={searchBy}
              onChange={(event) => setSearchBy(event.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <div className={styles.searchButtonDiv}>
              <IconButton
                className={`${styles.closeButton} ${
                  searchBy ? "" : styles.noVisibility
                }`}
                onClick={handleSearchClose}
              >
                <CloseSharpIcon />
              </IconButton>
              <button onClick={handleSearch} className={styles.searchButton}>
                Search
              </button>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
            direction="row"
            alignItems="flex-start"
          >
            <div className="DateRange">
              Data from:{" "}
              <div className="inline">
                {" "}
                {moment(filters.billDate[1]).format("DD-MM-YYYY")}
              </div>
              to{" "}
              <div className="inline">
                {moment(filters.billDate[0]).format("DD-MM-YYYY")}
              </div>
            </div>
            <div>
              <DateFilter
                filters={filters}
                handleFilterDates={handleFilterDates}
              ></DateFilter>
            </div>
          </Grid>
          <Grid
            item
            xs={1}
            className="flexDisplay paddingTop8"
            direction="row"
            style={{ justifyContent: "end" }}
          >
            <div>
              <HelpSection></HelpSection>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className="flexDisplay"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ marginTop: "20px" }}
        >
          <div>
            {!!userAction.edit && (
              <Button
                className="dataButton"
                style={{
                  fontSize: "16px",
                  padding: "5px 15px",
                  marginRight: "20px",
                }}
                onClick={handleInactive}
                disabled={Object.keys(selectedRowIds).length === 0}
              >
                Inactive
              </Button>
            )}
            {!!userAction.export_access && (
              <Button
                variant="contained"
                className="dataButton"
                onClick={createAllRecordExcel}
                disabled={loading}
              >
                Export to Excel
              </Button>
            )}
            {allRecordLoading ? (
              <span style={{ marginLeft: "20px" }}>
                Please wait ... it might take few minutes.{" "}
              </span>
            ) : allRecord.length > 0 ? (
              <CSVLink
                data={allRecord}
                filename={`Recruiter data.csv`}
                className="btn btn-primary blueColor downloadExcelButton"
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                  marginLeft: "20px",
                  fontWeight: "500",
                }}
              >
                Download now
              </CSVLink>
            ) : null}
          </div>
          <Grid item xs={3} className="flexDisplay" justifyContent="flex-end">
            <button
              className={styles.blueOutlinedButton}
              style={{ padding: "10px 16px", marginLeft: "20px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              filters
            </button>
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => {
            setOpenFilterDrawer(false);
          }}
        >
          <FilterDrawer
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            filterUrl={filterUrl}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
          ></FilterDrawer>
        </Drawer>
      </Grid>
    </Styles>
  );
};

export default ExternalWorker;
