/* eslint-disable eqeqeq */

import "./App.css";
// import WorkerDb from "./containers/WorkerDB";
// import WorkerView from "./containers/WorkerView";
import WorkerViewV2 from "./containers/WorkerData/WorkerView";
import LeadWorkerView from "./containers/WorkerData/LeadWorkerView";
import PaymentListingV2 from "./containers/PaymentListingV2";
import "react-toastify/dist/ReactToastify.css";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./services/AuthenticatedRoute";
import AuthProvider, { AuthContext } from "./services/AuthProvider";
import Login from "./containers/Login";
import Register from "./containers/Register";
import RegistrationSuccess from "./containers/Register/RegistrationSuccess";
import BankVerificationListing from "./containers/BankDetailsVerificationListing";
import PennyTestingVerification from "./containers/PennyTestingVerification";
import ChangePassword from "./containers/Settings/ChangePassword";
import Jobsdb from "./containers/JobsDB";
import ForgotPassword from "./containers/Settings/ForgotPassword";
import WorkerDataV3 from "./containers/WorkerDataV3";
import ShiftData from "./containers/ShiftData";
import EmployerData from "./containers/EmployerData/index";
import HubListing from "./containers/HubListing/index";
import AddCompany from "./containers/EmployerData/AddCompany/index";
import AddProject from "./containers/ProjectConfig/AddProject/index";
import AddMpa from "./containers/MpaConfig/AddMpa/index";
import TatConfig from "./containers/ProjectConfig/TatConfig/index";
import Axios from "axios";
import Referral from "./containers/Referral";
// import HubLocationV2 from "./containers/HubLocationV2/HubLocation";
import RecuiterDashboard from "./containers/RecruiterDashboard/index";
// import WorkerLedger from "./containers/WorkerLedger";
// import ParticularLedger from "./containers/ParticularLedger";
import DocumentVerification from "./containers/DocumentVerification";
import VerificationDetails from "./containers/DocumentVerification/VerificationDetails";
import UrlLinks from "./UrlLinks";
import ProjectConfig from "./containers/ProjectConfig";
import AttendanceManagement from "./containers/AttendanceManagement";
import DriveDatabase from "./containers/DriveDatabase";
import MpaConfig from "./containers/MpaConfig";
import WiproReport from "./containers/WiproReport";
import TreeboReport from "./containers/TreeboReport";
import AmazonReport from "./containers/AmazonReport";
import ClientReport from "./containers/ClientReport";
import Context from "./context/context";
import JobConfigForm from "./containers/JobsDB/JobConfigForm";
import EligibilityCriteriaForm from "./containers/JobsDB/EligibilityCriteriaForm";
import ApplicationDb from "./containers/ApplicationDb";
import CampaignTracker from "./containers/CampaignTracker";
import Questions from "./containers/JobsDB/JobConfigForm/jobQuestions/Questions";
import AccessPanel from "./containers/AccessPanel";

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const { initSession, logout, showToast } = useContext(AuthContext);
  const [defaultRoute, setDefaultRoute] = useState("/");

  /** Map page IDs to their respective components */
  const pageComponentMap = [
    {
      id: 1.1,
      file: AccessPanel,
    },
    {
      id: 11,
      file: EmployerData,
    },
    {
      id: 7,
      file: MpaConfig,
    },
    {
      id: 1,
      file: ProjectConfig,
    },
    {
      id: 12,
      file: HubListing,
    },
    {
      id: 4,
      file: Jobsdb,
    },
    {
      id: 3,
      file: ApplicationDb,
    },
    {
      id: 2,
      file: WorkerDataV3,
    },
    {
      id: 5,
      file: PaymentListingV2,
    },
    {
      id: 6,
      file: DocumentVerification,
    },
    {
      id: 8,
      file: ShiftData,
    },
    {
      id: 9,
      file: RecuiterDashboard,
    },
    {
      id: 13,
      file: Referral,
    },
    {
      id: 1.2,
      file: ChangePassword,
    },
  ];

  Axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("accessToken");

  // Function to get the default component based on first pageId
  const getDefaultComponent = () => {
    const pages = JSON.parse(localStorage.getItem("pages")) || [];

    const firstPage = pageComponentMap.filter((item) => {
      if (item.id === 1.1 && localStorage.getItem("roleType") === "6")
        return true;
      if (item.id === 1.2) return true;
      return (
        pages.length > 0 &&
        pages.some((singlePage) => {
          return singlePage.Id == item.id;
        })
      );
    });

    if (firstPage) {
      return JSON.parse(localStorage.getItem("userDesignation"))
        ?.designation_key === "50"
        ? firstPage[1]?.file || ChangePassword
        : firstPage[0]?.file || ChangePassword;
    }

    return ChangePassword; // Default fallback component
  };

  useEffect(() => {
    initSession().then(() => setInitialized(true));
  }, [initSession]);

  useEffect(() => {
    const verifyUsername = () => {
      Axios.post(`${UrlLinks.baseUrl}${UrlLinks.checkAdminUserNameV2}`, {
        user_name: localStorage.getItem("userName"),
      })
        .then((response) => {
          console.log("Authorised user");
          const storedRoute = localStorage.getItem("defaultRoute") || "/";
          setDefaultRoute(storedRoute);
        })
        .catch((err) => {
          if (err.response?.status === 404) {
            logout();
          }
        });
    };
    verifyUsername();
  }, []);

  Axios.interceptors.response.use(
    (response) => {
      if (response?.data?.code === 1005) {
        logout();
        showToast("error", "Your session has expired. Please login again");
      } else {
        return response;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    initialized && (
      <BrowserRouter>
        <Switch>
          <AuthenticatedRoute
            exact
            path="/"
            component={getDefaultComponent()}
          />
          <Redirect exact from="/" to={defaultRoute} /> {/* Dynamic Redirect */}
          <Route exact path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route
            exact
            path="/registrationsuccess"
            component={RegistrationSuccess}
          />
          <Route
            exact
            path="/resetpassword/:username"
            component={ForgotPassword}
          />
          <AuthenticatedRoute
            exact
            path="/changepassword"
            component={ChangePassword}
          />
          {localStorage.getItem("roleType") === "6" && (
            <AuthenticatedRoute
              exact
              path="/accessPanel"
              component={AccessPanel}
            />
          )}
          <AuthenticatedRoute
            exact
            path="/workerview/v2/:id"
            component={WorkerViewV2}
          />
          <AuthenticatedRoute
            exact
            path="/leadWorkerview/v2/:id"
            component={LeadWorkerView}
          />
          <AuthenticatedRoute
            exact
            path="/employer-data"
            component={EmployerData}
          />
          <AuthenticatedRoute
            exact
            path="/employer-data/company"
            component={AddCompany}
          />
          <AuthenticatedRoute exact path="/mpaConfig" component={MpaConfig} />
          <AuthenticatedRoute path="/mpaConfig/mpa/:mpaId" component={AddMpa} />
          {JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key !== "50" && (
            <AuthenticatedRoute
              exact
              path="/projectConfig"
              component={ProjectConfig}
            />
          )}
          {JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key !== "50" && (
            <AuthenticatedRoute
              path="/projectConfig/project/:projectId"
              component={AddProject}
            />
          )}
          {JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key !== "50" && (
            <AuthenticatedRoute
              path="/tatConfig/project/:projectId"
              component={TatConfig}
            />
          )}
          {JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key !== "50" && (
            <AuthenticatedRoute
              exact
              path="/attendance"
              component={AttendanceManagement}
            />
          )}
          {JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key !== "50" && (
            <AuthenticatedRoute
              exact
              path="/driveDatabase"
              component={DriveDatabase}
            />
          )}
          <AuthenticatedRoute
            exact
            path="/hub-listing"
            component={HubListing}
          />
          <AuthenticatedRoute exact path="/jobsdb" component={Jobsdb} />
          <AuthenticatedRoute
            path="/jobsdb/jobConfig/:jobId"
            component={JobConfigForm}
          />
          <AuthenticatedRoute
            path="/jobsdb/screening/:jobId"
            component={Questions}
          />
          <AuthenticatedRoute
            path="/jobsdb/eligibilityCriteria/:jobId"
            component={EligibilityCriteriaForm}
          />
          <AuthenticatedRoute
            exact
            path="/campaignTracker"
            component={CampaignTracker}
          />
          <AuthenticatedRoute
            exact
            path="/applicationDb"
            component={ApplicationDb}
          />
          <AuthenticatedRoute
            exact
            path="/workerdata"
            component={WorkerDataV3}
          />
          <AuthenticatedRoute
            exact
            path="/paymentslistingV2"
            component={PaymentListingV2}
          />
          <AuthenticatedRoute
            exact
            path="/document-verification"
            component={DocumentVerification}
          />
          <AuthenticatedRoute
            exact
            path="/verificationdetails/:rowData/:fn/:bucket"
            component={VerificationDetails}
          />
          <AuthenticatedRoute
            exact
            path="/bankverificationlisting"
            component={BankVerificationListing}
          />
          <AuthenticatedRoute
            exact
            path="/pennyTestingverification/:userId/:pennyStatus"
            component={PennyTestingVerification}
          />
          <AuthenticatedRoute
            exact
            path="/shiftdata"
            component={ShiftData}
            pageId={8}
          />
          <AuthenticatedRoute
            exact
            path="/recruiter-data"
            component={RecuiterDashboard}
          />
          <AuthenticatedRoute exact path="/referral" component={Referral} />
          <AuthenticatedRoute
            exact
            path="/wiproReports"
            component={WiproReport}
          />
          <AuthenticatedRoute
            exact
            path="/treeboReports"
            component={TreeboReport}
          />
          <AuthenticatedRoute
            exact
            path="/amazonReports"
            component={AmazonReport}
          />
          <AuthenticatedRoute
            exact
            path="/clientReports"
            component={ClientReport}
          />
          {/* <AuthenticatedRoute
            exact
            path="/particularLedger/:okaygoId"
            component={ParticularLedger}
          /> */}
          {/* <AuthenticatedRoute
            exact
            path="/workerview/:id"
            component={WorkerView}
          /> */}
          {/* <Route
            exact
            path="/v2/register-hub-location"
            component={HubLocationV2}
          /> */}
          {/* <AuthenticatedRoute exact path="/workerDb" component={WorkerDb} /> */}
          {/* <AuthenticatedRoute
            exact
            path="/workerLedger"
            component={WorkerLedger}
          /> */}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  );
};

const appWithAuthentication = () => (
  <Context>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Context>
);

export default appWithAuthentication;
