import React from "react";
import { Grid } from "@material-ui/core";

function PersonalDetails(props) {
  const { data, documentList } = props;
  const makeClients = () => {
    let str = "";
    data.facilityData.map((el) => {
      if (el.clientName) str += el.clientName + ",";
    });
    if (str.length > 0) {
      return str.substring(0, str.length - 1);
    } else return "";
  };
  let clients = makeClients();
  const makePeresntAdd = () => {
    let str = "";
    if (data.present_address) {
      str += data.present_address + ",";
    }
    if (data.present_city) {
      str += data.present_city + ",";
    }
    if (data.present_state) {
      str += data.present_state + ",";
    }
    if (str.length > 0) {
      return str.substring(0, str.length - 1);
    } else return "";
  };
  let presentAdd = makePeresntAdd();

  const makePermanentAdd = () => {
    let str = "";
    if (data.permanent_address) {
      str += data.permanent_address + ",";
    }
    if (data.permanent_city) {
      str += data.permanent_city + ",";
    }
    if (data.permanent_state) {
      str += data.permanent_state + ",";
    }
    if (str.length > 0) {
      return str.substring(0, str.length - 1);
    } else return "";
  };
  let permanenrtAdd = makePermanentAdd();

  return (
    <Grid container className="flexDisplay" direction="column">
      <Grid item xs={12} className="marginBottom40">
        <span className="fontSize1_5rem fontWeightBold">Personal Details</span>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Full name</span>
        </Grid>
        <Grid item xs={8}>
          <span>
            {data.firstName} {data.lastName}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Phone number</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.contact}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Date of birth</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.dob}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Father's name</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.fathersName}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Gender</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.gender}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Location</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.userGoogleLocation}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay  height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Employment status</span>
        </Grid>
        <Grid item xs={8}>
          <span>
            {(data.facilityName !== null && data.facilityName !== 0) ||
            (data.swiggyWorkerStatus !== null &&
              data.swiggyWorkerStatus !== 0) ||
            (data.zomatoWorkerStatus !== null &&
              data.zomatoWorkerStatus !== 0) ? (
              <div>Employed</div>
            ) : (
              <div>Unemployed</div>
            )}
          </span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay  height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Client(s)</span>
        </Grid>
        <Grid item xs={8}>
          <span>{clients}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay  height40" direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Source</span>
        </Grid>
        <Grid item xs={8}>
          <span>{data.partnerName}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay " direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Present Address</span>
        </Grid>
        <Grid item xs={8} className="paddingBottom16">
          <span>{presentAdd}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className="flexDisplay " direction="row">
        <Grid item xs={4}>
          <span className="fontWeightBold">Permanent Address</span>
        </Grid>
        <Grid item xs={8} className="paddingBottom16">
          <span>{permanenrtAdd}</span>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PersonalDetails;
