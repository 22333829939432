import React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/actions/index";
import { bindActionCreators } from "redux";

const ExportButton = (props) => {
  const {
    uniqueKey,
    createAllRecordExcel,
    allRecordLoading,
    filename,
    buttonName,
    loadingMessage,
    downloadButton,
    handleOpenExportModal,
    exportDrive,
  } = props;

  const { users } = useSelector((state) => state);
  const { allRecord } = users;

  const dispatch = useDispatch();
  const { setAllRecord } = bindActionCreators(actionCreators, dispatch);

  return (
    <div
      className={
        uniqueKey === "paymentListingInvoice" ||
        uniqueKey === "paymentListingTransaction"
          ? "marginRight20"
          : ""
      }
      style={{
        display: "flex",
        flexDirection:
          uniqueKey === "paymentListingTransaction" ? "row" : "column",
        alignItems:
          uniqueKey === "paymentListingInvoice" && allRecordLoading
            ? "flex-start"
            : "center",
        marginTop:
          uniqueKey === "AttendanceConfig" &&
          (allRecordLoading || allRecord.length > 0)
            ? "30px"
            : uniqueKey === "accessPanel" &&
              (allRecordLoading || allRecord.length > 0)
            ? "27px"
            : "",
      }}
    >
      <Button
        variant={
          uniqueKey === "driveConfig" || uniqueKey === "accessPanel"
            ? "outlined"
            : "contained"
        }
        className={
          uniqueKey === "driveConfig" || uniqueKey === "accessPanel"
            ? "dataButtonOutline2"
            : uniqueKey === "paymentListingInvoice"
            ? "dataButton2 widthFitContent"
            : uniqueKey === "paymentListingTransaction"
            ? "dataButton2 marginRight20 widthFitContent"
            : "dataButton"
        }
        onClick={
          uniqueKey === "workerData" || uniqueKey === "driveConfig"
            ? handleOpenExportModal
            : createAllRecordExcel
        }
        disabled={uniqueKey === "driveConfig" ? exportDrive : allRecordLoading}
      >
        {buttonName}
      </Button>
      {allRecordLoading ? (
        <div style={{ marginTop: "12px" }}>{loadingMessage}</div>
      ) : allRecord.length > 0 ? (
        <CSVLink
          data={allRecord}
          filename={filename}
          onClick={() => setAllRecord([])}
          className="blueColor downloadExcelButton"
          target="_blank"
          style={{
            color: "inherit",
            textDecoration: "none",
            marginTop: uniqueKey === "accessPanel" ? "7px" : "10px",
            fontWeight: "500",
          }}
        >
          {downloadButton}
        </CSVLink>
      ) : null}
    </div>
  );
};

export default ExportButton;
