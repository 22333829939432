/* eslint-disable eqeqeq */

import React, { useEffect, useState } from "react";
import styles from "./Dialogs.module.scss";
import { Close } from "@material-ui/icons";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import axios from "axios";
import UrlLinks from "../../UrlLinks";
import AutoSelectFilter from "../FilterComponents/AutoSelectFilter";
import { FormControl, TextField } from "@mui/material";
import AddIcon from "../../containers/Assets/Add.svg";
import AddIconDisabled from "../../containers/Assets/AddDisabled.svg";
import MultiSelectCheckFilter from "../FilterComponents/MultiSelectCheckFilter";
// import { bindActionCreators } from "redux";
// import * as actionCreators from "../../store/actions/index";
import EditIcon from "@mui/icons-material/Edit";
import SearchSelectFilter from "../FilterComponents/SearchSelectFilter";
import { makeStyles } from "@material-ui/styles";
import { useDebounce } from "use-debounce";

const usestyles = makeStyles({
  textComponent: {
    "& .MuiInputBase-root.Mui-disabled": {
      background: "#F6F6F6",
    },
  },
});

export default function UserMember(props) {
  const {
    uniqueKey,
    open,
    setOpen,
    title,
    filters,
    setFilters,
    showToast,
    updateMyData,
    dataList,
    isEdit,
    setIsEdit,
    roleList,
    dashboardPagesList,
    actionAddMemberList,
    manageById,
    setManageById,
    actionButton,
  } = props;

  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  /** useDispatch is used to update states to redux store **/
  // const dispatch = useDispatch();
  // const { setModalLoader } = bindActionCreators(actionCreators, dispatch);

  const classes = usestyles();
  const [searchTextBy, setSearchTextBy] = useState({ type: "", text: "" });
  const [debouncedText] = useDebounce(searchTextBy.text, 350);
  const [disableButton, setDisableButton] = useState(true);
  const [roleListInternal, setRoleListInternal] = useState([]);
  const [isPagesChanged, setIsPagesChanged] = useState(false);
  const [validationErr, setValidationErr] = useState({
    email: false,
    phoneNumber: false,
  });
  const [validationMessage, setValidationMessage] = useState({
    emailError: "",
    phoneNumberError: "",
  });

  let debounceLoading = false;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 888,
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
    borderRadius: "5px",
  };

  const emailPattern =
    /^(?!.*@betterplace\.co\.in$)[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const emailPatternBp = /^[A-Z0-9._%+-]+@betterplace\.co\.in$/i;

  const handleClose = () => {
    setFilters((prev) => ({
      ...prev,
      action_addMember: ["View"],
      dashboardPages_addMember: [],
      email_addMember: "",
      firstName_addMember: "",
      lastName_addMember: "",
      managedBy_addMember: "",
      phoneNo_addMember: "",
      role_addMember: "",
    }));
    setManageById("");
    setIsEdit(false);
    setOpen(false);
  };

  const checkValidInput = async (url, type, searchType) => {
    if (debouncedText) {
      debounceLoading = true;
      await axios
        .get(`${url}${type !== "roleChange" ? debouncedText : ""}`)
        .then((response) => {
          let user_exist = response.data.response.user_exist;
          if (
            type !== "roleChange" &&
            searchTextBy.type === "email" &&
            filters.role_addMember.id != 50 &&
            !emailPatternBp.test(debouncedText) &&
            debouncedText !== ""
          ) {
            setValidationErr({
              ...validationErr,
              email: true,
            });
            setValidationMessage((prev) => ({
              ...prev,
              emailError: `*Enter valid Email ID`,
            }));
          } else if (
            type !== "roleChange" &&
            searchTextBy.type === "email" &&
            filters.role_addMember.id == 50 &&
            !emailPattern.test(debouncedText) &&
            debouncedText !== ""
          ) {
            setValidationErr({
              ...validationErr,
              email: true,
            });
            setValidationMessage((prev) => ({
              ...prev,
              emailError: `*Enter valid Email ID`,
            }));
          } else if (
            type !== "roleChange" &&
            searchTextBy.type === "phoneNumber" &&
            debouncedText !== "" &&
            debouncedText.length < 10
          ) {
            setValidationErr({
              ...validationErr,
              phoneNumber: true,
            });
            setValidationMessage((prev) => ({
              ...prev,
              phoneNumberError: `*Enter valid Phone Number`,
            }));
          } else {
            if (type === "roleChange") {
              let errFlag = false;
              let errMessage = "";

              if (!!user_exist) {
                errFlag = true;
                errMessage =
                  searchType === "phoneNumber"
                    ? "*Phone Number already exist"
                    : "*Email ID already exist";
              } else {
                if (searchType === "phoneNumber") {
                  if (filters.phoneNo_addMember.length < 10) {
                    errFlag = true;
                    errMessage = "*Enter valid Phone Number";
                  } else {
                    errFlag = false;
                    errMessage = "";
                  }
                } else {
                  if (
                    (filters.role_addMember.id == 50 &&
                      !emailPattern.test(filters.email_addMember)) ||
                    (filters.role_addMember.id != 50 &&
                      !emailPatternBp.test(filters.email_addMember))
                  ) {
                    errFlag = true;
                    errMessage = "*Enter valid Email ID";
                  } else {
                    errFlag = false;
                    errMessage = "";
                  }
                }
              }

              setValidationErr({
                ...validationErr,
                email: searchType === "email" ? errFlag : false,
                phoneNumber: searchType === "phoneNumber" ? errFlag : false,
              });

              setValidationMessage((prev) => ({
                ...prev,
                emailError: searchType === "email" ? errMessage : "",
                phoneNumberError:
                  searchType === "phoneNumber" ? errMessage : "",
              }));
            } else {
              setValidationErr({
                ...validationErr,
                [searchTextBy.type]: !!user_exist,
              });
              setValidationMessage((prev) => ({
                ...prev,
                emailError: `${
                  searchTextBy.type === "email" && !!user_exist
                    ? "*Email ID already exist"
                    : ""
                }`,
                phoneNumberError: `${
                  searchTextBy.type === "phoneNumber" && !!user_exist
                    ? "*Phone Number already exist"
                    : ""
                }`,
              }));
            }
          }
        })
        .catch((err) => console.log(err));

      debounceLoading = false;
    }
  };

  const handleInputChange = (e, type) => {
    e.preventDefault();
    const phonePattern = /^\d{10}$/;
    const namePattern = /^[A-Za-z\s]*$/;
    let inputValue = e.target.value;

    switch (type) {
      case 1:
        if (namePattern.test(inputValue)) {
          setFilters((prev) => ({ ...prev, firstName_addMember: inputValue }));
        }
        break;
      case 2:
        if (namePattern.test(inputValue)) {
          setFilters((prev) => ({ ...prev, lastName_addMember: inputValue }));
        }
        break;
      case 3:
        inputValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
        if (phonePattern.test(inputValue) || inputValue.length < 10) {
          setSearchTextBy({ type: "phoneNumber", text: inputValue });
          setFilters((prev) => ({
            ...prev,
            phoneNo_addMember: inputValue,
          }));
        }
        break;
      case 4:
        setSearchTextBy({ type: "email", text: inputValue });
        setFilters((prev) => ({ ...prev, email_addMember: inputValue }));
        break;
      default:
        break;
    }
  };

  const handleClick = async (e, type) => {
    let mainUrl = "";
    let payload = null;
    switch (type) {
      case 1: {
        payload = {
          addDeleteAction:
            filters.action_addMember?.indexOf("Approve/ Reject") < 0 ||
            filters.role_addMember.id == 20
              ? 0
              : 1,
          editAction:
            filters.action_addMember?.indexOf("Edit") < 0 ||
            filters.role_addMember.id == 20
              ? 0
              : 1,
          emailId:
            filters.role_addMember.id != 20 ? filters.email_addMember : "",
          exportAction:
            filters.action_addMember?.indexOf("Export") < 0 ||
            filters.role_addMember.id == 20
              ? 0
              : 1,
          firstName: filters.firstName_addMember,
          lastName: filters.lastName_addMember,
          manageBy: filters.managedBy_addMember,
          pageName:
            filters.role_addMember.id != 20
              ? filters.dashboardPages_addMember
              : [],
          phoneNumber:
            filters.role_addMember.id == 20 ? filters.phoneNo_addMember : "",
          role: filters.role_addMember.id,
          viewAction:
            filters.action_addMember?.indexOf("View") < 0 ||
            filters.role_addMember.id == 20
              ? 0
              : 1,
        };

        mainUrl = `${UrlLinks.baseUrl}${
          UrlLinks.accessPanelUrls.addMemberForRoleAcess
        }?addDeleteAction=${payload.addDeleteAction}&editAction=${
          payload.editAction
        }${
          payload.emailId ? `&emailId=${payload.emailId}` : ""
        }&export_access=${payload.exportAction}&firstName=${
          payload.firstName
        }&lastName=${payload.lastName}&manageBy=${payload.manageBy}${
          payload.pageName?.length > 0 ? `&pageName=${payload.pageName}` : ""
        }${
          payload.phoneNumber ? `&phoneNumber=${payload.phoneNumber}` : ""
        }&role=${payload.role}&viewAction=${payload.viewAction}`;

        await axios
          .post(`${mainUrl}`)
          .then((res) => {
            showToast("success", `Created successfully`);
            handleClose();
            updateMyData();
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        break;
      }
      case 2: {
        let selectedPages = dashboardPagesList
          .filter((page) => {
            return (
              filters.dashboardPages_addMember.length > 0 &&
              filters.dashboardPages_addMember?.some((dPage) => {
                if (dPage === page.label) {
                  return page.id;
                }
              })
            );
          })
          .map((pageObj) => +pageObj.id);

        payload = {
          addDeleteAction:
            filters.role_addMember.id != 20
              ? filters.action_addMember?.indexOf("Approve/ Reject") < 0
                ? 0
                : 1
              : 0,
          editAction:
            filters.role_addMember.id != 20
              ? filters.action_addMember?.indexOf("Edit") < 0
                ? 0
                : 1
              : 0,
          emailId:
            filters.email_addMember && filters.role_addMember?.id != 20
              ? filters.email_addMember
              : null,
          exportAction:
            filters.role_addMember.id != 20
              ? filters.action_addMember?.indexOf("Export") < 0
                ? 0
                : 1
              : 0,
          manageBy: filters.managedBy_addMember,
          pageName:
            filters.role_addMember.id != 20
              ? !isPagesChanged
                ? selectedPages
                : filters.dashboardPages_addMember?.map((e) => +e)
              : [],
          phoneNumber:
            filters.phoneNo_addMember && filters.role_addMember?.id == 20
              ? filters.phoneNo_addMember
              : null,
          role: filters.role_addMember.id,
          viewAction:
            filters.role_addMember.id != 20
              ? filters.action_addMember?.indexOf("View") < 0
                ? 0
                : 1
              : 0,
          userId: dataList.userId,
        };

        mainUrl = `${UrlLinks.baseUrl}${
          UrlLinks.accessPanelUrls.UpdateMemberForRoleAcess
        }?userId=${payload.userId}&addDeleteAction=${
          payload.addDeleteAction
        }&editAction=${payload.editAction}${
          payload.emailId ? `&emailId=${payload.emailId}` : ""
        }&export_access=${payload.exportAction}&manageBy=${payload.manageBy}${
          payload.pageName ? `&pageName=${payload.pageName}` : ""
        }${
          payload.phoneNumber ? `&phoneNumber=${payload.phoneNumber}` : ""
        }&role=${payload.role}&viewAction=${payload.viewAction}`;

        await axios
          .post(`${mainUrl}`)
          .then((res) => {
            showToast("success", `Updated successfully`);
            handleClose();
            updateMyData();
          })
          .catch((err) => {
            console.log("err", err);
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isPagesChanged && isEdit) {
      setIsPagesChanged(true);
    }
  }, [filters.dashboardPages_addMember]);

  useEffect(() => {
    let checkValidInputUrl = `${UrlLinks.baseUrl}${UrlLinks.accessPanelUrls.validateUser}`;

    if (searchTextBy.type === "email") {
      checkValidInputUrl = `${checkValidInputUrl}?email=`;
    } else if (searchTextBy.type === "phoneNumber") {
      checkValidInputUrl = `${checkValidInputUrl}?phoneNumber=`;
    }

    let fetchValidation = async () => {
      try {
        await checkValidInput(
          checkValidInputUrl,
          "textBoxSearch",
          searchTextBy.type
        );
      } catch (error) {
        console.error("Error validating input:", error);
      }
    };
    fetchValidation();
  }, [debouncedText]);

  useEffect(() => {
    let newList = [];
    if (
      uniqueKey === "viewMember" &&
      dataList &&
      (dataList.designation_key != 20 || dataList.designation_key != 50)
    ) {
      roleList.map((role) => {
        if (role.id != 20 && role.id != 50) {
          newList.push(role);
        }
        return true;
      });
      setRoleListInternal(newList);
    }
  }, [dataList]);

  useEffect(() => {
    /* validating disable button functionality with respect to the change in input values */
    if (uniqueKey === "addMember" && filters.role_addMember) {
      if (
        filters.role_addMember.id == 20 &&
        filters.firstName_addMember &&
        filters.lastName_addMember &&
        filters.phoneNo_addMember &&
        filters.managedBy_addMember
      ) {
        setDisableButton(false);
      } else if (
        filters.role_addMember.id != 20 &&
        filters.firstName_addMember &&
        filters.lastName_addMember &&
        filters.email_addMember &&
        filters.action_addMember?.length > 0 &&
        filters.managedBy_addMember &&
        filters.dashboardPages_addMember?.length > 0
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    } else if (uniqueKey === "addMember" && !filters.role_addMember) {
      setDisableButton(true);
    } else if (uniqueKey === "viewMember" && filters.role_addMember && isEdit) {
      if (filters.role_addMember.id == 20 && filters.managedBy_addMember) {
        setDisableButton(false);
      } else if (
        filters.action_addMember?.length > 0 &&
        filters.managedBy_addMember &&
        filters.dashboardPages_addMember?.length > 0
      ) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    }
  }, [
    filters.role_addMember,
    filters.firstName_addMember,
    filters.lastName_addMember,
    filters.phoneNo_addMember,
    filters.action_addMember,
    filters.managedBy_addMember,
    filters.email_addMember,
    filters.dashboardPages_addMember,
  ]);

  useEffect(() => {
    if (uniqueKey === "viewMember") return;
    if (!filters.role_addMember) {
      setValidationErr({
        ...validationErr,
        email: false,
        phoneNumber: false,
      });
      setValidationMessage((prev) => ({
        ...prev,
        emailError: "",
        phoneNumberError: "",
      }));
    } else if (filters.role_addMember && filters.role_addMember?.id == 20) {
      if (filters.phoneNo_addMember) {
        let checkValidInputUrl = `${UrlLinks.baseUrl}${UrlLinks.accessPanelUrls.validateUser}?phoneNumber=${filters.phoneNo_addMember}`;

        let fetchValidation = async () => {
          try {
            await checkValidInput(
              checkValidInputUrl,
              "roleChange",
              "phoneNumber"
            );
          } catch (error) {
            console.error("Error validating input:", error);
          }
        };
        fetchValidation();
      }
    } else if (filters.role_addMember && filters.role_addMember?.id != 20) {
      if (filters.email_addMember) {
        let checkValidInputUrl = `${UrlLinks.baseUrl}${UrlLinks.accessPanelUrls.validateUser}?email=${filters.email_addMember}`;

        let fetchValidation = async () => {
          try {
            await checkValidInput(checkValidInputUrl, "roleChange", "email");
          } catch (error) {
            console.error("Error validating input:", error);
          }
        };
        fetchValidation();
      }
    }
  }, [filters.role_addMember]);

  const loaderBar = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "80px 0",
      }}
    >
      <ScaleLoader height={20} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Modal
      open={open}
      className={styles.userMemberModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      {
        <Box sx={style} className={styles.userMemberBox}>
          <DialogTitle disableTypography className={styles.header}>
            <span>{title}</span>
            <span style={{ display: "flex" }}>
              {uniqueKey === "viewMember" && !isEdit && (
                <button
                  className={styles.editBtn}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <EditIcon />
                  <span>Edit</span>
                </button>
              )}
              <IconButton onClick={handleClose} className={styles.closeButton}>
                <Close />
              </IconButton>
            </span>
          </DialogTitle>
          {modalLoader ? (
            loaderBar
          ) : (
            <DialogContent className={styles.userBox}>
              <Grid container className={styles.outerGrid}>
                <Grid item xs={6}>
                  <AutoSelectFilter
                    filters={filters}
                    setFilters={setFilters}
                    multipleFlag={false}
                    id="role_addMember"
                    options={
                      uniqueKey === "viewMember" &&
                      dataList &&
                      dataList.designation_key != 20 &&
                      dataList.designation_key != 50
                        ? roleListInternal
                        : roleList
                    }
                    placeholder="Select Role"
                    uniqueKey={uniqueKey}
                    disabled={
                      uniqueKey === "viewMember" &&
                      (!isEdit ||
                        (isEdit &&
                          (filters.role_addMember?.id == 50 ||
                            filters.role_addMember?.id == 20)))
                        ? true
                        : false
                    }
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={styles.innerGrid}>
                <Grid item xs={6} className={styles.leftInnerGrid}>
                  <div>Name</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p>{`${filters.firstName_addMember} ${filters.lastName_addMember}`}</p>
                  ) : (
                    <FormControl
                      variant="outlined"
                      className={styles.formControl}
                    >
                      <TextField
                        id="outlined-adornment-weight"
                        className={`${styles.textComponentHalf} ${classes.textComponent}`}
                        placeholder="First Name"
                        value={filters.firstName_addMember}
                        onChange={(e) => {
                          handleInputChange(e, 1);
                        }}
                        disabled={
                          uniqueKey === "addMember" && filters.role_addMember
                            ? false
                            : true
                        }
                      />
                      <TextField
                        id="outlined-adornment-weight"
                        className={`${styles.textComponentHalf} ${classes.textComponent}`}
                        placeholder="Last Name"
                        value={filters.lastName_addMember}
                        onChange={(e) => {
                          handleInputChange(e, 2);
                        }}
                        disabled={
                          uniqueKey === "addMember" && filters.role_addMember
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={
                    uniqueKey === "viewMember" && !isEdit
                      ? styles.rightViewInnerGrid
                      : styles.rightInnerGrid
                  }
                >
                  <div>Phone Number</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p style={{ width: "95%" }}>{`${
                      filters.phoneNo_addMember || "-"
                    }`}</p>
                  ) : (
                    <FormControl
                      variant="outlined"
                      className={styles.formControl}
                    >
                      <TextField
                        id="outlined-adornment-weight"
                        className={`${styles.textComponent} ${classes.textComponent}`}
                        placeholder="Enter Phone Number"
                        value={filters.phoneNo_addMember}
                        onChange={(e) => {
                          handleInputChange(e, 3);
                        }}
                        disabled={
                          (!isEdit &&
                            filters.role_addMember &&
                            filters.role_addMember?.id == 20) ||
                          (isEdit &&
                            filters.role_addMember &&
                            filters.role_addMember?.id == 20 &&
                            (dataList.phoneNumber === "" ||
                              !dataList.phoneNumber))
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  )}
                  {validationErr.phoneNumber && (
                    <FormHelperText
                      style={{ color: "red", fontSize: "12px", width: "95%" }}
                      id="component-error-text"
                    >
                      {validationMessage.phoneNumberError}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Grid container className={styles.innerGrid}>
                <Grid item xs={6} className={styles.leftInnerGrid}>
                  <div>Email ID</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p>{`${filters.email_addMember || "-"}`}</p>
                  ) : (
                    <FormControl
                      variant="outlined"
                      className={styles.formControl}
                    >
                      <TextField
                        id="outlined-adornment-weight"
                        className={`${styles.textComponent} ${classes.textComponent}`}
                        placeholder="Enter Email ID"
                        value={filters.email_addMember}
                        onChange={(e) => {
                          handleInputChange(e, 4);
                        }}
                        disabled={
                          (!isEdit &&
                            filters.role_addMember &&
                            filters.role_addMember?.id != 20) ||
                          (isEdit &&
                            filters.role_addMember &&
                            filters.role_addMember?.id != 20 &&
                            (dataList.emailId === "" || !dataList.emailId))
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  )}
                  {validationErr.email && (
                    <FormHelperText
                      style={{ color: "red", fontSize: "12px" }}
                      id="component-error-text"
                    >
                      {validationMessage.emailError}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={
                    uniqueKey === "viewMember" && !isEdit
                      ? styles.rightViewInnerGrid
                      : styles.rightInnerGrid
                  }
                >
                  <div>Action</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p style={{ width: "95%" }}>
                      {`${
                        filters.action_addMember &&
                        filters.action_addMember.length
                          ? filters.action_addMember
                          : "-"
                      }`}
                    </p>
                  ) : (
                    <MultiSelectCheckFilter
                      filters={filters}
                      setFilters={setFilters}
                      multipleFlag={true}
                      id="action_addMember"
                      options={actionAddMemberList}
                      placeholder="Action"
                      uniqueKey={`action_${uniqueKey}`}
                      disabled={
                        filters.role_addMember &&
                        filters.role_addMember?.id != 20
                          ? false
                          : true
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container className={styles.innerGrid}>
                <Grid item xs={6} className={styles.leftInnerGrid}>
                  <div>Managed By</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p>{`${filters.managedBy_addMember}`}</p>
                  ) : (
                    <SearchSelectFilter
                      filters={filters}
                      setFilters={setFilters}
                      id="managedBy_addMember"
                      sourceId={manageById}
                      setSourceId={setManageById}
                      module={"managedByModalListing"}
                      multiple={false}
                      placeholder="Search and select"
                      url={`${UrlLinks.baseUrl}${UrlLinks.accessPanelUrls.getListOfManageEmailId}`}
                      disabled={filters.role_addMember ? false : true}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={
                    uniqueKey === "viewMember" && !isEdit
                      ? styles.rightViewInnerGrid
                      : styles.rightInnerGrid
                  }
                >
                  <div>Dashboard Pages</div>
                  {uniqueKey === "viewMember" && !isEdit ? (
                    <p style={{ width: "95%" }}>
                      {`${
                        dataList.page_names && dataList.page_names.length
                          ? dataList.page_names
                          : "-"
                      }`}
                    </p>
                  ) : (
                    <MultiSelectCheckFilter
                      filters={filters}
                      setFilters={setFilters}
                      multipleFlag={true}
                      id="dashboardPages_addMember"
                      options={dashboardPagesList}
                      placeholder="Select Dashboard Pages"
                      uniqueKey={`dashboardPages_${uniqueKey}`}
                      disabled={
                        filters.role_addMember &&
                        filters.role_addMember?.id != 20
                          ? false
                          : true
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          )}
          <DialogActions className={styles.userButton}>
            <Button
              size="small"
              variant="contained"
              className="dataButton2"
              startIcon={
                uniqueKey === "addMember" && (
                  <img
                    alt="/"
                    src={
                      disableButton ||
                      validationErr.email ||
                      validationErr.phoneNumber
                        ? AddIconDisabled
                        : AddIcon
                    }
                    style={{
                      border: "1px solid #FFFFFF",
                      borderRadius: "50%",
                    }}
                  />
                )
              }
              onClick={(e) => handleClick(e, uniqueKey === "addMember" ? 1 : 2)}
              disabled={
                disableButton ||
                validationErr.email ||
                validationErr.phoneNumber ||
                debounceLoading
              }
            >
              {actionButton}
            </Button>
          </DialogActions>
        </Box>
      }
    </Modal>
  );
}
