import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  driveTab: {
    driveDashboard: true,
    workerDashboard: false,
  },
  multipleCityName: [],
  multipleDriveRole: [],
  multipleExamCity: [],
  multipleInvigilatorRole: [],
  multipleManagedById: [],
  multipleDashboardPageId: [],
};

const setCurId = (state, action) => {
  const {
    multipleCityName,
    multipleDriveRole,
    multipleExamCity,
    multipleInvigilatorRole,
    multipleManagedById,
    multipleDashboardPageId
  } = action;

  return updateObject(state, {
    multipleCityName: multipleCityName,
    multipleDriveRole: multipleDriveRole,
    multipleExamCity: multipleExamCity,
    multipleInvigilatorRole: multipleInvigilatorRole,
    multipleManagedById: multipleManagedById,
    multipleDashboardPageId: multipleDashboardPageId,
  });
};

const setDriveTab = (state, action) => {
  return updateObject(state, {
    driveTab: action.driveTab,
  });
};

const driveDbReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUR_ID:
      return setCurId(state, action);
    case actionTypes.DRIVE_TAB:
      return setDriveTab(state, action);
    default:
      return state;
  }
};
export default driveDbReducer;
