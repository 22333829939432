import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Styles from "./Dialogs.module.scss";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import document from "../../assets/document.svg";
import ResponseModal from "./ResponseModal";
import Typography from "@material-ui/core/Typography";
import Download from "../../assets/Download.svg";
import { Grid } from "@material-ui/core";
import moment from "moment";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiDialog-paperWidthSm": {
      width: "600px !important",
    },
  },
}));

export default function CustomizedDialogs(props) {
  const {
    openOnboardingModal,
    setOpenOnboardingModal,
    showToast,
    updateMyData,
    title,
    uniqueKey,
    templateButton,
    fileTypeWarning,
    url,
    fileName,
  } = props;
  const classes = useStyles();
  const [excel, setExcel] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [name, setName] = useState("");
  const [excelError, setExcelError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invalidData, setInvalidData] = useState();
  const [responseData, setResponseData] = useState();
  const [template, setTemplate] = useState("");

  // File templates
  const leadsTemplate = "/Upload leads template.xlsx";
  const mettleAssessorTemplate =
    "/Mettl Assessor payroll __ Worker Onboarding __ Sample Format.xlsx";
  const offboardingDataTemplate =
    "https://www.okaygo.in/Offboarding_Multiple_Client_Template.xlsx";
  const shiftDataTemplate = "/shiftDataTemplate.xlsx";

  const handleClose = () => {
    setOpenOnboardingModal(false);
  };

  const handleCloseFile = () => {
    setName("");
    setExcel(null);
  };

  const handleBrowse = (event) => {
    const fileExtension = event.target.files[0]?.name?.split(".").slice(-1)[0];
    const file_Name = event.target.files[0]?.name;

    if (
      fileExtension.toLowerCase() === "xlsx" ||
      fileExtension.toLowerCase() === "xls"
    ) {
      setName(file_Name);
      setExcel(event.target.files[0]);
      setExcelError(false);
    } else {
      setName("");
      setExcel(null);
      setExcelError(true);
    }
    setResponseData();
    event.target.value = null;
  };

  const createInvalidData = (records) => {
    let list = [];

    if (uniqueKey === "uploadLeads" && records.length > 0) {
      records.map((record) => {
        list.push({
          "Full Name": record.name ? record.name : "",
          "Phone Number": record.phoneNumber ? record.phoneNumber : "",
          "Job Id": record.jobId ? record.jobId : "",
          City: record.city ? record.city : "",
          "Recruiter Assigned": record.recruiterNumber
            ? record.recruiterNumber
            : "",
          Source: record.source ? record.source : "",
          "Reason of failure": record.reasonOfFailure
            ? record.reasonOfFailure
            : "",
        });
        return true;
      });
    } else if ((uniqueKey === "uploadMettlAssessor") & (records.length > 0)) {
      records.map((record) => {
        list.push({
          "Project Name": record.projectName ? record.projectName : "",
          Name: record.name ? record.name : "",
          City: record.city ? record.city : "",
          "Current Address": record.currentAddress ? record.currentAddress : "",
          "Permanent Address": record.permanentAddress
            ? record.permanentAddress
            : "",
          "Email Id": record.emailId ? record.emailId : "",
          "Contact Number": record.contactNumber ? record.contactNumber : "",
          "Pan Number": record.panNumber ? record.panNumber : "",
          "Pan Card": record.panCard ? record.panCard : "",
          "Aadhar Number": record.aadharNumber ? record.aadharNumber : "",
          "Aadhar Front": record.aadharCard ? record.aadharCard : "",
          "Aadhar Back": record.aadharCardBack ? record.aadharCardBack : "",
          "Bank Account Number": record.bankAccountNumber
            ? record.bankAccountNumber
            : "",
          "Bank IFSC Code": record.ifscCode ? record.ifscCode : "",
          "Cancelled Cheque": record.cancelledCheque
            ? record.cancelledCheque
            : "",
          "Reason of failure": record.reason ? record.reason : "",
        });
        return true;
      });
    } else if ((uniqueKey === "uploadOffboardingData") & (records.length > 1)) {
      records.map((record) => {
        if (record.hasOwnProperty("WorkerDetail")) {
          if (
            record.WorkerDetail.isWorkerOffboarded === 0 &&
            "uploadStatus" !== "Offboarding initiated for this worker"
          ) {
            list.push({
              "Client ID": record.WorkerDetail.casperId,
              "OkayGo ID": record.WorkerDetail.okaygoId,
              "Exit date": record.WorkerDetail.exitDate
                ? moment(record.WorkerDetail.exitDate).format("DD/MM/YYYY")
                : "",
              "Exit Type": record.WorkerDetail.exitType,
              "Exit reason": record.WorkerDetail.exitReason,
              "Profile Blacklisted":
                record.WorkerDetail.isProfileBlacklisted === 1
                  ? "Yes"
                  : record.WorkerDetail.isProfileBlacklisted === 0
                  ? "No"
                  : "",
              Client: record.WorkerDetail.partner || "",
              "Reason for failure": record.WorkerDetail.uploadStatus,
            });
          }
        }
        return true;
      });
    } else if ((uniqueKey === "addShift") & (records.length > 0)) {
      records.map((record) => {
        list.push({
          "Exam Date": record.examDate || "",
          "Client Code": record.clientCode || "",
          "Venue Name": record.venueName || "",
          Name: record.name || "",
          "Contact Number": record.contactNumber || "",
          Role: record.role || "",
          Shift: record.shift || "",
          "Client Resource ID": record.clientResourceId || "",
          "Reason for failure": record.erroString,
        });
        return true;
      });
    }
    return list.length > 0 ? list : null;
  };

  const handleExcel = () => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("file", excel);

    Axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (uniqueKey === "uploadLeads") {
          if (response.data) {
            setInvalidData(
              createInvalidData(response.data.response.invalidExcel)
            );
            setResponseData(response.data.response);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        } else if (uniqueKey === "uploadMettlAssessor") {
          if (response.data) {
            setInvalidData(
              createInvalidData(response.data.response.invalidExcel)
            );
            setResponseData(response.data.response);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        } else if (uniqueKey === "uploadOffboardingData") {
          if (response.data) {
            setInvalidData(createInvalidData(response.data.response));
            const summary = response.data.response.slice(-1)[0];
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        } else if (uniqueKey === "addShift") {
          if (response.data) {
            setInvalidData(createInvalidData(response.data.errorRecords));
            const summary = {
              totalAttempted: response.data.totalCount,
              totalUploaded: response.data.success,
            };
            setResponseData(summary);
            updateMyData();
            setIsLoading(false);
          } else {
            setResponseData([]);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast("error", error.message);
      });
  };

  useEffect(() => {
    if (responseData) {
      setOpenResponseModal(true);
    }
  }, [responseData]);

  useEffect(() => {
    if (uniqueKey === "uploadLeads") {
      setTemplate(leadsTemplate);
    } else if (uniqueKey === "uploadMettlAssessor") {
      setTemplate(mettleAssessorTemplate);
    } else if (uniqueKey === "uploadOffboardingData") {
      setTemplate(offboardingDataTemplate);
    } else if (uniqueKey === "addShift") {
      setTemplate(shiftDataTemplate);
    }
  }, [uniqueKey]);

  let dialogData = (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openOnboardingModal}
      className={classes.root}
      classes={{ paper: Styles.paperOnboarding }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span>{title}</span>
        <a
          style={{ paddingRight: "2.5rem", paddingLeft: "10px" }}
          href={template}
          download
          target="_blank"
          rel="noreferrer"
          className={Styles.downloadTemplate}
        >
          <span>{templateButton}</span>
          <img
            style={{ paddingLeft: "0.5rem" }}
            src={Download}
            alt="download"
          />
        </a>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12} className="padding0">
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleBrowse}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.uploadButton}
              component="span"
              startIcon={
                <img src={document} alt={"upload"} style={{ color: "white" }} />
              }
            >
              Browse File
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} className="padding0">
          <span className={`fontSize13 ${excelError ? "redColor" : ""}`}>
            {fileTypeWarning}
          </span>
        </Grid>
        <Grid item xs={12} className="padding0" style={{ marginTop: "10px" }}>
          {name && (
            <span className={Styles.fileName}>
              {name.length > 70 ? `${name.substring(0, 69)}...` : `${name}`}
              <span>
                <IconButton
                  aria-label="close"
                  style={{ color: "white", paddingRight: "0" }}
                  onClick={handleCloseFile}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading ? (
          <span>Please wait... it may take few minutes</span>
        ) : (
          <Button
            variant="contained"
            onClick={handleExcel}
            className={`${!excel ? "disabledButton" : "blueButtonContained"}`}
            disabled={!excel}
          >
            Upload
          </Button>
        )}
      </DialogActions>
      {openResponseModal && (
        <ResponseModal
          openResponseModal={openResponseModal}
          setOpenResponseModal={setOpenResponseModal}
          setOpenOnboardingModal={setOpenOnboardingModal}
          responseData={responseData}
          invalidData={invalidData}
          uniqueKey={uniqueKey}
          fileName={fileName}
        />
      )}
    </Dialog>
  );

  return dialogData;
}
