import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Upload from "./components/DelhiveryEarningAdmin";
import {
  Button,
  Grid,
  Drawer,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import NoRecord from "./noRecord.svg";
import styles from "./index.module.scss";
import ScaleLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import AddIcon from "../Assets/Add.svg";
import filterIcon from "./filterIcon.svg";
import Filters from "./filters";
import { AppData } from "../../context/context";
import { useContext } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WorkerTaskViewModal from "./components/WorkerTaskViewModal";
import { AuthContext } from "../../services/AuthProvider";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 94%;
    position: absolute;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
      border-right: 1px solid #ddd !important;
    }
    td {
      margin: 0;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
    thead {
      th {
        padding: 15px 17px;
      }
    }
    tbody {
      td {
        max-width: 300px;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({
  actionButton: {
    "& .MuiSelect-selectMenu": {
      border: "1px solid #1C75BC",
      color: "#1C75BC",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 10px)",
    },
    "& .MuiSelect-iconOutlined": {
      right: 0,
      color: "#1C75BC",
    },
    "& .css-i4bv87-MuiSvgIcon-root": {
      fontSize: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1C75BC",
    },
  },
}));

const ProjectConfig = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { showToast } = useContext(AuthContext);
  const { formMode, setFormMode, userAction } = useContext(AppData);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allRecord, setAllRecord] = useState([]);
  const [disablePayments, setDisablePayments] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState(0);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortDirectionUrl, setSortDirectionUrl] = useState("");
  const [sortUrl, setSortUrl] = useState("");
  const [filterUrl, setFilterUrl] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openWorkerTaskViewModal, setOpenWorkerTaskViewModal] = useState(false);
  const [searchByAuto, setsearchByAuto] = useState("");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [actions, setActions] = useState(0);
  const [workflowId, setWorkflowId] = useState("");

  const [filters, setFilters] = useState({
    addedOn: [null, null],
    client: "",
    projectOwner: "",
    searchBy: "",
    vertical: "",
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    qcStatus: "",
    rejectReason: "",
    reworkReason: "",
  });

  const ApplySort = () => {
    let url = "";
    switch (sortBy) {
      case "swiggy_id":
        url = "&partnerId=101";
        break;
      case "flipkart_id":
        url = "&partnerId=100";
        break;
      default:
        url = "";
        break;
    }
    setSortUrl(url);
  };

  const handleActions = (event, workflow_id) => {
    if (event.target.value === "ViewEditProject") {
      setFormMode("view");
    } else if (event.target.value === "WorkerTaskView") {
      setWorkflowId(workflow_id);
      setOpenWorkerTaskViewModal(true);
    } else if (event.target.value === "ConfigureTat") {
      if (
        workflow_id === null ||
        workflow_id === undefined ||
        workflow_id === ""
      ) {
        showToast("error", "Process not mapped with project");
      }
    }
  };

  const ApplyFilters = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;
    if (filters["projectOwner"]) {
      url = `${url}&projectOwner=${filters["projectOwner"].id}`;
    }
    if (filters["vertical"]) {
      url = `${url}&category=${filters["vertical"].id}`;
    }
    if (filters["client"]) {
      url = `${url}&partnerId=${filters["client"].id}`;
    }
    if (filters["addedOn"][0] && !filters["addedOn"][1])
      url = `${url}&fromDate=${moment(filters["addedOn"][0]).format(
        "YYYY-MM-DD"
      )}&toDate=${moment(new Date()).format("YYYY-MM-DD")} 23:59:59`;
    if (!filters["addedOn"][0] && filters["addedOn"][1])
      url = `${url}&fromDate=2019-01-01&toDate=${moment(
        filters["addedOn"][1]
      ).format("YYYY-MM-DD")} 23:59:59`;
    if (filters["addedOn"][0] && filters["addedOn"][1])
      url = `${url}&fromDate=${moment(filters["addedOn"][0]).format(
        "YYYY-MM-DD"
      )}&toDate=${moment(filters["addedOn"][1]).format("YYYY-MM-DD")} 23:59:59`;

    setFilterUrl(url);
    setFilters(filters);
    if (url) {
      setIsFilterApplied(true);
    }
    setCurrentPage(1);
  };

  const resetFilters = () => {
    const filterList = {
      addedOn: [null, null],
      client: "",
      projectOwner: "",
      searchBy: "",
      vertical: "",
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
      qcStatus: "",
      rejectReason: "",
      reworkReason: "",
    };
    setFilters(filterList);
    setFilterUrl("");
    setIsFilterApplied(true);
    setCurrentPage(1);
    return filterList;
  };

  const columnsOkayGo = useMemo(
    () => [
      {
        Header: "Client Logo",
        accessor: "logo",
        defaultCanFilter: false,
        defaultCanSort: false,
        Cell: (row) => {
          return (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "10% 0",
              }}
            >
              {row.row.original.logo && (
                <img
                  height="50"
                  width="50"
                  alt="not found"
                  src={row.row.original.logo}
                  style={{
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
                    borderRadius: "50%",
                    padding: "6%",
                  }}
                ></img>
              )}
            </span>
          );
        },
      },
      {
        Header: "Client Name",
        accessor: "partnerName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Vertical Name",
        accessor: "category",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Project Owner",
        accessor: "projectOwner",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Project Code",
        accessor: "projectCode",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Added On",
        accessor: "insertedOn",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <FormControl
              variant="outlined"
              className={`margin0 ${classes.actionButton}`}
            >
              <Select
                id="actions"
                value={actions}
                classes={{ outlined: styles.actionSelect }}
                onChange={(e) => handleActions(e, row.row.original.workflowId)}
                IconComponent={MoreVertIcon}
              >
                <MenuItem value={0} disabled={true}>
                  {" "}
                  Actions
                </MenuItem>
                <MenuItem value="ViewEditProject">
                  <Link
                    to={{
                      pathname: `/projectConfig/project/${row.row.original.projectId}`,
                    }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    View/Edit Project
                  </Link>
                </MenuItem>
                <MenuItem value="ConfigureTat">
                  <Link
                    to={{
                      pathname: row.row.original.workflowId
                        ? `/tatConfig/project/${row.row.original.workflowId}`
                        : "/projectConfig",
                      state: row.row.original.workflowId,
                    }}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Configure TAT
                  </Link>
                </MenuItem>
                <MenuItem value="WorkerTaskView">Worker Task View</MenuItem>
              </Select>
            </FormControl>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    []
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.map((el) => {
      extractedData.push({
        category: el.category || "",
        insertedOn: el.insertedOn
          ? moment(el.insertedOn).format("DD/MM/YYYY")
          : "",
        logo: el.logo || "",
        partnerName: el.client_name || "",
        projectCode: el.project_code || "",
        projectId: el.projects_id || "",
        projectName: el.project_name || "",
        projectOwner: el.project_owner || "",
        workflowId: el.workflow_id ? el.workflow_id : "",
      });
      return true;
    });
    return extractedData;
  };

  // Function to create All record export data
  const columns = columnsOkayGo;
  const updateMyData = () => {
    getPaymentListing(perPage, currentPage);
  };

  const getPaymentListing = (rows, pageNo) => {
    setLoading(true);
    let requestedBy = "";
    if (localStorage.getItem("roleType")) {
      Axios.get(
        `${urlLinks.baseUrl}${urlLinks.projectListing.mainListing}?page_no=${
          pageNo - 1
        }${requestedBy}&rows=${rows}${filterUrl}${sortUrl}`
      )
        .then((response) => {
          if (response) {
            setData(makeData(response.data.response));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  useEffect(() => {
    ApplySort();
    setSortDirection(false);
    setSortDirectionUrl("");
  }, [sortBy]);

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  useEffect(() => {
    getPaymentListing(perPage, 1);
  }, [filterUrl, sortUrl, localStorage.getItem("roleType")]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: [""] },
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    rows.map((record, index) => {
      if (record.original.queued !== 0 || !record.original.isInvoiceCreated) {
        selectedRowIds[index] = false;
      }
    });
  }, [isAllRowsSelected]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      indexes.map((id) => {
        if (id) {
          disableButton = false;
          return true;
        }
        return true;
      });
      setDisablePayments(disableButton);
    }
  }, [selectedRowIds]);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className="tableContainer">
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={"header"}>
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="row">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid
        container
        className="padding2percent"
        style={{ paddingRight: "2%" }}
      >
        <Grid style={{ justifyContent: "space-between" }} container spacing={2}>
          <Grid item xs={6}>
            <span className="fontSize24 fontWeightBold">Project List</span>
          </Grid>
          <Grid
            style={{
              marginLeft: "54px",
              display: "flex",
              justifyContent: "end",
            }}
            item
            xs={4}
          >
            {!!userAction.edit && (
              <Link
                to="/projectConfig/project/add"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    textTransform: "unset",
                    color: "#1C75BC",
                    border: "1px solid #1C75BC",
                  }}
                  variant="outlined"
                  startIcon={
                    <img
                      alt="/"
                      src={AddIcon}
                      onClick={() => {
                        setFormMode("create");
                      }}
                    />
                  }
                >
                  Add New Project
                </Button>
              </Link>
            )}
            <button
              className={styles.filterButton}
              style={{ marginLeft: "16px" }}
              onClick={() => setOpenFilterDrawer(true)}
            >
              <img src={filterIcon} alt="filterIcon" />
              Show filters
            </button>
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
          </Grid>
        )}
        {openUploadModal && (
          <Upload
            openOnboardingModal={openUploadModal}
            setOpenOnboardingModal={setOpenUploadModal}
            showToast={showToast}
            updateMyData={updateMyData}
            data={data}
          ></Upload>
        )}
        {openWorkerTaskViewModal && (
          <WorkerTaskViewModal
            open={openWorkerTaskViewModal}
            close={setOpenWorkerTaskViewModal}
            filters={filters}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
            title="Configure worker task view"
            workflowId={workflowId}
            showToast={showToast}
          />
        )}
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
        >
          <Filters
            handleClose={() => setOpenFilterDrawer(false)}
            filters={filters}
            setFilters={setFilters}
            applyFilters={ApplyFilters}
            resetFilters={resetFilters}
          />
        </Drawer>
      </Grid>
    </Styles>
  );
};
export default ProjectConfig;
