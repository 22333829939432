import FlagSVG from "../../assets/Flag.svg";
import NotUploadSVG from "../../assets/Not uploaded.svg";
import VerifiedSVG from "../../assets/verified15x15.svg";
import RejectedSVG from "../../assets/Rejected.svg";
import PendingSVG from "../../assets/Pending.svg";

export const makeDynamicStatus = (type, status) => {
  switch (status) {
    case 0:
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingTop: "8px" }}>
            <img
              src={
                type === "profileVerification" || type === "selfieVerification"
                  ? FlagSVG
                  : NotUploadSVG
              }
              alt=""
            ></img>
          </div>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            {type === "profileVerification" || type === "selfieVerification"
              ? "Flagged"
              : "Not Filled"}
          </div>
        </div>
      );
    case 1:
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingTop: "4px" }}>
            <img src={VerifiedSVG} alt=""></img>
          </div>
          <div style={{ marginLeft: "10px" }}> Verified</div>
        </div>
      );
    case 2:
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingTop: "4px" }}>
            <img
              src={
                type === "profileVerification" || type === "selfieVerification"
                  ? RejectedSVG
                  : FlagSVG
              }
              alt=""
            ></img>
          </div>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            {type === "profileVerification" || type === "selfieVerification"
              ? "Rejected"
              : "Flagged"}
          </div>
        </div>
      );
    case 3:
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingTop: "4px" }}>
            <img
              src={
                type === "panCardStatus"
                  ? RejectedSVG
                  : type === "selfieVerification"
                  ? NotUploadSVG
                  : ""
              }
              alt=""
            ></img>
          </div>
          <div style={{ marginLeft: "10px" }}>
            {" "}
            {type === "panCardStatus"
              ? "Rejected"
              : type === "selfieVerification"
              ? "Not Filled"
              : ""}
          </div>
        </div>
      );
    case 4:
      return (
        type === "panCardStatus" && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingTop: "4px" }}>
              <img src={PendingSVG} alt=""></img>
            </div>
            <div style={{ marginLeft: "10px" }}> Pending</div>
          </div>
        )
      );
    default:
      return type === "profileVerification" ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingTop: "8px" }}>
            <img src={NotUploadSVG} alt=""></img>
          </div>
          <div style={{ marginLeft: "10px" }}> Not Filled</div>
        </div>
      ) : (
        ""
      );
  }
};
