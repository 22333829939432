import UrlLinks from '../UrlLinks'

class UserService {
  async loginToPortal(username,token) {
    // console.log({token})
    return fetch(`${UrlLinks.baseUrl}${UrlLinks.checkAdminUserNameV2}`, {
        method: "POST",
        // mode: "cors",
        headers: {
          'Authorization': token,
          // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          // withCredentials: true, 
          // "Access-Control-Request-Headers": "Content-Type, x-requested-with",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({ "user_name": username })
      });
  }
}

export default UserService;