import React, { useContext, useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import WorkerListIcon from "./Assets/worker list.svg";
import MpaIcon from "./Assets/mpa.svg";
import PaymentListIcon from "./Assets/payment list.svg";
import ChangePassword from "./Assets/Change password.svg";
import MenuIcon from "@material-ui/icons/Menu";
import { AuthContext } from "../../services/AuthProvider";
import styles from "./index.module.scss";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import VersionPackage from "../../../package.json";
import artImage from "./Assets/Okaygologos.svg";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import ShiftIcon from "./Assets/shift planning icon.svg";
import { Link } from "react-router-dom";
import "./index.css";
import {
  AppBar,
  Button,
  Collapse,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import { ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import UrlLinks from "../../UrlLinks";

const HeaderV2 = () => {
  const { logout } = useContext(AuthContext);
  const collapseRefs = useRef({});

  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [dashboardPages, setDashboardPages] = useState([]);
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [defaultRoute, setDefaultRoute] = useState("/changepassword");

  const handleClick = (a) => {
    if (a === 1) {
      setDropdown(!dropdown);
    }
    if (a === 2) {
      setDropdown1(!dropdown1);
    }
    if (a === 3) {
      setDropdown2(!dropdown2);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const getRole = () => {
  //   switch (localStorage.getItem("roleType")) {
  //     case "1":
  //       return "HRBP Admin";
  //     case "2":
  //       return "OkayGo Admin";
  //     case "5":
  //       return "Super HRBP";
  //     case "6":
  //       return "Super Admin";
  //     case "11":
  //       return "Swiggy HRBP";
  //     case "15":
  //       return "Telecaller";
  //     case "23":
  //       return "Client User";
  //     case "25":
  //       return "AJ Admin";
  //     case "26":
  //       return "AJ Admin";
  //     case "30":
  //       return "AJ Finance Admin";
  //     case "31":
  //       return "OkayGo Finance Admin";
  //     case "27":
  //       return "BP Analytics";
  //     case "30":
  //       return "VACO Admin";

  //     default:
  //       return "No Role specified";
  //   }
  // };

  const menuItems = [
    /** Order is as per the main drawer (DO NOT CHANGE THE ORDER) */
    { id: 1.1, name: "Access Panel", route: "/accessPanel" },
    { id: 11, name: "Employer Data", route: "/employer-data" },
    { id: 7, name: "MPA", route: "/mpaConfig" },
    { id: 1, name: "Projects Page", route: "/projectConfig" },
    { id: 14, name: "Worker Execution", route: "" },
    { id: 15, name: "Task Execution", route: "" },
    { id: 16, name: "Attendance", route: "/attendance" },
    { id: 17, name: "Drive Database", route: "/driveDatabase" },
    { id: 12, name: "Hub List", route: "/hub-listing" },
    { id: 4, name: "Jobs Page", route: "" },
    { id: 3, name: "Application DB", route: "/applicationDb" },
    { id: 2, name: "Workers Page", route: "/workerdata" },
    { id: 5, name: "Payments Page", route: "/paymentslistingV2" },
    { id: 6, name: "Verification Details", route: "" },
    { id: 8, name: "Shift Data", route: "/shiftdata" },
    { id: 9, name: "Recruiter Data", route: "/recruiter-data" },
    { id: 13, name: "Referral", route: "/referral" },
    // { id: 1.3, name: "Worker List", route: "/workerDb" },
    { id: 1.2, name: "Change password", route: "/changepassword" },
  ];

  useEffect(() => {
    const menuFiltered = menuItems.filter((item) => {
      if (item.id === 1.2) return true;
      return (
        dashboardPages.length > 0 &&
        dashboardPages.some((page) => {
          return page.Id == item.id;
        })
      );
    });

    const menuFilteredRole31 = menuItems.filter((item) => {
      if (item.id !== 1.1) return true;
    });

    if (localStorage.getItem("roleType") === "6") {
      setFilteredMenu(menuItems);
      localStorage.setItem("defaultRoute", "/accessPanel");
      setDefaultRoute("/accessPanel");
    } else if (localStorage.getItem("roleType") === "31") {
      setFilteredMenu(menuFilteredRole31);
      localStorage.setItem("defaultRoute", "/employer-data");
      setDefaultRoute("/employer-data");
    } else {
      setFilteredMenu(menuFiltered);

      if (menuFiltered.length > 0) {
        let firstPage =
          menuFiltered[0].id === 14
            ? menuFiltered[1].id === 15
              ? menuFiltered[2]
              : menuFiltered[1]
            : menuFiltered[0].id === 15
            ? menuFiltered[1]
            : menuFiltered[0];

        let secondPage = menuFiltered[1] || menuFiltered[0];
        let newDefaultRoute =
          JSON.parse(localStorage.getItem("userDesignation"))
            ?.designation_key === "50"
            ? secondPage.route
            : firstPage.route;

        if (!newDefaultRoute?.length) {
          // If route is empty, set default sub-route
          switch (firstPage.id) {
            case 4:
              newDefaultRoute = "/jobsdb";
              break;
            case 6:
              newDefaultRoute = "/document-verification";
              break;
            default:
              newDefaultRoute = "/"; // Fallback to home
          }
        }
        localStorage.setItem("defaultRoute", newDefaultRoute);
        setDefaultRoute(newDefaultRoute);
      }
    }
  }, [dashboardPages]);

  useEffect(() => {
    const pages = JSON.parse(localStorage.getItem("pages")) || [];
    setUserDetails(
      `${localStorage.getItem("fullName")} (${
        localStorage.getItem("roleType") === "6"
          ? "Super Admin"
          : localStorage.getItem("roleType") === "31"
          ? "Finance Admin"
          : localStorage.getItem("roleType") === "51"
          ? "Client"
          : JSON.parse(localStorage.getItem("userDesignation"))
              ?.designation_name
      })`
    );
    setDashboardPages(pages);
  }, [localStorage.getItem("roleType")]);

  const jobsPage = (item) => {
    return (
      <React.Fragment key={item.id}>
        <Divider />
        <List style={{ padding: "0" }}>
          <ListItemButton
            onClick={() => {
              handleClick(3);
            }}
            style={{ padding: "16px", width: "100%" }}
          >
            <ListItemIcon>
              <img src={WorkerListIcon} alt="Jobs icon" />
            </ListItemIcon>
            <ListItemText primary="Jobs" />
            {dropdown2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={dropdown2}
            timeout="auto"
            ref={(el) => (collapseRefs.current[2] = el)}
          >
            <List style={{ padding: "0" }}>
              <Link to="/jobsdb" className={styles.routeLink}>
                <ListItem
                  style={{ padding: "16px 16px 16px 35px" }}
                  button
                  key="jobsDB"
                  className={
                    window.location.pathname === "/jobsdb"
                      ? styles.activeRoute
                      : null
                  }
                  onClick={handleDrawerClose}
                >
                  <ListItemText primary="Jobs DB" onClick={handleDrawerClose} />
                </ListItem>
              </Link>
            </List>
            <List style={{ padding: "0" }}>
              <Link to="/campaignTracker" className={styles.routeLink}>
                <ListItem
                  style={{ padding: "16px 16px 16px 35px" }}
                  button
                  key="campaignTracker"
                  className={
                    window.location.pathname === "/campaignTracker"
                      ? styles.activeRoute
                      : null
                  }
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary="Campaign Tracker"
                    onClick={handleDrawerClose}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      </React.Fragment>
    );
  };

  const verificationPage = (item) => {
    return (
      <React.Fragment key={item.id}>
        <Divider />
        <List style={{ padding: "0" }}>
          <ListItemButton
            onClick={() => {
              handleClick(1);
            }}
            style={{ padding: "16px" }}
          >
            <ListItemIcon>
              <img src={WorkerListIcon} alt="Verification icon" />
            </ListItemIcon>
            <ListItemText primary="Verification details" />
            {dropdown ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={dropdown}
            timeout="auto"
            ref={(el) => (collapseRefs.current[3] = el)}
          >
            <List style={{ padding: "0" }}>
              <Link to="/document-verification" className={styles.routeLink}>
                <ListItem
                  style={{ padding: "16px 16px 16px 35px" }}
                  button
                  key="DocumentVerification"
                  className={
                    window.location.pathname === "/document-verification"
                      ? styles.activeRoute
                      : null
                  }
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary="Document Verification"
                    onClick={handleDrawerClose}
                  />
                </ListItem>
              </Link>
            </List>
            <List style={{ padding: "0" }}>
              <Link to="/bankverificationlisting" className={styles.routeLink}>
                <ListItem
                  style={{ padding: "16px 16px 16px 35px" }}
                  button
                  key="Bank Verification"
                  className={
                    window.location.pathname === "/bankverificationlisting"
                      ? styles.activeRoute
                      : null
                  }
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary="Bank Verification"
                    onClick={handleDrawerClose}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>
        </List>
      </React.Fragment>
    );
  };

  const executionPage = (item) => {
    return (
      <React.Fragment key={item.id}>
        <Divider />
        <List style={{ padding: "0" }} key={`${item.id}_main`}>
          <a
            className={"execution-button"}
            href={`${
              item.id === 14
                ? UrlLinks.workerExecutionUrl
                : UrlLinks.taskExecutionUrl
            }?userId=${localStorage.getItem(
              "emailId"
            )}&pass=${localStorage.getItem("password")}&ogRedirect=/${
              item.id === 14 ? "worker_view" : "process_okaygo"
            }`}
            target="_blank"
          >
            <ListItem
              style={{ padding: "16px" }}
              button
              key={item.id}
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <img
                  src={WorkerListIcon}
                  alt="icon"
                  onClick={handleDrawerClose}
                />
              </ListItemIcon>
              <ListItemText primary={item.name} onClick={handleDrawerClose} />
            </ListItem>
          </a>
        </List>
      </React.Fragment>
    );
  };

  const otherPages = (item) => {
    return (
      <React.Fragment key={item.id}>
        <Divider />
        <List style={{ padding: "0" }} key={`${item.id}_main`}>
          <Link to={item.route} className={styles.routeLink}>
            <ListItem
              style={{ padding: "16px" }}
              button
              key={item.id}
              className={
                window.location.pathname === item.route
                  ? styles.activeRoute
                  : null
              }
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                {item.id === 1.1 || item.id === 9 ? (
                  <Icon
                    style={{ color: "#fffff !important" }}
                    onClick={handleDrawerClose}
                  >
                    <GroupIcon></GroupIcon>
                  </Icon>
                ) : item.id === 12 ? (
                  <Icon>
                    <AddBusinessIcon></AddBusinessIcon>
                  </Icon>
                ) : (
                  <img
                    src={
                      item.id === 7
                        ? MpaIcon
                        : item.id === 5
                        ? PaymentListIcon
                        : item.id === 8
                        ? ShiftIcon
                        : item.id === 1.2
                        ? ChangePassword
                        : WorkerListIcon
                    }
                    alt={`${item.name} icon`}
                    className={item.id === 8 ? styles.listIcon : null}
                    onClick={handleDrawerClose}
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  item.id === 1
                    ? "Project Config"
                    : item.id === 2
                    ? "Worker Data"
                    : item.id === 5
                    ? "Payment List"
                    : item.id === 9
                    ? "Recruiter's Data"
                    : item.id === 12
                    ? "Hub Listing"
                    : item.name
                }
                onClick={handleDrawerClose}
              />
            </ListItem>
          </Link>
        </List>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.root}>
      <AppBar position="fixed" className={styles.NavbarBG}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Link to={defaultRoute}>
            <img src={artImage} alt="logo" className={styles.Artboard3} />
          </Link>
          <span className={styles.logout}>{userDetails}</span>
          <Button color="inherit" onClick={logout} className={styles.logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {
        <Drawer
          className={styles.drawer}
          anchor="left"
          open={open}
          onKeyDown={handleDrawerClose}
          classes={{
            paper: styles.drawerPaper,
          }}
          onClose={handleDrawerClose}
        >
          <div className={styles.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          {!!filteredMenu.length &&
            filteredMenu.map((item) => {
              return item.id === 4
                ? jobsPage(item)
                : item.id === 6
                ? verificationPage(item)
                : item.id === 14 || item.id === 15
                ? executionPage(item)
                : otherPages(item);
            })}
          <div className={styles.version}>
            <span>Version: {VersionPackage.version}</span>
          </div>
        </Drawer>
      }
      <main className={styles.content}></main>
    </div>
  );
};

export default HeaderV2;
