import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import Axios from "axios";
import { Button, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ScaleLoader from "react-spinners/ScaleLoader";
import AutoSelectFilter from "../../../Utils/FilterComponents/AutoSelectFilter";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import UrlLinks from "../../../UrlLinks";
import SearchSelectFilter from "../../../Utils/FilterComponents/SearchSelectFilter";
import MultipleSelectFilter from "../../../Utils/FilterComponents/MultipleSelectFilter";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    ApplyFilters,
    filters,
    setFilters,
    managedById,
    setManagedById,
    dashboardPageId,
    setDashboardPageId,
    roleList,
    dashboardPagesList,
    actionAddMemberList,
  } = props;
  const { users, driveDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const { multipleManagedById, multipleDashboardPageId } = driveDb;

  const dispatch = useDispatch();
  const { setModalLoader, setCurId } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const handleCancelFilters = () => {
    setManagedById(multipleManagedById);
    setDashboardPageId(multipleDashboardPageId);
    handleClose();
  };

  const handleApplyFilters = () => {
    setCurId(null, null, null, null, managedById, dashboardPageId);
    ApplyFilters();
    handleClose();
  };

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = modalLoader ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loaderBar}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={`${styles.filterContainerDrawer}`}
    >
      <Grid container className={`${styles.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={styles.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={styles.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={styles.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={styles.filterHeadingDivider}></div>
      <Grid container className={styles.filtersContainer}>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Managed By</span>
          <SearchSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="managedBy"
            sourceId={managedById}
            setSourceId={setManagedById}
            module="managedByListing"
            multiple={true}
            placeholder="Search and select"
            url={`${UrlLinks.baseUrl}${UrlLinks.accessPanelUrls.getListOfManageEmailId}`}
            disabled={false}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Role</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="role"
            options={roleList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Action</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="action"
            options={actionAddMemberList}
          />
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Dashboard Pages</span>
          <MultipleSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="dashboardPages"
            options={dashboardPagesList ? dashboardPagesList : ""}
            sourceId={dashboardPageId}
            setSourceId={setDashboardPageId}
          />
        </Grid>
      </Grid>
      <Grid container className={styles.filterButtonDividerContainer}>
        <div className={styles.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
