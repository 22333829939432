/* eslint-disable no-use-before-define */

import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
export default function AutoSelect(props) {
  const {
    options,
    filters,
    setFilters,
    id,
    multipleFlag,
    title
  } = props;
  const classes = useStyles();

  const handleChange = (e, val) => {
    if (title === "Configure worker task view") {
      setFilters({ ...filters, [id]: val ? val : "" });
    } else {
      setFilters({ ...filters, [id]: val });
    }
  };
  return (
    <Autocomplete
      multiple={multipleFlag === false ? false : true}
      id={id}
      value={filters[id]}
      onChange={(event, value) => handleChange(event, value)}
      style={
        title === "Configure worker task view"
          ? { width: "100%" }
          : { width: 250 }
      }
      options={options}
      classes={{
        option: classes.option,
      }}
      //   autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(option) => <React.Fragment>{option.name}</React.Fragment>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            title === "Configure worker task view" ? "Type & Select" : ""
          }
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            // autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}