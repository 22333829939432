import React, { useState, useMemo } from "react";
import styles from "./FilterComponents.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const useStyles = makeStyles((themes) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "44px",
      "& .MuiSelect-select": {
        padding: "10px",
      },
    },
  },
  jobSelectMui: {
    "& .MuiMenuItem-root": {
      textWrap: "wrap",
    },
  },
}));

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function JobFilter(props) {
  const {
    uniqueKey,
    jobFilterOption,
    setJobFilterOption,
    jobList,
    disabled,
    filters,
    setFilters,
    id,
    statusId,
    selectedOption,
    setSelectedOption,
    jobFilterOptionList,
    placeholder,
  } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const handleSelect = (e) => {
    const selectedId = e.target.value;
    const selectedValue = jobList.find((option) => option.id === selectedId);
    const selectedName = selectedValue.name;
    const selectedSlots = selectedValue.slots;
    uniqueKey === "attendanceProject"
      ? setSelectedOption({
          id: selectedId,
          name: selectedName,
          slots: selectedSlots,
        })
      : setSelectedOption({
          id: selectedId,
          name: selectedName,
        });
    setFilters({ ...filters, [id]: selectedId });
  };

  const handleRadioSelect = (e) => {
    const selectedValue = e.target.value;
    setJobFilterOption(selectedValue);

    if (selectedValue === "active") {
      setFilters({ ...filters, [statusId]: 1, [id]: "" });
      setSelectedOption({ id: selectedValue, name: "Active Jobs" });
    } else if (selectedValue === "inactive") {
      setFilters({ ...filters, [statusId]: 2, [id]: "" });
      setSelectedOption({ id: selectedValue, name: "In Active Jobs" });
    } else if (selectedValue === "all") {
      setFilters({ ...filters, [statusId]: "", [id]: "" });
      setSelectedOption({ id: selectedValue, name: "All Jobs" });
    }
  };

  const displayedOptions = useMemo(
    () =>
      uniqueKey === "applicationDB"
        ? jobList.filter((option) => containsText(option.name, searchText))
        : jobList,
    [uniqueKey, searchText, jobList]
  );

  return (
    <Box
      sx={{
        width:
          uniqueKey === "applicationDB"
            ? "372px"
            : uniqueKey === "driveProject"
            ? "inherit"
            : "227px",
        minWidth: uniqueKey === "driveProject" && "372px",
      }}
      className={classes.root}
    >
      <FormControl fullWidth disabled={disabled}>
        <Select
          MenuProps={{
            autoFocus: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            classes: { paper: `${styles.jobSelect} ${classes.jobSelectMui}` },
          }}
          id="search-select"
          value={selectedOption.id}
          onChange={(e) => handleSelect(e)}
          onClose={() => setSearchText("")}
          renderValue={() =>
            uniqueKey === "applicationDB" || uniqueKey === "driveProject"
              ? selectedOption.name
              : "Change Project"
          } // This prevents rendering empty string in Select's value if search text would exclude currently selected option.
        >
          {/* TextField is put into ListSubheader so that it doesn't act as a selectable item in the menu.
          i.e. we can click the TextField without triggering any selection.*/}
          {uniqueKey === "applicationDB" && (
            <ListSubheader>
              <TextField
                size="small"
                autoFocus // Autofocus on textfield
                placeholder={placeholder}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    e.stopPropagation();
                  }
                }}
              />
              <RadioGroup
                row
                aria-label="filter-options"
                name="filter-options"
                value={jobFilterOption}
                onChange={(e) => handleRadioSelect(e)}
              >
                {jobFilterOptionList.map((radio, i) => (
                  <FormControlLabel
                    key={i}
                    value={radio.id}
                    control={<Radio />}
                    label={radio.name}
                  />
                ))}
              </RadioGroup>
            </ListSubheader>
          )}

          {displayedOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
