import React, { useState, useEffect, useContext } from "react";
import { Select, MenuItem, IconButton } from "@material-ui/core";
import DoneSharpIcon from "@material-ui/icons/DoneSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import urlLinks from "../../../UrlLinks";
import Axios from "axios";
import { AppData } from "../../../context/context";

const EditableDropdownSubComponent = (props) => {
  const { row, columnId, initialValue, updateMyData, showToast, setLoading } =
    props;
  const { userAction } = useContext(AppData);
  const [value, setValue] = useState(initialValue);
  const [showButtons, setShowButtons] = useState(false);

  const onChange = (e) => {
    setShowButtons(true);
    setValue(e.target.value);
  };

  const updateStatus = () => {
    setLoading(true);
    if (columnId === "approvalStatus") {
      const postData = {
        field: "approvalStatus",
        userId: row.userId,
        referralId: row.referralId,
      };

      Axios.post(
        `${urlLinks.baseUrl}${
          urlLinks.referral.updateReferralFields
        }?updatedBy=${localStorage.getItem("userID")}&referralId=${
          postData.referralId
        }&value=${value}&field=${postData.field}`
      ).then((response) => {
        if (response.data.code === 1000) {
          updateMyData();
          setLoading(false);
        } else {
          showToast(response.data.message);
          setLoading(false);
        }
      });
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    updateStatus();
  };

  const handleCancel = () => {
    setValue(initialValue);
    setShowButtons(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  let dropdown = <div></div>;
  if (columnId === "approvalStatus")
    dropdown = (
      <div className="index">
        <Select
          id="approvalStatus"
          value={value}
          disableUnderline
          onChange={onChange}
          inputProps={{
            classes: {
              icon: "selectIcon",
            },
          }}
          style={{ marginLeft: "30px" }}
          disabled={!userAction.edit}
        >
          <MenuItem value="-" disabled>
            <em>Approval Status</em>
          </MenuItem>
          <MenuItem value="1">Approved</MenuItem>
          <MenuItem value="2">Not Approved</MenuItem>
        </Select>
        {showButtons && (
          <div>
            <IconButton color="primary" onClick={handleConfirm}>
              <DoneSharpIcon />
            </IconButton>
            <IconButton color="secondary" onClick={handleCancel}>
              <CloseSharpIcon />
            </IconButton>
          </div>
        )}
        {row.callStatusDate && (
          <div>
            <span>{row.callStatusDate}</span>
          </div>
        )}
      </div>
    );

  return <React.Fragment>{dropdown}</React.Fragment>;
};

export default EditableDropdownSubComponent;
