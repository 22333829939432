import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  forwardRef,
  useRef,
} from "react";
import styled from "styled-components";
import { Button, Grid, Drawer, Checkbox } from "@material-ui/core";
import { useTable, useRowSelect, useSortBy, useFilters } from "react-table";
import urlLinks from "../../UrlLinks";
import Axios from "axios";
import styles from "./index.module.scss";
import Pagination from "../../Utils/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import { AuthContext } from "../../services/AuthProvider";
import NoRecord from "./Assets/noRecord.svg";
import "./indeterminateCheckbox.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/actions/index";
import SearchBar from "../../Utils/functions/SearchBar";
import ExportButton from "../../Utils/functions/ExportButton";
import filterIcon from "../../assets/filterIconWhite.svg";
import FilterIconMarked from "../../assets/FilterIconMarked.svg";
import AddIcon from "../Assets/Add.svg";
import DeleteIcon from "../Assets/Delete.svg";
import DeleteDisableIcon from "../Assets/DeleteDisabled.svg";
import RemoveExtraSpaces from "../../Utils/functions/RemoveExtraSpaces";
import FilterDrawer from "./components/FilterDrawer";
import UserMember from "../../Utils/dialogs/UserMember";
import moment from "moment";
import DeleteConfirmationModal from "../../Utils/dialogs/DeleteConfirmationModal.js";

const Styles = styled.div`
  padding: 1rem;
  .tableContainer {
    overflow-x: scroll;
    border: 1px solid #cfcfcf;
    max-height: 74vh;
  }
  .tableContainer::-webkit-scrollbar {
    width: 0.75em;
  }

  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  table {
    border-spacing: 0;
    width: max-content;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      :last-child {
        border-left: 0;
      }
      background-color: #1c75bc !important;
      color: #ffffff;
    }
    th,
    td {
      padding: 5px 15px;
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      background-color: white;
      nth-last-child(2) {
        border-right: 0;
      }
      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles((theme) => ({}));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <React.Fragment>
      <Checkbox ref={resolvedRef} {...rest} />
    </React.Fragment>
  );
});

const AccessPanel = (props) => {
  /** accessing states from redux store **/
  const { users, driveDb } = useSelector((state) => state);
  const { allRecord, isFilter, openFilterDrawer } = users;
  // const { multipleManagedById, multipleDashboardPageId } = driveDb;

  /** useDispatch is used to update states to redux store **/
  const dispatch = useDispatch();
  const { setAllRecord, setIsFilter, setModalLoader, setOpenFilterDrawer } =
    bindActionCreators(actionCreators, dispatch);

  /** other states **/
  const classes = useStyles();
  const { showToast } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [allRecordLoading, setAllRecordLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchBy, setSearchBy] = useState("");
  const [searchByAuto, setSearchByAuto] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [crossFilter, setCrossFilter] = useState({
    value: "&tabRole=",
    index: null,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterUrl, setFilterUrl] = useState("");
  const [isEditMemberDetails, setIsEditMemberDetails] = useState(false);
  const [managedById, setManagedById] = useState([]);
  const [dashboardPageId, setDashboardPageId] = useState([]);
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const [openMemberDetailsModal, setOpenMemberDetailsModal] = useState(false);
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [memberDataList, setMemberDataList] = useState(null);
  const [filterDashboardPagesList, setFilterDashboardPagesList] = useState([]);
  const [dashboardPagesList, setDashboardPagesList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [disable, setDisable] = useState(true);
  const [selectedUserIds, setSelectedUserIds] = useState("");
  const [manageById_addMember, setManageById_addMember] = useState("");
  const [filters, setFilters] = useState({
    action: "",
    action_addMember: ["View"],
    dashboardPages: "",
    dashboardPages_addMember: [],
    email_addMember: "",
    firstName_addMember: "",
    lastName_addMember: "",
    managedBy: "",
    managedBy_addMember: "",
    phoneNo_addMember: "",
    role: "",
    role_addMember: "",
    searchBy: "",
  });
  const actionAddMemberList = [
    {
      id: "View",
      name: "View",
      label: 1,
    },
    {
      id: "Edit",
      name: "Edit",
      label: 2,
    },
    {
      id: "Approve/ Reject",
      name: "Approve/ Reject",
      label: 3,
    },
    {
      id: "Export",
      name: "Export",
      label: 4,
    },
  ];

  const handleSearch = () => {
    setFilters({ ...filters, searchBy: searchBy });
    setSearchByAuto(searchBy);
  };

  const handleSearchClose = () => {
    setFilters({ ...filters, searchBy: "" });
    setSearchBy("");
    setFilterUrl("");
    setSearchByAuto("");
  };

  const ApplyFilters = () => {
    let url = updateFilterUrl();
    setFilterUrl(url);
    setFilters(filters);
    setCurrentPage(1);
  };

  const updateFilterUrl = () => {
    let url = "";
    if (filters["searchBy"]) url = `${url}&searchBy=${filters["searchBy"]}`;

    // Action Filters
    if (filters["action"]) {
      url = `${url}&action=${filters["action"].label}`;
    }

    // Dashboard Pages Filters
    if (filters["dashboardPages"]) {
      url = `${url}&pageName=${filters["dashboardPages"]}`;
    }

    // Managed By Filters
    if (filters["managedBy"] || filters["managedBy"] === 0) {
      url = `${url}&manageBy=${filters["managedBy"]}`;
    }

    // Role Filters
    if (filters["role"]) {
      url = `${url}&roleType=${filters["role"].id}`;
    }

    return url;
  };

  const resetFiltersWithoutSearch = (type) => {
    setIsFilter(false);
    const filterList = {
      action: "",
      action_addMember: filters.action_addMember,
      dashboardPages: "",
      dashboardPages_addMember: filters.dashboardPages_addMember,
      email_addMember: filters.email_addMember,
      firstName_addMember: filters.firstName_addMember,
      lastName_addMember: filters.lastName_addMember,
      managedBy: "",
      managedBy_addMember: filters.managedBy_addMember,
      phoneNo_addMember: filters.phoneNo_addMember,
      role: "",
      role_addMember: filters.role_addMember,
      searchBy: filters.searchBy,
    };

    setFilterUrl(`${filters.searchBy ? "&searchBy=" + filters.searchBy : ""}`);
    setFilters(filterList);
    setManagedById([]);
    setDashboardPageId([]);
    setCurrentPage(1);
    return filterList;
  };

  const workerDataColumn = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              className="overrideCheckbox"
              color="white"
            />
          </div>
        ),
        Cell: ({ row }) => (
          <div id="check1">
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              style={{ display: "flex", justifyItems: "center" }}
            />
          </div>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return <span>{row.row.original.name}</span>;
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Phone Number",
        accessor: "contactNo",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Email ID",
        accessor: "emailId",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Role",
        accessor: "role",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Action",
        accessor: "action",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Managed By",
        accessor: "managedBy",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Dashboard Pages",
        accessor: "dashboardPages",
        Cell: (row) => {
          return (
            <div style={{ width: "339px" }}>
              {row.row.original.dashboardPages}
            </div>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Creation Date",
        accessor: "creationDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "Exit Date",
        accessor: "exitDate",
        defaultCanFilter: false,
        defaultCanSort: false,
      },
      {
        Header: "View/Edit",
        Cell: (row) => {
          return (
            <Button
              variant="outlined"
              className={`dataButtonOutline2`}
              onClick={(e) => handleClick(e, "viewMember", row.row.original)}
            >
              View/Edit
            </Button>
          );
        },
        defaultCanFilter: false,
        defaultCanSort: false,
      },
    ],
    [crossFilter]
  );

  //Function to create table data
  const makeData = (response) => {
    let extractedData = [];
    response.content.map((el) => {
      let actionList = [];
      el.view && actionList.push("View");
      el.edit && actionList.push("Edit");
      el.addDelete && actionList.push("Add/Delete");
      el.export_access && actionList.push("Export");

      extractedData.push({
        action: actionList.join(","),
        contactNo: el.phoneNumber || "",
        creationDate: el.creationDate
          ? moment(el.creationDate).format("DD/MM/YYYY")
          : "",
        dashboardPages: el.page_names.join(","),
        emailId: el.emailId || "",
        exitDate: el.exitDate ? moment(el.exitDate).format("DD/MM/YYYY") : "",
        managedBy: el.manageBy || "",
        name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        role: el.designation_name || "",
        userId: el.userId || "",
      });
      return true;
    });
    return extractedData;
  };

  //Function to create export data as per the main listing order
  const makeExportData = (response) => {
    let extractedData = [];

    response.map((el) => {
      let actionList = [];
      el.view && actionList.push("View");
      el.edit && actionList.push("Edit");
      el.addDelete && actionList.push("Add/Delete");
      el.export_access && actionList.push("Export");

      let temp = {
        Name: `${el.firstName ? el.firstName : ""} ${
          el.lastName ? el.lastName : ""
        }`,
        "Phone number": el.phoneNumber || "",
        "Email ID": el.emailId || "",
        Role: el.designation_name || "",
        Action: actionList.join(","),
        "Managed By": el.manageBy || "",
        "Dashboard Pages": el.page_names.join(","),
        "Creation Date": el.creationDate
          ? moment(el.creationDate).format("DD/MM/YYYY")
          : "",
        "Exit Date": el.exitDate
          ? moment(el.exitDate).format("DD/MM/YYYY")
          : "",
      };

      extractedData.push(temp);
      return true;
    });
    return extractedData;
  };

  const updateMyData = () => {
    getExternalWorkerList(perPage, currentPage);
  };

  //Function to call export data
  const createAllRecordExcel = async () => {
    setAllRecordLoading(true);

    let worker_url = `${urlLinks.baseUrl}${urlLinks.accessPanelUrls.mainListing}?${filterUrl}${crossFilter.value}&exportExcel=1`;

    Axios.get(worker_url).then((response) => {
      if (response) {
        setAllRecord(makeExportData(response.data.response));
      }
    });
  };

  const getExternalWorkerList = (rows, pageNo) => {
    setLoading(true);

    let worker_url = `${urlLinks.baseUrl}${
      urlLinks.accessPanelUrls.mainListing
    }?page_no=${pageNo - 1}&rows=${rows}${filterUrl}${crossFilter.value}`;

    Axios.get(worker_url)
      .then((response) => {
        if (response) {
          setTotalRows(response.data.response.totalElements);
          setTotalPages(response.data.response.totalPages);
          if (currentPage > response.data.response.totalPages)
            setCurrentPage(1);
          setData(makeData(response.data.response));
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const columns = workerDataColumn;
  const handlePageChange = (page) => {
    getExternalWorkerList(perPage, page);
    setCurrentPage(page);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      showToast,
      setLoading,
      filters,
      setFilters,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const handlePerRowsChange = (newPerPage) => {
    getExternalWorkerList(newPerPage, 1);
    setPerPage(newPerPage);
    setCurrentPage(1);
  };

  const handleClick = async (e, type, rowData) => {
    switch (type) {
      case "addMember":
        setModalLoader(true);
        setOpenAddMemberModal(true);
        setModalLoader(false);
        break;
      case "viewMember":
        setModalLoader(true);
        setOpenMemberDetailsModal(true);
        await Axios.get(
          `${urlLinks.baseUrl}${urlLinks.accessPanelUrls.mainListing}?userId=${rowData.userId}`
        )
          .then((response) => {
            if (response) {
              let resData = response.data.response.content[0];
              setMemberDataList(resData);

              let actionList = ["View"];
              resData.edit && actionList.push("Edit");
              resData.addDelete && actionList.push("Approve/ Reject");
              resData.export_access && actionList.push("Export");

              setFilters((prev) => ({
                ...prev,
                action_addMember: actionList,
                dashboardPages_addMember: resData.page_names,
                email_addMember: resData.emailId || "",
                firstName_addMember: resData.firstName,
                lastName_addMember: resData.lastName,
                managedBy_addMember: resData.manageBy,
                phoneNo_addMember: resData.phoneNumber || "",
                role_addMember: {
                  id: resData.designation_key,
                  name: resData.designation_name,
                },
              }));
              setManageById_addMember({ id: resData.manageBy });
            }
          })
          .catch((error) => {
            showToast(
              "error",
              "Error",
              "Something went wrong, please try after sometime."
            );
          });
        setModalLoader(false);
        break;
      case "deleteMember":
        setModalLoader(true);
        let rowIndexes = Object.entries(selectedRowIds);
        let idList = "";
        rowIndexes.map((id, index) => {
          if (id[1]) {
            idList += data[parseFloat(id[0])].userId + ",";
          }
          return true;
        });
        idList = idList.substr(0, idList.length - 1);
        setSelectedUserIds(idList);
        setOpenDeleteMemberModal(true);
        setModalLoader(false);
        break;
      default:
        break;
    }
  };

  const handleDelete = async () => {
    await Axios.post(
      `${urlLinks.baseUrl}${urlLinks.accessPanelUrls.deleteMemberForRoleAcess}?userId=${selectedUserIds}`
    )
      .then((response) => {
        showToast("success", "Member deleted successfully");
      })
      .catch((error) => {
        showToast(
          "error",
          "Error",
          "Something went wrong, please try after sometime."
        );
      });
    updateMyData();
  };

  const makeDashboardPagesList = (res) => {
    let list = [];
    res?.map((e) => {
      list.push({ id: e.typeValue, name: e.typeKey, label: e.typeKey });
      return true;
    });
    return list;
  };

  const makeFilterDashboardPagesList = (res) => {
    let list = [];
    res?.map((e) => {
      list.push({ id: e.typeValue, name: e.typeValue, label: e.typeKey });
      return true;
    });
    return list;
  };

  const makeRoleList = (res) => {
    let list = [];
    res?.map((e) => {
      list.push({ id: e.typeValue, name: e.typeKey });
      return true;
    });
    return list;
  };

  useEffect(() => {
    setAllRecordLoading(false);
  }, [allRecord]);

  // useEffect(() => {
  //   let searchByVar = RemoveExtraSpaces(searchBy);
  //   let count = searchByVar.split(/[ ,]+/).length;
  //   if (count > 100) {
  //     setSearchError(true);
  //   } else {
  //     setSearchError(false);
  //   }
  // }, [searchBy]);

  useEffect(() => {
    ApplyFilters(filters);
  }, [searchByAuto]);

  useEffect(() => {
    if (
      filters.action !== "" ||
      filters.dashboardPages !== "" ||
      filters.managedBy !== "" ||
      filters.role !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [filterUrl]);

  useEffect(() => {
    getExternalWorkerList(perPage, 1);
    setAllRecord([]);
  }, [filterUrl, localStorage.getItem("roleType"), crossFilter]);

  useEffect(() => {
    if (selectedRowIds) {
      const indexes = Object.values(selectedRowIds);
      let disableButton = true;
      if (indexes.length > 1) {
        disableButton = true;
      } else {
        indexes.map((id) => {
          if (id) {
            disableButton = false;
            return true;
          }
          return true;
        });
      }
      setDisable(disableButton);
    }
  }, [selectedRowIds]);

  useEffect(async () => {
    setModalLoader(true);
    // API for dashboard pages listing
    await Axios.get(
      `${urlLinks.baseUrl}${urlLinks.accessPanelUrls.dashboardPagesListing}&rows=30`
    ).then((response) => {
      setDashboardPagesList(
        makeDashboardPagesList(response.data.response.content)
      );
      setFilterDashboardPagesList(
        makeFilterDashboardPagesList(response.data.response.content)
      );
    });

    // API for role listing
    await Axios.get(
      `${urlLinks.baseUrl}${urlLinks.accessPanelUrls.roleListing}&rows=100`
    ).then((response) => {
      setRoleList(makeRoleList(response.data.response.content));
    });
    setModalLoader(false);
  }, []);

  const loader = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const table = (
    <div className={`tableContainer ${styles.tableContainer}`}>
      <table {...getTableProps()} className="table">
        <thead className="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    column.render("Header") === "Name"
                      ? "stickWorkerNameV2 zIndex1080 header"
                      : column.id === "selection"
                      ? "stickCheckbox zIndex1080 header width25"
                      : "header"
                  }
                >
                  <span
                    style={
                      column.render("Header") === "addressGoogle" ||
                      column.render("Header") === "documentsPending"
                        ? { width: "250px" }
                        : {}
                    }
                  >
                    {column.render("Header")}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`row ${
                    row.original.criminalRecord ? "criminalRecord" : ""
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${
                          cell.column.Header === "Name"
                            ? "stickWorkerNameV2 zIndex540"
                            : cell.column.id === "selection"
                            ? "stickCheckbox zIndex540"
                            : "cell"
                        } ${
                          row.original.criminalRecord === "Yes"
                            ? "criminalRecord"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
      </table>
    </div>
  );

  return (
    <Styles>
      <Grid container className="padding2percent">
        <Grid item xs={12} className="flexDisplay">
          <Grid
            item
            xs={6}
            className="flexDisplay padding0 paddingTop8 justifySpaceBetween"
          >
            <SearchBar
              uniqueId="accessPanel"
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              searchError={false}
              filters={filters}
              setFilters={setFilters}
              handleSearch={handleSearch}
              handleSearchClose={handleSearchClose}
              ApplyFilters={ApplyFilters}
              placeholder={`Search by Name / Email ID/ Phone Number`}
              buttonName="Search"
              searchInstructions=""
              warningMessage=""
            />
          </Grid>
          <Grid item xs={6} className={`${styles.itemRowReverse} flexDisplay`}>
            <Button
              variant="outlined"
              className={`dataButtonOutline2 ${styles.filterButton}`}
              onClick={() => setOpenFilterDrawer(true)}
            >
              {!isFilter ? (
                <img src={filterIcon} alt="filterIcon" />
              ) : (
                <img src={FilterIconMarked} alt="filterIcon" />
              )}
              Filters
            </Button>
            <div style={{ marginRight: "16px" }}>
              <ExportButton
                uniqueKey="accessPanel"
                createAllRecordExcel={createAllRecordExcel}
                allRecordLoading={allRecordLoading}
                filename="Access Panel.csv"
                buttonName="Export Data"
                loadingMessage="Please wait ... it might take few minutes."
                downloadButton="Download now"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className={`flexDisplay paddingTop16`}>
          <Grid item xs={6}>
            <Button
              onClick={(event) => {
                {
                  setCrossFilter({
                    value: "&tabRole=",
                    index: null,
                  });
                }
              }}
              className={`statusButtons marginRight20 ${
                crossFilter.value === "&tabRole="
                  ? "blueColorBorder"
                  : "greyColorBorder"
              }`}
            >
              All
            </Button>
            <Button
              onClick={(event) => {
                {
                  setCrossFilter({
                    value: "&tabRole=1",
                    index: 1,
                  });
                }
              }}
              className={`statusButtons marginRight20 ${
                crossFilter.value === "&tabRole=1"
                  ? "blueColorBorder"
                  : "greyColorBorder"
              }`}
            >
              Internal
            </Button>
            <Button
              onClick={(event) => {
                {
                  setCrossFilter({
                    value: "&tabRole=3",
                    index: 3,
                  });
                }
              }}
              className={`statusButtons marginRight20 ${
                crossFilter.value === "&tabRole=3"
                  ? "blueColorBorder"
                  : "greyColorBorder"
              }`}
            >
              External
            </Button>
            <Button
              onClick={(event) => {
                setCrossFilter({ value: "&tabRole=2", index: 2 });
              }}
              className={`statusButtons marginRight20 ${
                crossFilter.value === "&tabRole=2"
                  ? "blueColorBorder"
                  : "greyColorBorder"
              }`}
            >
              Recruiter
            </Button>
          </Grid>
          <Grid item xs={6} className={`${styles.itemRowReverse} flexDisplay`}>
            <Button
              className="dataButtonOutline2"
              variant="outlined"
              startIcon={
                <img alt="/" src={disable ? DeleteDisableIcon : DeleteIcon} />
              }
              onClick={(e) => {
                handleClick(e, "deleteMember");
              }}
              disabled={disable}
            >
              Delete Member
            </Button>
            <Button
              style={{
                textTransform: "unset",
                color: "#FFF",
                border: "1px solid #1C75BC",
                background: " #1C75BC",
                marginRight: "16px",
              }}
              variant="contained"
              startIcon={
                <img
                  alt="/"
                  src={AddIcon}
                  style={{ border: "1px solid #FFFFFF", borderRadius: "50%" }}
                />
              }
              onClick={(e) => {
                handleClick(e, "addMember");
              }}
            >
              Add Member
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          loader
        ) : data.length === 0 ? (
          <div className={styles.noRecord}>
            <img src={NoRecord} alt="noRecord" />
            <span>No records found</span>
          </div>
        ) : (
          <Grid item xs={12} className="paddingTop16">
            {table}
            <Pagination
              rowsperpage={perPage}
              pageNo={currentPage}
              totalElements={totalRows}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
            />
          </Grid>
        )}
        {openFilterDrawer && (
          <Drawer
            anchor="right"
            open={openFilterDrawer}
            onClose={() => {
              setOpenFilterDrawer(false);
              // setManagedById(multipleManagedById);
              // setDashboardPageId(multipleDashboardPageId);
            }}
          >
            <FilterDrawer
              handleClose={() => setOpenFilterDrawer(false)}
              filters={filters}
              setFilters={setFilters}
              ApplyFilters={ApplyFilters}
              resetFilters={resetFiltersWithoutSearch}
              managedById={managedById}
              setManagedById={setManagedById}
              dashboardPageId={dashboardPageId}
              setDashboardPageId={setDashboardPageId}
              roleList={roleList}
              dashboardPagesList={filterDashboardPagesList}
              actionAddMemberList={actionAddMemberList}
            ></FilterDrawer>
          </Drawer>
        )}
        {openAddMemberModal && (
          <UserMember
            uniqueKey="addMember"
            open={openAddMemberModal}
            setOpen={setOpenAddMemberModal}
            title={`Add Member`}
            filters={filters}
            setFilters={setFilters}
            showToast={showToast}
            updateMyData={updateMyData}
            dataList={memberDataList}
            isEdit={isEditMemberDetails}
            setIsEdit={setIsEditMemberDetails}
            roleList={roleList}
            dashboardPagesList={dashboardPagesList}
            actionAddMemberList={actionAddMemberList}
            manageById={manageById_addMember}
            setManageById={setManageById_addMember}
            actionButton="Create"
          />
        )}
        {openMemberDetailsModal && (
          <UserMember
            uniqueKey="viewMember"
            open={openMemberDetailsModal}
            setOpen={setOpenMemberDetailsModal}
            title={`Member Details`}
            filters={filters}
            setFilters={setFilters}
            showToast={showToast}
            updateMyData={updateMyData}
            dataList={memberDataList}
            isEdit={isEditMemberDetails}
            setIsEdit={setIsEditMemberDetails}
            roleList={roleList}
            dashboardPagesList={dashboardPagesList}
            actionAddMemberList={actionAddMemberList}
            manageById={manageById_addMember}
            setManageById={setManageById_addMember}
            actionButton="Update Details"
          />
        )}
        {openDeleteMemberModal && (
          <DeleteConfirmationModal
            uniqueId="deleteMember"
            openConfirmationDialog={openDeleteMemberModal}
            setOpenConfirmationDialog={setOpenDeleteMemberModal}
            title={`Delete Member`}
            content={
              "Are you sure want to delete this member? This action cannot be undone."
            }
            approveButtonText="Delete"
            declineButtonText="Cancel"
            handleApprove={handleDelete}
          />
        )}
      </Grid>
    </Styles>
  );
};

export default AccessPanel;
