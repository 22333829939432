import React from "react";
import HRBPview from "./HRBPview";

function HRBPuse(props) {
  const { data } = props;

  const hrbp = <div>{<HRBPview data={data} />}</div>;

  return hrbp;
}

export default HRBPuse;
